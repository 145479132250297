import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';

export default class Spinner extends Component{
    render(){
        return(
            <React.Fragment>
                <CircularProgress color="primary" />
            </React.Fragment>
        )
    }
}