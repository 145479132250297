import React, { useState, useEffect } from "react";
import { Avatar, Typography, Button } from "@material-ui/core";
import styled from "styled-components";
import renderHTML from "react-render-html";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton' ;
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isMobileOnly, isMobile } from "react-device-detect";

const ChallengeCardContainer = styled.div`
  height: 540px;
  margin-top: 12px;
  display:flex;
  justify-content:center;
`;

const StyledChallengeCard = styled.div`
  margin: 0 10px;
  border-radius: 6px;
  width: ${isMobile?"280px":"340px"};
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const ChallengeProfileTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 16px;
`;

const StyledAvatar = styled(Avatar)`
  width: 100px !important;
  height: 70px !important;
  border-radius: 4px !important;
  object-fit: contain;
`;

const ContentContainer = styled.div`
  margin-left: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  color: grey;
  padding-top: 8px;
`;

const ChallengeCardButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${isMobileOnly?"center":"flex-end"};
  justify-content: flex-end;
`;

const ThingsToKnow = styled.div`
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.66;

  & > ul {
      padding-left: 18px;
      margin: 2px;
  }
`;

const ChallengeMainContent = styled.div`
  overflow-y: scroll;
  flex: 1;
`

const ChallengeNameContainer = styled.div`
display: flex;
justify-content: space-between;
flex-direction: row;
`

export default function ChallengeCard(props) {
  const [isMobile, setIsMobile] = useState(false)
  const [expand, setExpand] = useState(false)


  useEffect(()=>{
    if(isMobileOnly){
      setIsMobile(true)

  }
  }, [])

  function onClickTry() {
    props.onClickTry(props.data);
  }

  function onClickStart() {
    props.onClickStart(props.data);
  }

  function renderThingsToKnow() {
    return (
      <ThingsToKnow>{renderHTML(props.data.prerequisites || "")}</ThingsToKnow>
    );
  }

  function onClickExpand(){
    let expandMore = true
    if(expand){
      expandMore = false
    }
    setExpand(expandMore)
  }

  return (
    <ChallengeCardContainer className={`${isMobileOnly && (expand ?"heightInitial":  "challengeCardContainerDefaultHeight")}`}>
      <StyledChallengeCard className={`${isMobileOnly && (expand ?"heightInitial":  "challengeCardDefaultHeight")}`}>
        <ChallengeProfileTopContainer onClick={onClickExpand}>
          <StyledAvatar
            alt={props.data.name}
            src={props.data.picURL}
            variant="square"
          />
          <ContentContainer className="fullMobileWidth">
            <ChallengeNameContainer  className="fullMobileWidth">
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              {props.data.name}
            </Typography>


            {isMobile && <IconButton color="primary" aria-label="expand-more" size="small">
                {expand ? <ExpandLessIcon color="primary" />: <ExpandMoreIcon color="primary" />}
              </IconButton>}
            </ChallengeNameContainer>
            <Typography
              variant="caption"
              style={{ color: "grey", marginBottom: 8 }}
            >
              {props.data.value}
            </Typography>
          </ContentContainer>
        </ChallengeProfileTopContainer>
        <ChallengeMainContent className={`${isMobileOnly && !expand && "displayNone"}`}>
          <Section>
            <AlignCenter>
              <StyledTypography variant="body1" style={{ fontWeight: "600", color:"#000" }}>
                About the Challenge
              </StyledTypography>
            </AlignCenter>
            <AlignCenter>
              <Typography variant="caption">{props.data.about}</Typography>
            </AlignCenter>
          </Section>
          <Section>
            <AlignCenter>
              <StyledTypography variant="body1" style={{ fontWeight: "600", color:"#000", marginTop: "16px" }}>
                Things you may want to know
              </StyledTypography>
            </AlignCenter>
            {renderThingsToKnow()}
          </Section>
        </ChallengeMainContent>
        <ChallengeCardButtons className={`${isMobileOnly && !expand && "displayNone"}`}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 12 }}
            size="small"
            onClick={onClickTry}
            className="displayNone"
          >
            Try Phase 1 for Free
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 12 }}
            size={"small"}
            onClick={onClickStart}
            className="onBoardButton"
          >
            {"Start Now for free"}
            {/* {props.data.price ? ` for $${props.data.price}` : ""} */}
          </Button>
        </ChallengeCardButtons>
      
      </StyledChallengeCard>
    </ChallengeCardContainer>
  );
}
