import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import Lottie from "react-lottie";
import * as animationData from "../../assets/waiting.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default class Waiting extends Component {
  render() {
    return (
      <div className="pageContainer flexCenter flexColumn">
        <Lottie options={defaultOptions} height={420} width={450} />
        <Typography variant="h4" align="center">
          Thanks for reaching out!
        </Typography>
        <Typography variant="subtitle1" color="initial">
          The trainer will contact you within the next 48 hours.
        </Typography>
      </div>
    );
  }
}
