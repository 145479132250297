import React, { Component } from "react";
import moment from "moment";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fitness from "../../assets/fitness.png";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    KeyboardTimePicker
  } from '@material-ui/pickers';

const DAYS_MAP = {
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7,
};
function roundTime(timestamp, roundTo) {
    try {
        const roundedUp =
        Math.floor(moment(timestamp).minute() / roundTo) * roundTo;

        return moment(timestamp).minute(roundedUp).second(0);
    } catch (e) {
        console.error(e);
    }
}
  
export default class ReminderSetter extends Component {
    constructor(props) {
      super(props);
      this.state = {
        time: roundTime(moment(), 5),
      };
    }

    onChangeTime = (selectedTime) => {
        this.setState({
            time: selectedTime,
        });
    };
  
    onSavePress = () => {
      let time = this.state.time;
      let hours = time.format("HH");
      let minutes = time.format("mm");
      let goalTime;
      for (let timestamp of Object.keys(this.props.goals)) {
        goalTime = timestamp;
      }
      let reminders = {};
      for (let goalDay of Object.keys(this.props.goals[goalTime])) {
        const reminder = moment()
          .startOf("week")
          .add({ days: DAYS_MAP[goalDay], hours, minutes });
        let reminderTimestamp;
        reminderTimestamp = reminder.utc().unix();
        reminders[reminderTimestamp] = 0;
      }
      this.props.onReminderSet(reminders, true);
    };

    renderImage=()=>{
        return (<img id="fitnessImg" className="marginTop32" src={Fitness} alt="Consistency" />)
    }

    renderTitle = () => {
        return (
            <Typography className="marginTop32" variant="h6" color="primary">
                What time do you like to workout ?
            </Typography>
        )
    }

    renderTimer = () => {
        return (
            <div>
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Set Reminder Time"
                    value={this.state.time}
                    onChange={this.onChangeTime}
                    minutesStep={5}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    inputProps={{
                        step: 300
                    }}
                />
            </div>
        )
    }

    renderNote = () => {
        return (
            <div></div>
        )
    }

    renderActionButton = () => {
        return (
            <React.Fragment>
                <Button className="marginTop32 marginBottom32" fullWidth={true} color="primary" onClick={() => this.props.onReminderSet(false, true)}>
                    Set Later
                </Button>
                <Button className="marginTop32 marginBottom32" fullWidth={true} color="primary" variant="contained" onClick={this.onSavePress}>
                    Set Now
                </Button>
            </React.Fragment>
        )
    }
    render() {
        return (
            <React.Fragment>
                <DialogContent id="consistencyMainContainer" className="textAlignCenter skinColor" >
                    {this.renderImage()}
                    {this.renderTitle()}
                    {this.renderTimer()}
                    {this.renderNote()}
                </DialogContent>
                <DialogActions id="consistencyActionButton" className="skinColor">
                {this.renderActionButton()}   
                </DialogActions>
            </React.Fragment>
        )
    }
}