import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/DoneRounded";
import ErrorIcon from "@material-ui/icons/ErrorOutlineRounded";
import clsx from "clsx";

export default class TAButton extends React.Component {
  getStartIcon() {
    return (
      <div className="flexRow">
        {<CircularProgress size={18} className="icon loading" color="primary" />}
        {<DoneIcon className="icon success" />}
        {<ErrorIcon className="icon error" />}
        {this.props.startIcon}
      </div>
    );
  }

  renderText() {
    if (this.props.isLoading) {
      return this.props.loadingMessage;
    }

    if (this.props.isError) {
      return this.props.errorMessage;
    }

    if (this.props.isSuccess) {
      return this.props.successMessage;
    }

    return this.props.children;
  }

  render() {
    let buttonProps = Object.assign({}, this.props);
    // ugly
    delete buttonProps.isLoading;
    delete buttonProps.isSuccess;
    delete buttonProps.isError;
    delete buttonProps.errorMessage;
    delete buttonProps.successMessage;
    delete buttonProps.loadingMessage;

    // ugly end
    return (
      <>
        <Button
          {...buttonProps}
          disabled={this.props.disabled || this.props.isLoading}
          className={clsx({
            "ta-button": true,
            [this.props.className || "ta-button"]: true,
            loading: this.props.isLoading,
            error:
              this.props.isError &&
              !this.props.isLoading &&
              !this.props.isSuccess,
            success:
              this.props.isSuccess &&
              !this.props.isError &&
              !this.props.isLoading
          })}
          startIcon={this.getStartIcon()}
        >
          {this.renderText()}
        </Button>
      </>
    );
  }
}

TAButton.defaultProps = {
  loadingMessage: "Loading",
  errorMessage: "Error",
  successMessage: "Success",
  isLoading: false,
  isSuccess: false,
  isError: false
};

TAButton.propTypes = {
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  loadingMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string
};
