import React, {Component} from 'react';
import PublicSectionedListExerciseCard from './publicSectionedExerciseCard';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';
import {WORKOUT_SECTIONS_NAMES} from '../../constants';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

export default class PublicWorkoutSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: [true],
        };
    }

    expandSection = (index, e) => {
        e.stopPropagation();
        let expanded = Object.assign([], this.state.expanded);
        expanded[index] = true;
        this.setState({
            expanded,
        });
    };
    
    collapseSection = (index, e) => {
        e.stopPropagation();
        let expanded = Object.assign([], this.state.expanded);
        expanded[index] = false;
        this.setState({
            expanded,
        });
    };

    render() {
        let section = this.props.section;
        return (
            <div id="viewWorkoutSection" className="viewWorkoutSection"
                 onClick={(e) => e.stopPropagation && e.stopPropagation()}>
                <div className="viewWorkoutSectionHeader" >
                    <Typography variant="h6" className="inputLabelBold">
                        {`${section.workoutType}${section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT
                            ? ` ${this.props.firstSection.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT ? this.props.listIndex + 1 : this.props.listIndex}` : ''}`}
                    </Typography>
                    {section.repeatCircuit && (
                        <div className="circuitDisplay">
                            Circuit Count <span>{section.repeatCircuit.count}</span>
                        </div>
                    )}
                    {section.repeatCircuit?section.repeatCircuit.description&& <Tooltip arrow={true} title={section.repeatCircuit.description}>
                            <p className="descBtn">Description</p>
                        </Tooltip>:null}
                    {this.props.collapsible !== false && <>{this.state.expanded[this.props.listIndex] !== false ?
                        <IconButton size="small" style={{marginLeft: 4}}
                                    onClick={(e) => this.collapseSection(this.props.listIndex, e)}>
                            <ExpandLessIcon color="primary"/>
                        </IconButton> :
                        <IconButton size="small" style={{marginLeft: 4}}
                                    onClick={(e) => this.expandSection(this.props.listIndex, e)}>
                            <ExpandMoreIcon color="primary"/>
                        </IconButton>}</>}
                </div>
                <Collapse in={this.state.expanded[this.props.listIndex] !== false}>
                    <div style={{paddingLeft: 12, paddingRight: 12}}>
                        {section.exercises && section.exercises.length > 0 && section.exercises.map((item, index) => {
                            return (
                                <PublicSectionedListExerciseCard
                                    selected={Boolean(this.props.selectedSectionIndex === this.props.listIndex && this.props.selectedExerciseIndex === index)}
                                    onClickExerciseMiniCard={() => this.props.onClickExerciseMiniCard(item, this.props.listIndex, index)}
                                    key={index}
                                    data={item}
                                />
                            );
                        })}
                    </div>

                </Collapse>
            </div>
        );
    }
}
