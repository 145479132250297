import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import {ERROR_CODES} from './constants'
import {askForPermissionToReceiveNotifications, messaging} from "./database/database";

let dsn = process.env.REACT_APP_SENTRY_DSN;
// if (localStorage.getItem("token")) {
//     askForPermissionToReceiveNotifications();
//     messaging.onMessage((payload) => {
//         console.log("payload in database un dasbopard", payload);
//         const notificationTitle = payload.notification.title;
//         const notificationOptions = {
//             body: payload.notification.body,
//             // icon: payload.notification
//         };
//         // ServiceWorkerRegistration.showNotification(notificationTitle, notificationOptions);
//         new Notification(notificationTitle, notificationOptions);
//     });
// }

if (dsn) {
    Sentry.init({dsn: dsn, attachStacktrace: true});

    window.captureException = (error, level) => {
        Sentry.withScope(scope => {
            scope.setLevel(level);
            scope.setTag('version', process.env.REACT_APP_VERSION);
            Sentry.captureException(error);
        });
    }
    let originalConsoleError = console.error
    console.error = (error) => {
        window.captureException(error, 'error');
        checkPermissionsError(error);
        // eslint-disable-next-line no-undef
        originalConsoleError.apply(console, arguments)
    }
} else {
    let originalConsoleError = console.error;
    console.error = (error) => {
        checkPermissionsError(error);
        // eslint-disable-next-line no-undef
        originalConsoleError(error);
    };
}

const checkPermissionsError = (error) => {
    if (error.code === ERROR_CODES.PERMISSION_DENIED) {
        window.FortisForma.database.signOut();
    }
};

ReactDOM.render(<React.StrictMode>
    <App/>
</React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
