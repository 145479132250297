import React from "react";
import { Typography, TextField, Button, Link } from "@material-ui/core";
import TaButton from "../../components/taButton";
import Timer from "../timer/timer";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { ERROR_CODES } from "../../constants";

const formaRed = "#800520";

const timerDuration = 5 * 60;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoaderContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const AuthTextField = styled(TextField)`
  margin-top: 16px !important;
  width: 100%;
  &.authTextField .MuiOutlinedInput-input {
    text-align: center;
    font-weight: 600;
    letter-spacing: 8px;
    font-size: 30px;
    padding: 8px;
    color: ${formaRed} !important;
  }
`;

export default function LoginAuthContent(props) {
  const [authCode, setAuthCode] = React.useState("");
  const [timer, setTimer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isVerifying, setVerifying] = React.useState(false);
  const [isVerified, setVerified] = React.useState(false);

  React.useEffect(() => {
    sendOTP();
  }, []);
  
  const sendOTP = async () => {
    setLoading(true);
    window.NotificationUtils.showInfo("Sending OTP");
    try {
      const result = await window.FortisForma.database.sendOtp();
      if (!result) {
        throw new Error();
      }
      if (
        result &&
        result.data &&
        result.data.message === ERROR_CODES.MFA_VERIFIED
      ) {
        window.NotificationUtils.showSuccess("Account Already Verified");
        setLoading(false);
        return props.routeToHome();
      }
      setLoading(false);
      setTimer(true);
      window.NotificationUtils.showSuccess("OTP sent successfully");
      return result;
    } catch (e) {
      console.error(e);
      setLoading(false);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  const onVerify = async () => {
    setVerifying(true);
    if (!checkErrors()) {
      setVerifying(false);
      window.NotificationUtils.showError("Incorrect OTP entered");
      return false;
    }
    try {
      const result = await window.FortisForma.database.verifyOtp(authCode);
      if (!result) {
        setVerifying(false);
        window.NotificationUtils.showError("Incorrect OTP entered");
        return;
      }
      setVerifying(false);
      setVerified(true);
      setTimer(false);
      window.NotificationUtils.showSuccess("Verified Successfully");
      window.onOTPSuccess();
    }catch (e) {
      setVerifying(false);
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  const onTimerComplete = () => {
    setTimer(false);
  };

  const checkErrors = () => {
    if (authCode && authCode.length === 6) {
      return true;
    }
    return false;
  };

  const renderLoader = () => {
    return (
      <LoaderContainer>
        <CircularProgress size={30} color="primary" />
      </LoaderContainer>
    );
  };

  const renderTextField = () => {
    return (
      <AuthTextField
        className="authTextField"
        onChange={(e) => {
          setAuthCode(e.target.value);
        }}
        // label="OTP"
        variant="outlined"
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "");
        }}
        inputProps={{
          maxLength: 6,
        }}
        value={authCode}
      />
    );
  };
  const renderTimer = () => {
    if (timer) {
      return (
        <Timer
          startTimer={timer}
          initialValue={timerDuration}
          onComplete={onTimerComplete}
          style={{ marginTop: "12px" }}
        />
      );
    }
  };
  const renderResendButton = () => {
    if (!timer && !isVerified) {
      return (
        <Container>
          <Typography
            variant="subtitle2"
            style={{ color: "grey", marginRight: "4px" }}
          >
            Didn't receive the OTP?
          </Typography>
          <span>
            <Button
              color="primary"
              component={Link}
              onClick={sendOTP}
              size="large"
              style={{ padding: 2 }}
            >
              <Typography
                variant="subtitle2"
                style={{ color: formaRed }}
              >
                RESEND
              </Typography>
            </Button>
          </span>
        </Container>
      );
    }
  };
  const renderCardContent = () => {
    return (
      <ColumnContainer>
        <img
          alt="FortisForma Logo"
          className="logo"
          src="/assets/textLogo.png"
          style={{ width: "200px" }}
          />
        <h2>
          Please Enter OTP
        </h2>
        <Typography
          variant="subtitle2"
          style={{ color: "grey"}}>
          We have sent you the one time password to your e-mail
        </Typography>
        {timer ? renderTimer() : renderResendButton()}
        {renderTextField()}
        <TaButton
          className="loginButton"
          isLoading={isVerifying}
          disabled={!checkErrors()}
          onClick={onVerify}
          variant="contained"
          color="primary"
          style={{ marginTop: 24, width: "100%" }}
          isSuccess={isVerified}
          successMessage="Verified"
          loadingMessage="Verifying"
        >
          Verify
        </TaButton>
      </ColumnContainer>
    );
  };
  return loading ? renderLoader() : renderCardContent();
}
