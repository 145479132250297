import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Spinner from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
// import { withRouter } from 'next/router';
import React, { Component } from 'react';
import { Col, Row } from '../../components/common/layoutComponents';
import PasswordInput from '../../components/passwordField/passwordComponent';
import { COLLECTIONS, RESET_PASSWORD_STATES, ROLES } from '.././../constants';

const TIMER = 5000;

function getDomainName(hostName) {
    if (hostName === "localhost") {
        return "localhost:3000"
    }
    return hostName;
}

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null,
            noRedirect: false,
            email: "",
            password: "",
            confirmPassword: "",
            code: "",
            status: RESET_PASSWORD_STATES.LOADING,
            passwordType: 'password',
            confirmPasswordType: 'password',
            remaining: TIMER / 1000,
            showPassword: false,
            showConfirmPassword: false,
        }
        this.onChangePassword = this.onChange.bind(this, "password");
        this.onChangeConfirmPassword = this.onChange.bind(this, "confirmPassword");
    
    }
 


    componentDidMount() {
        let querySearchParams = this.props.location.search;
        let params = new URLSearchParams(querySearchParams);
        let code = params.get("code");
        if (!code) {
            this.setState({
                status: RESET_PASSWORD_STATES.INVALID_CODE
            })
            this.startTimer();
            return;
        }
        window.FortisForma.database.verifyPasswordResetCode(code).then((result) => {

            this.setState({ email: result, code });
            //Valid Code
            this.setState({
                status: RESET_PASSWORD_STATES.VALID_CODE
            })
            // TODO: somehow get user role here
            // this.getUserRole(result)
        })
            .catch((e) => {
                //Invalid Code
                console.error(e);
                window.NotificationUtils.showError('Expired or Invalid Reset Code', 1500, 'bottomCenter')
                this.setState({
                    status: RESET_PASSWORD_STATES.INVALID_CODE
                })
                this.startTimer();
            })
    }

    async getUserRole(email) {
        let query = {
            collection: COLLECTIONS.USER_DATA,
            filters: [{
                "key": "email",
                "operator": "==",
                "value": email
            }]
        }
        let profiles = await window.FortisForma.database.queryData(query)
        if (profiles && profiles.length) {
            let profile = profiles[0]
            let role = profile.role;
            this.setState({
                role
            })
        }
    }

    onChange(key, event) {
        
        let update = {};
        update[key] = event.target.value;
        this.setState(update);
    }

    updatePassword = () => {
        if (this.checkErrors()) {
            return;
        }

        window.FortisForma.database.confirmPasswordReset(this.state.code, this.state.password).then(() => {
            window.NotificationUtils.showSuccess('Password Reset Successful')
            this.setState({ status: RESET_PASSWORD_STATES.RESET_COMPLETE })
            // this.startTimer()
        })
            .catch((e) => {
                console.error(e)
                window.NotificationUtils.showError('Unable to reset password')
            })
    }

    checkErrors() {
        if (!this.state.password || this.state.password === "") {
            window.NotificationUtils.showError("Please enter a valid password");
            return true;
        }

        if (this.state.password.length < 6) {
            window.NotificationUtils.showError("Password length should be greater than 6");
            return true;
        }

        if (this.state.password !== this.state.confirmPassword) {
            window.NotificationUtils.showError("Passwords don't match");
            return true;
        }
    }

    startTimer(timer = TIMER) {
        // TODO: use user role to decide redirect message
        if (this.state.role === ROLES.TRAINER || true) {
            this._startTimer(timer);
        } else {
            this.setState({
                noRedirect: true
            })
        }
    }

    _startTimer(timer = TIMER) {
        this.timer = setTimeout(() => {
            window.location = window.location.protocol + "//" + getDomainName(window.location.hostname);
        }, timer - 500);

        this.interval = setInterval(() => {
            this.setState({
                remaining: this.state.remaining - 1
            })
        }, timer / (timer / 1000));
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
        clearInterval(this.interval)
    }

    progressIndicator() {
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                margin: "auto",
                textAlign: "center",
                height: 50
            }}>
                <Spinner color="primary" />
            </div>
        )
    }
    handleClickShowPassword = (e) => {
        console.log("Handle e")
        
        this.setState({
          showPassword: !this.state.showPassword
        })
      }
    
      handleClickShowConfirmPassword = () => {
        this.setState({
          showConfirmPassword: !this.state.showConfirmPassword
        })
      }

    togglePasswordType(field, type) {

        switch (field) {
            case 1:
                this.setState({ passwordType: type });
                break;

            case 2:
                this.setState({ confirmPasswordType: type });
                break;

            default:
                break;
        }
    }

    renderResetPasswordForm() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="center">
                        <img alt='FortisForma Logo' className="logo" src="/assets/logo.png" />
                    </Col>
                </Row>
                <Card className="ForgotPasswordCard">
                    <CardContent>
                        <FormControl autoComplete="off" className='forgotPasswordFormControl' >
                            <FormGroup>
                                <FormLabel htmlFor="email" >Email</FormLabel>
                                <div style={{ fontSize: '12px', fontWeight: '600', marginBottom: 26, marginTop: "14px" }}>{this.state.email}</div>
                            </FormGroup>
                            
                            <div style={{marginBottom: "10px"}}>
                                <PasswordInput showIndicator={true} label={"Enter New Password"} showPassword={this.state.showPassword} value={this.state.password}  onChange={this.onChangePassword} name="password" inputId="password" handleClickShowPassword={this.handleClickShowPassword} />
                            </div>
                        
                            <PasswordInput showIndicator={true} label={"Confirm New Password"} showPassword={this.state.showConfirmPassword} value={this.state.confirmPassword}  onChange={this.onChangeConfirmPassword} name="password" inputId="confirmPassword" handleClickShowPassword={this.handleClickShowConfirmPassword} />
                           
                            <div className="forgotPasswordUpdateButton">
                                <Button variant="contained" onClick={this.updatePassword} color="primary">Update</Button>
                            </div>
                        </FormControl>
                    </CardContent>
                </Card>
            </React.Fragment>

        )
    }

    renderInvalidCodeForm() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="center">
                        <img alt='FortisForma Logo' className="logo" src="/assets/logo.png" />
                    </Col>
                </Row>
                <Card className="ForgotPasswordCard">
                    <CardContent>
                        <div style={{ width: '100%', height: '100%', fontSize: '12px' }}>
                            Your request to reset the password has expired or the link has already been used.
                    </div>
                    {
                            this.state.noRedirect === false && this.state.remaining > 0 && <p className="redirect" style={{ marginTop: 16 }}>Taking you back in {this.state.remaining} seconds...</p>
                    }
                    </CardContent>
                </Card>
            </React.Fragment>

        )
    }

    renderResetCompleteContent() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="center">
                        <img alt='FortisForma Logo' className="logo" src="/assets/logo.png" />
                    </Col>
                </Row>
                <Card className="ForgotPasswordCard">
                    <CardContent>
                        <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', fontSize: '12px' }}>
                            <h3>Password Reset Complete</h3>
                        </div>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }

    _render() {
        switch (this.state.status) {
            case RESET_PASSWORD_STATES.LOADING:
                return this.progressIndicator();

            case RESET_PASSWORD_STATES.INVALID_CODE:
                return this.renderInvalidCodeForm();

            case RESET_PASSWORD_STATES.VALID_CODE:
                return this.renderResetPasswordForm();

            case RESET_PASSWORD_STATES.RESET_COMPLETE:
                return this.renderResetCompleteContent();

            default:
                break;
        }
    }

    render() {
        return (
            <div className='forgotPasswordContainer' >

                {
                    this._render()
                }

            </div>)
    }
}


export default ForgotPassword