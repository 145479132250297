
import { ROLES } from '../../constants';
import AccountAction from '../../views/accountAction/accountAction';
import ClientEmailVerified from '../../views/clientEmailVerified/clientEmailVerified';
import ForgotPassword from '../../views/forgotPassword/forgotPassword';
import HomePage from "../../views/home/home";
import Login from '../../views/login/index';
import Landing from '../../views/onboarding/landing';
import Onboard from '../../views/onboarding/onboard';
import TrainerSearch from '../../views/onboarding/trainerSearch';
import Waiting from "../../views/onboarding/waiting";
import PublicWorkoutPage from "../../views/publicWorkout/publicWorkout";
import EmailVerification from '../../views/signup/emailVerification';
import Challenges from '../../views/onboarding/challenges';
import Terms from '../../views/terms/terms';
import InvalidLogin from "../../views/invalidLogin/InvalidLogin";
import Invite from "../../views/invite/index";
import LoginAuthentication from '../../views/loginAuthentication/loginAuthentication';

function routes(isLoggedIn, user) {
  return [
    {
      path: "/",
      name: "Landing",
      component: Landing
    },
    {
      path: "/public-workout",
      name: "PublicWorkout",
      component: PublicWorkoutPage,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      hideHeader: true,
      redirect: user,
      pathTo: "/home",
    },
    {
      path: "/login-authentication",
      name: "Login Authentication",
      component: LoginAuthentication,
      hideHeader: true,
      redirect: !user,
      pathTo: "/login",
    },
    {
      path: "/signup",
      name: "Signup",
      component: Onboard,
      hideHeader: true
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/forgot-password/action",
      name: "Forgot Password",
      component: ForgotPassword,
      hideHeader: true
    },
    {
      path: "/home",
      name: "HomePage",
      component: HomePage,
      hideHeader: true,
      redirect: !user || user.role !== ROLES.CLIENT,
      pathTo: "/login"
    },
    {
      path: "/account-action/action",
      name: "AccountAction",
      component: AccountAction,
      hideHeader: true
    },
    {
      path: "/verify-email/action",
      name: "Email Verification",
      component: EmailVerification,
      hideHeader: true
    },
    {
      path: "/client-email-verified",
      name: "Client Email Verified",
      component: ClientEmailVerified,
      hideHeader: true
    },
    {
      path: "/trainer-search",
      name: "Trainer Search",
      component: TrainerSearch,
      hideHeader: true
    },
    {
      path: "/waiting",
      name: "Waiting",
      component: Waiting,
      hideHeader: true
    },

    {
      path: "/challenges",
      name: "Challenges",
      component: Challenges,
      hideHeader: true
    },
    {
      path: "/invalid-login",
      name: "Invalid Login",
      component: InvalidLogin,
      hideHeader: true,
    },
    {
      path: "/invite",
      name: "Invite",
      component: Invite,
      hideHeader: true,
    },
  ];
};

export default routes;
