import React, { Component } from 'react';
import AppDownload from '../../components/appDownload';

export default class ClientEmailVerified extends Component {

    render() {
        return (
            <div className="loginBody">
                <div className="welcomeMessage">
                    <div style={{ paddingBottom: 80 }}>
                        <div className="center">
                            <img alt='FortisForma Logo' className="logo" src="/assets/logo.png"/>
                        </div>
                    </div>
                    <h3>Email Verified Successfully!</h3>
                    <p>
                        You can now access our mobile or web app with this account
                    </p>
                    <AppDownload />
                </div>
            </div>
        )
    }
}