import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Spinner from "../../components/spinner/spinner";

export default class TrainerListScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedTrainer: null,
        loading: true,
        trainerList: null
    }
}

    componentDidMount() {
        this.fetchTrainerList();
    }

    async fetchTrainerList() {
      try {
        let trainerList = await window.FortisForma.database.getTrainerList();
        this.setState({
          trainerList: trainerList,
          loading: false
        })
      }
      catch(e) {
        console.error(e);
        this.setState({ loading: false });
      }
    }

    
    trainerScreenDoneButton = () => {
      if (!this.state.selectedTrainer) {
        window.NotificationUtils.showError("Please select a trainer");
        return
      } else {
        let data = this.state.selectedTrainer && this.state.selectedTrainer.id;
        this.props.trainerScreenDoneButton && this.props.trainerScreenDoneButton(data);
      }
    }

    handleListItemClick = (data) => {
      this.setState({
        selectedTrainer: data
      })
    }

    renderListItem = () => {
      return this.state.trainerList && this.state.trainerList.data.map((item, index)=> {
        return(
          <React.Fragment key={index}>
            <ListItem key={index}
              button
              selected={this.state.selectedTrainer && this.state.selectedTrainer.id === item.id}
              onClick={()=>this.handleListItemClick(item)}
            >
              <ListItemText primary={item.name} />
            </ListItem>
            <Divider style={{width: "100%"}} />
          </React.Fragment>
        )
      })
    }

    progressIndicator() {
        return (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spinner color="primary" />
          </div>
        );
      }

    render() {
      if(this.state.loading) {
        return this.progressIndicator()
      }
        return (
            <React.Fragment>
                <div className="trainerListMainContainer">
                    <Typography variant="h5">Please select a health practitioner</Typography>
                    <List component="nav" id={!this.state.loading && "trainerListConatiner"}>
                        {this.renderListItem()}
                    </List>
                    <div className="trainerListButtonContainer">
                        <Button onClick={this.props.clientDetailCloseButton} variant="outlined" color="primary">Close</Button>
                        <Button onClick={this.trainerScreenDoneButton} variant="contained" color="primary">Done</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}