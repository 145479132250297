import React, {useEffect, useState} from "react";
import {addUserMessages, currentUserMessages,} from "../../database/database";
import {v4 as uuidv4} from "uuid";
import Button from "@material-ui/core/Button";
import {COLLECTIONS, MONTHS} from "../../constants";


function ChatRoom({trainer, user, onClose, unreadMessages, removeLastMessage}) {

    const [formValue, setFormValue] = useState("");
    const [messages, setMessages] = useState(unreadMessages);
    const [lastMessage, setLastMessage] = useState({});
    const [oldMessages, setOldMessages] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(trainer[0]);

    useEffect(() => {
        if (unreadMessages) {
            setLastMessage(unreadMessages)
        }
        if (user) {

            if (selectedTrainer) {
                removeLastMessage(selectedTrainer)
                setSelectedTrainer(selectedTrainer);
            } else {
                setSelectedTrainer(trainer[0]);
                removeLastMessage(trainer[0])

            }
            if (selectedTrainer) {
                currentUserMessages(selectedTrainer.id, user.id).then((res) => {
                    if (res.length) {
                        let result = [...res];
                        result.sort((a, b) => a.createdAt - b.createdAt);
                        setMessages(groupByDateTime(result));
                        setOldMessages(result);
                        let myDiv = document.getElementById("chatData");
                        if (myDiv !== null) {
                            myDiv.scrollTop = myDiv.scrollHeight;
                        }
                    } else {
                        setMessages({});
                    }
                });
                setSelectedTrainer(selectedTrainer);
                updateReadStatus();
            }
        }

        return () => {
            setMessages({});
        };
    }, [user, selectedTrainer, unreadMessages]);


    useEffect(() => {
        realTimeMessages();

        return () => {

        };
    }, []);
    const realTimeMessages = () => {

        window.firebase
            .firestore()
            .collection(COLLECTIONS.MESSAGE)
            .where("trainerId", "==", selectedTrainer.id)
            .where("clientId", "==", user.id)
            .onSnapshot((doc) => {
                let data = [];

                if (unreadMessages) {
                    setLastMessage(unreadMessages)
                }
                doc.docs.map((item) => {
                    data.push(item.data());
                });
                if (data.length) {
                    let result = [...data];
                    result.sort((a, b) => a.createdAt - b.createdAt);
                    setMessages(groupByDateTime(result));
                    setOldMessages(result);
                    if (result.length > oldMessages.length) {
                        let myDiv = document.getElementById("chatData");
                        if (myDiv !== null) {
                            myDiv.scrollTop = myDiv.scrollHeight;
                        }
                    }
                } else {
                    setMessages({});
                }
            });
        updateReadStatus();
    }
    const lastUnreadMessages = () => {

        window.firebase
            .firestore()
            .collection(COLLECTIONS.MESSAGE)
            .where("clientId", "==", user.id)
            .where("client_read_status", "==", false)
            .onSnapshot((doc) => {
                let datas = {};
                // onMessageListener()
                if (doc.docs.length) {
                    let result = [...trainer];
                    // let max = Math.max.apply(Math, doc.docs.map(function (o) {
                    //     return o.data().createdAt;
                    // }));
                    result.map((item) => {
                        let count = 0;
                        doc.docs.map((subItem, index) => {
                            let data = subItem.data();
                            if (data.trainerId === item.id) {
                                data.count = count + 1;
                                // if (max === data.createdAt) {
                                datas[data.trainerId] = [data]
                                // }
                                count++;
                            }
                        })
                    })
                    if (datas) {
                        setLastMessage(datas);
                    } else {
                        setLastMessage({});
                    }
                }
            });
    }
    const updateReadStatus = () => {

        window.firebase
            .firestore()
            .collection(COLLECTIONS.MESSAGE)
            .where('trainerId', '==', selectedTrainer.id)
            .where('clientId', '==', user.id)
            .where("client_read_status", '==', false)
            .get().then((doc) => {
            doc.docs.map((item) => {
                let messageRef = window.firebase
                    .firestore()
                    .collection(COLLECTIONS.MESSAGE).doc(item.id);
                messageRef.set({
                    client_read_status: true
                }, {merge: true});
            });

        });
        if (unreadMessages) {
            setLastMessage(unreadMessages)
        }
        removeLastMessage(selectedTrainer);
    }
    const groupByDateTime = (messages) => {
        let finalObj = {};
        messages.forEach((item) => {
            let yesterday = new Date(Date.now() - 86400000);
            let ts = new Date(item.createdAt);
            let today = new Date();
            const date = JSON.stringify(new Date(item.createdAt))
                .split("T")[0]
                .replace('"', "");

            const modifiedDate = date.split("-");
            let dateString = "";
            MONTHS.map((item) => {
                if (item.num === parseInt(modifiedDate[1])) {
                    if (yesterday.toDateString() === ts.toDateString()) {
                        dateString = "Yesterday";
                    } else if (ts.toDateString() === today.toDateString()) {
                        dateString = "Today";
                    } else {
                        dateString = item.str + " " + modifiedDate[2] + ", " + modifiedDate[0];
                    }
                }
            });
            if (finalObj[dateString]) {
                finalObj[dateString].push(item);
            } else {
                finalObj[dateString] = [item];
            }
        });

        return finalObj;
    };
    const convertTimeToDate = (seconds) => {
        if (seconds) {
            let yesterday = new Date(Date.now() - 86400000);
            let ts = new Date(seconds * 1000);
            let today = new Date();
            let myDate = new Date(seconds * 1000);

            function tConvert(time) {
                time = time
                    .toString()
                    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) {
                    time = time.slice(1);
                    time = time.slice(0, 3);
                    time[3] = +time[0] < 12 ? "AM" : "PM";
                    time[0] = +time[0] % 12 || 12;
                }
                return time.join("");
            }

            if (yesterday.toDateString() === ts.toDateString()) {
                return (<>
                    <b>Yesterday</b> {tConvert(myDate.toLocaleTimeString())}
                </>);
            } else if (ts.toDateString() === today.toDateString()) {
                return (<>
                    <b>Today</b> {tConvert(myDate.toLocaleTimeString())}
                </>);
            } else {
                let yesterday1 = new Date(Date.now() - 86400000);
                let ts1 = new Date(seconds);
                let myDate1 = new Date(seconds);
                if (yesterday1.toDateString() === ts1.toDateString()) {
                    return (<>
                        <b>Yesterday</b> {tConvert(myDate1.toLocaleTimeString())}
                    </>);
                } else if (ts1.toDateString() === today.toDateString()) {
                    return (<>
                        <b>Today</b> {tConvert(myDate1.toLocaleTimeString())}
                    </>);
                } else {
                    return (<>
                        <b>{new Date(seconds).toDateString()}</b>{" "}
                        {tConvert(myDate1.toLocaleTimeString())}
                    </>);
                }
            }
        }
    };
    const sendMessage = async (e) => {
        e.preventDefault();
        try {
            window.NotificationUtils.showSuccess("Sending....");
            let msgID = uuidv4();
            // await sendMessageofNotification({name: user.name, text: formValue}, selectedTrainer);

            await addUserMessages(formValue, user, selectedTrainer, msgID).then((response) => {
                removeLastMessage(selectedTrainer)
                if (unreadMessages) {
                    setLastMessage(unreadMessages)
                }
                window.NotificationUtils.showSuccess("Message Sent Successfully.");
                setFormValue("");
                if (response) {
                    currentUserMessages(selectedTrainer.id, user.id).then((res) => {
                        if (res.length) {
                            res.sort((a, b) => a.createdAt - b.createdAt);
                            setMessages(groupByDateTime(res));

                            setOldMessages(res);
                            let myDiv = document.getElementById("chatData");
                            if (myDiv !== null) {
                                myDiv.scrollTop = myDiv.scrollHeight;
                            }

                        } else {
                            setMessages({});
                        }
                    });
                    removeLastMessage(selectedTrainer);
                }
            });
            // onMessageListener();
        } catch (e) {
            window.NotificationUtils.showError("Message not sent.");
            setFormValue("");
        }

    };
    const letterName = (name) => {

        let secondName = name.split(" ")[1];
        let secondName2 = name.split("_")[1];
        if (secondName) {
            secondName = secondName[0];

        } else {

            secondName = "";
        }
        if (secondName2) {
            secondName = secondName2[0];
        }

        name = name[0] + secondName;
        return name;
    }
    // const removeLastMessage = (item) => {
    //     let last = lastMessage;
    //     delete last[item.id];
    //     setLastMessage(last);
    // }
    return (<>
        <div className="ChatRoom">
            <h4>{selectedTrainer?.name}</h4>

            <div className="msg_history">
                <div className="clientChatFlex">
                    <div className="left">

                        {trainer?.map((item) => {
                            if (trainer?.tier !== "Coaching Solution")
                                return (<Button className={`leftItem ${item.id === selectedTrainer.id && 'active'}`}
                                                onClick={() => {
                                                    setSelectedTrainer(item);
                                                    removeLastMessage(item);
                                                }}
                                >
                                        <span>{letterName(item.name)} {unreadMessages && Object.keys(unreadMessages).map((message) => {
                                            if (message === item.id) {
                                                return (<p className='counter'>{unreadMessages[message][0].count}</p>)
                                            }
                                        })}</span>

                                    <div>

                                        <h5>{item.name}</h5>
                                        {unreadMessages && Object.keys(unreadMessages).map((message) => {
                                            if (message === item.id) {
                                                return (<p><b>{unreadMessages[message][0].text}</b></p>)
                                            }
                                        })}

                                    </div>
                                </Button>);
                        })}
                    </div>
                    <div className="right">
                        <div className='chat_scroll' id="chatData">
                            {messages && Object.keys(messages).length !== 0 ? Object.keys(messages).map((message) => {
                                return (<>
                                    <p className="chat-date">{message}</p>
                                    <div className="type_msg">
                                        {messages[message].length ? (messages[message].map((item, index) => (<>
                                            {item.sender === user.role ? (<div className="outgoing_msg" key={index}>
                                                <div className="sent_msg">
                            <span className="time_date">
                              <b>
                                <i>You,</i>
                              </b>{" "}
                                {item.createdAt !== null && convertTimeToDate(item.createdAt)}
                            </span>
                                                    <p>{item.text}</p>
                                                </div>
                                            </div>) : (<div className="incoming_msg" key={index}>
                                                <div className="received_msg">
                            <span className="time_date">
                              <b>
                                <i>{selectedTrainer.name},</i>
                              </b>{" "}
                                {item.createdAt !== null && convertTimeToDate(item.createdAt)}
                            </span>
                                                    <p>{item.text}</p>
                                                </div>
                                            </div>)}
                                        </>))) : (<p className="chat-date">Say Hi!</p>)}

                                    </div>

                                </>)
                            }) : <p className="chat-date">Say Hi!</p>}
                        </div>


                        <form onSubmit={sendMessage} className="input_msg_write">
                            <input
                                value={formValue}
                                onChange={(e) => setFormValue(e.target.value)}
                                className="write_msg"
                                placeholder="Type your message here..."
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className="msg_send_btn"
                                type="submit"
                                disabled={!formValue}
                            >
                                Send
                            </Button>
                        </form>
                    </div>
                </div>
                {/* <div className="chat" id="chatData">*/}
                {/*<p className="chat-date">August 2, 2022</p>*/}

                {/* </div>*/}
            </div>
            <div className="closeChatRoom">
                <i
                    className="icon material-icons pointer"
                    onClick={() => onClose()}
                    alt="close"
                >
                    close
                </i>
            </div>
        </div>
    </>);
}

export default ChatRoom;
