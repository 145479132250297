import { Typography } from '@material-ui/core';
import React from 'react';
import DoneIcon from '@material-ui/icons/Done';

export default function ProgramListItem(props) {

    function onSelectProgram() {
        props.onSelectProgram(props.data.id)
    }

    return (
        <div 
            className={props.selected === props.data.id ? "programListItem programListItemSelected" : "programListItem"}
            onClick={onSelectProgram}
        >
            <Typography variant="body2" className="boldFont">
                Program Assigned By Trainer
            </Typography>
            {props.selected === props.data.id && <DoneIcon color="primary" />}
        </div>
            
    )
}