import { Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import Accountable from '../../assets/accountable.png';
import Diet from '../../assets/diet.png';
import Exercise from '../../assets/exercise.png';
import Properly from '../../assets/properly.png';
import Strength from '../../assets/strength.png';
import Trainer from '../../assets/trainer.png';
import Workout from '../../assets/workout.png';
import Injury from '../../assets/injury.png';
import { LOCAL_STORAGE_KEYS, ONBOARDING_TYPES, SERVICE_NAMES } from '../../constants';
import { isMobileOnly } from "react-device-detect";

export default class Onboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType: ONBOARDING_TYPES.CONSISTENCY
        }
    }

    componentDidMount(){
        if(isMobileOnly){
            this.scrollToTop()

        }
    }

    isSelected = (type, name) => {
        let flag;
        if(this.state.selectedType === ONBOARDING_TYPES.MOTIVE || this.state.selectedType === ONBOARDING_TYPES.REQUIRED_SERVICE) {
            let selectedItems = Object.assign([], this.state[this.state.selectedType]);
            flag = selectedItems.includes(name);
        }
        else {
            if(this.state[type] === name) {
                flag = true  
            }
            else {
                flag = false
            }
        }
        return Boolean(flag)
    }

    onSelectOption = (type, name) => {
        let update;
        update = {};
        if(this.state.selectedType === ONBOARDING_TYPES.MOTIVE || this.state.selectedType === ONBOARDING_TYPES.REQUIRED_SERVICE) {
            let selectedItems = Object.assign([], this.state[this.state.selectedType]);
            if(selectedItems.includes(name)) {
                const index = selectedItems.indexOf(name);
                if (index > -1) {
                    selectedItems.splice(index, 1);
                }
            }
            else {
                selectedItems.push(name);
            }
            update[type] = selectedItems;
        }   
        else {
            update[type] = name;
        }
        this.setState(
            update
        )
    }

    scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
      };

    onClickNext = () => {
        if(this.state.selectedType !== ONBOARDING_TYPES.MOTIVE){
            let nextType;
            if(this.state.selectedType === ONBOARDING_TYPES.CONSISTENCY) {
                nextType = ONBOARDING_TYPES.REQUIRED_SERVICE;
            }
            if(this.state.selectedType === ONBOARDING_TYPES.REQUIRED_SERVICE) {
                nextType = ONBOARDING_TYPES.MOTIVE;
            }
            if(isMobileOnly){
                this.scrollToTop()
            }
            this.setState({
                selectedType: nextType
            })
        }
        else {
            let data = {
                [ONBOARDING_TYPES.CONSISTENCY]: this.state[ONBOARDING_TYPES.CONSISTENCY],
                [ONBOARDING_TYPES.REQUIRED_SERVICE]: this.state[ONBOARDING_TYPES.REQUIRED_SERVICE],
                [ONBOARDING_TYPES.MOTIVE]: this.state[ONBOARDING_TYPES.MOTIVE]
            };
            try {
                window.sessionStorage.setItem(
                  LOCAL_STORAGE_KEYS.ONBOARDING_DATA,
                  JSON.stringify(data)
                );
                /*
                  !Temporary disabled trainer searach feature
                 this.props.history.push('/trainer-search')    
                */
                this.props.history.push('/challenges')
            }
            catch(e) {
                window.NotificationUtils.showError("Unable to save data")
            }
           
        }
    }

    onClickBack = () => {
        let prevType;
        if(this.state.selectedType === ONBOARDING_TYPES.REQUIRED_SERVICE) {
            prevType = ONBOARDING_TYPES.CONSISTENCY;
        }
        else {
            prevType = ONBOARDING_TYPES.REQUIRED_SERVICE;
        }
        this.setState({
            selectedType: prevType
        })
    }

    renderOption = (type, name, iconText, icon, isLarge) => {
        return (
            <div className="onboardOptionContainer">
                <div className={this.isSelected(type, name) ? "onboardOptionSelected" : "onboardOption"} 
                    onClick={() => this.onSelectOption(type, name)}>
                    {iconText ? (
                        <Typography variant="h2" style={{color: 'grey'}}>
                            {iconText}
                        </Typography>) : <img alt="Options" src={icon} className={isLarge ? "onboardOptionImage largeImgOnboard": "onboardOptionImage"}/>}
                </div>
                <Typography variant="body1" className={isMobileOnly?"mg-b-16": "mg-top-16"} style={{color: 'grey', maxWidth: 160}}>
                   {name}
                </Typography>
            </div>
        )
    }

    renderQuestion() {
        switch(this.state.selectedType) {
            case ONBOARDING_TYPES.CONSISTENCY:
                return this.renderConsistency();
            
            case ONBOARDING_TYPES.REQUIRED_SERVICE:
                return this.renderService();

            case ONBOARDING_TYPES.MOTIVE:
                return this.renderMotive();

            default:
                return null;
        }
    }
    
    renderConsistency() {
        return (
            <React.Fragment>
                <Typography variant="h6" className="pd-top-32" style={{color: 'grey'}}>
                    How often do you currently workout? Select one.
                </Typography>
                <div className="pd-top-32 flexRow" id="onboardOptionContainer">
                    {this.renderOption(ONBOARDING_TYPES.CONSISTENCY, "Not at all", "0")}
                    {this.renderOption(ONBOARDING_TYPES.CONSISTENCY, "2-4 days a week", "2-4")}
                    {this.renderOption(ONBOARDING_TYPES.CONSISTENCY, "I'm a machine", "5-7")}
                </div>
            </React.Fragment>
        )
    }

    renderService() {
        return (
          <React.Fragment>
            <Typography
              variant="h6"
              className="pd-top-32"
              style={{ color: "grey" }}
            >
              What kind of support are you looking for? <br />
              Select all that apply.
            </Typography>
            <div className="pd-top-32 flexRow" id="onboardOptionContainer">
              {this.renderOption(
                ONBOARDING_TYPES.REQUIRED_SERVICE,
                SERVICE_NAMES.STUDIO_GYM_CLINIC,
                null,
                Trainer
              )}
              {this.renderOption(
                ONBOARDING_TYPES.REQUIRED_SERVICE,
                SERVICE_NAMES.IN_HOME,
                null,
                Exercise,
                true
              )}
              {this.renderOption(
                ONBOARDING_TYPES.REQUIRED_SERVICE,
                SERVICE_NAMES.ONLINE,
                null,
                Workout
              )}
            </div>
          </React.Fragment>
        );
    }

    renderMotive() {
        return (
            <React.Fragment>
                <Typography variant="h6" className="pd-top-32" style={{color: 'grey'}}>
                    I want to work with a trainer because I want to... <br/>Select all that apply.
                </Typography>
                <div className="pd-top-32 flexRow" id="onboardOptionContainer">
                    {this.renderOption(ONBOARDING_TYPES.MOTIVE, "Lose weight", null, Diet)}
                    {this.renderOption(ONBOARDING_TYPES.MOTIVE, "Improve my strength & conditioning", null, Strength)}
                    {this.renderOption(ONBOARDING_TYPES.MOTIVE, "Be held accountable", null, Accountable)}
                    {this.renderOption(ONBOARDING_TYPES.MOTIVE, "Make sure I do things properly", null, Properly)}
                    {this.renderOption(ONBOARDING_TYPES.MOTIVE, "I have injuries and conditions that need to be considered", null, Injury)}
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className="pageContainer flexCenter">
                <div className="onboardContainer">
                    {this.state.selectedType === ONBOARDING_TYPES.CONSISTENCY && <Typography variant="h4">
                        To help us find you the best<br/> trainer, tell us about yourself
                    </Typography>}
                    {this.renderQuestion()}
                </div>
                <div className="pd-top-32">
                    { this.state.selectedType !== ONBOARDING_TYPES.CONSISTENCY && 
                        (<Button size={isMobileOnly? "large": "medium"}  variant="outlined" color="primary" onClick={this.onClickBack} className="mg-right-16 onBoardButton">
                            Back
                        </Button>)
                    }
                    <Button size={isMobileOnly? "large": "medium"} className="onBoardButton"  variant="contained" color="primary" disabled={!Boolean(this.state[this.state.selectedType])} onClick={this.onClickNext}>
                        { this.state.selectedType === ONBOARDING_TYPES.MOTIVE ? `Done` : `Next` }
                    </Button>
                </div>
            </div>
        )
    }
}