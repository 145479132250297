import Spinner from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import constants from '../../constants';

let ACCOUNT_ACTIONS = constants.ACCOUNT_ACTIONS;
class AccountAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let querySearchParams = this.props.location.search;
        let params = new URLSearchParams(querySearchParams);
        let code = params.get("oobCode");
        let mode = params.get("mode");
        let continueUrl = params.get("continueUrl");
        this.routeTo(mode, code, continueUrl)
    }

    routeTo(mode, code, continueUrl) {
        let url;
        switch(mode) {
            case ACCOUNT_ACTIONS.RESET_PASSWORD:
                url = `/forgot-password/action?code=${code}`;
                break;
            
            case ACCOUNT_ACTIONS.VERIFY_EMAIL:
                url = `/verify-email/action?code=${code}&continueUrl=${continueUrl}`;
                break;

            default:
                break;
        }
        this.props.history.replace(url);
    }

    render() {
        return (
            <div style={{ display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner color="primary" />
            </div>
        )
    }
}


export default AccountAction