import { Typography } from "@material-ui/core";
import React from "react";
import moment from "moment";
import styled from "styled-components";

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

export default function Timer(props) {
  const initialValue = props.initialValue ? props.initialValue : 60;
  const [timer, setTimer] = React.useState(initialValue);
  const [isStarted, setIsStarted] = React.useState(false);

  React.useEffect(() => {
    if (!isStarted && timer === 0) {
      props.onComplete();
    }
  }, [isStarted]);
  // When the timer hits 0, isStarted set to false. When it is set to false, call the onComplete function

  React.useEffect(() => {
    if (props.startTimer) {
      setIsStarted(true);
    }
  }, [props.startTimer]);
  // Once start timer flag received from props, set initial value, and start timer.
  React.useEffect(() => {
    if (timer === 0) {
      stopTimer();
    } else timerFunction();
  }, [timer, isStarted]);
  //Everytime timer changes, timerfunction is called until it hits 0, initially dependent on isStarted
  const timerFunction = () => {
    isStarted && setTimeout(countdown, 1000);
  };

  const countdown = () => {
    setTimer(timer - 1);
  };

  const stopTimer = () => {
    setIsStarted(false);
  };

  const formatTimerValue = (value) => {
    const remainingTime = moment.utc(value * 1000).format("m:ss");
    return remainingTime;
  };

  return (
    <Container style={props.style || {}}>
      <Typography
        variant="subtitle1"
        component="h5"
        style={{ color: "#800520" }}
      >
        {formatTimerValue(timer)}
      </Typography>
    </Container>
  );
}
