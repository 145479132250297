import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import ChangePasswordEditor from './changePasswordEditor';

class ChangePasswordModal extends React.Component {
    render() {
        return (
            <Dialog open={this.props.open} fullScreen={this.props.fullScreen} onClose={this.props.onClose} aria-labelledby="changePasswordTitle">
                <DialogTitle id="changePasswordTitle">Change Password</DialogTitle>
                <ChangePasswordEditor {...this.props} />
            </Dialog>
        );
    }
}

export default ChangePasswordModal;