import MomentUtils from "@date-io/moment";
import {DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Spinner from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EventIcon from "@material-ui/icons/Event";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import memoize from "memoize-one";
import moment from "moment";
import React from "react";
import {isMobile} from "react-device-detect";
import emailWait from "../../../src/assets/email_wait.svg";
import restDay from "../../../src/assets/rest.svg";
import trainerWait from "../../../src/assets/trainer_wait.svg";
import AppDownload from "../../components/appDownload";
import Header from "../../components/common/Header/header";
import WorkoutCompleteModal from "../../components/completeModal/completeModal";
import Consistency from "../../components/consistency/consistency";
import ReminderSetter from "../../components/reminder/reminderSetter";
import SectionedWorkout from "../../components/sectionedWorkout/sectionedWorkout";
import ChangePasswordModal from "../changePassword/changePasswordModal";
import ChooseWorkout from "../chooseWorkout/chooseWorkout";
import MyPrograms from "../myPrograms/myPrograms";
import ProfileModal from "../profile/profileModal";
import ChatModal from "../chatRoom/chatMadal";
import TrainerListScreen from "../signup/trainerList";
import ProgramHistoryHandler from "./programHistoryHandler";
import {
    ACCOUNT_STATES,
    DAYS_MAP,
    DB_KEYS,
    GOAL_STATUS,
    LOCAL_STORAGE_KEYS,
    WORKOUT_SECTIONS_NAMES,
} from "../../constants";

const NO_WORKOUT_MESSAGE = "No workout assigned for this period";

const resistanceValueMap = {
    EXTRA_LIGHT: 1, LIGHT: 2, MEDIUM: 3, HEAVY: 4, EXTRA_HEAVY: 5,
};
const weekendDays = [{label: "Monday", value: "Mon", index: 0}, {label: "Tuseday", value: "Tue", index: 1}, {
    label: "Wednesday", value: "Wed", index: 2,
}, {label: "Thursday", value: "Thu", index: 3}, {label: "Friday", value: "Fri", index: 4}, {
    label: "Saturday", value: "Sat", index: 5,
}, {label: "Sunday", value: "Sun", index: 6},];
const valueResistanceMap = {
    1: "EXTRA_LIGHT", 2: "LIGHT", 3: "MEDIUM", 4: "HEAVY", 5: "EXTRA_HEAVY",
};

function isOldLogType(log = {}) {
    try {
        let keys = Object.keys(log);
        if (keys.length > 0) {
            let loggedValue = log[keys[0]] || {};
            if (loggedValue.hasOwnProperty("exerciseId") || loggedValue.hasOwnProperty("exercise")) {
                return true;
            }
        }
    } catch (e) {
        console.error(e);
        return false;
    }

    return false;
}

const sorter = (a, b) => Number(a) - Number(b);

function getCurrentWorkoutIndex(workoutStartDate, today, daysConfig = {total: 7, repeating: false}) {
    let day;
    if (workoutStartDate) {
        day = moment.unix(workoutStartDate);
    } else {
        day = moment();
    }

    day = day.startOf("day");
    today = today || moment();
    today = today.startOf("day");

    let repeating = Boolean(daysConfig.repeating);

    let diff = today.diff(day, "days");
    if (repeating) {
        diff = diff % daysConfig.total;
    }

    return Math.abs(diff);
}

const INITIAL_STATE = {
    logId: null,
    chatStatus: true,
    chatTrainer: [],
    logExercise: null,
    paramsModalVisible: false,
    passwordModalVisible: false,
    calenderVisible: true,
    noWorkout: false,
    workoutComplete: false,
    exercises: {},
    trainerList: false,
    showCompleteModal: false,
    accountState: ACCOUNT_STATES.APPROVED,
    trainerId: "",
    exerciseOrder: [],
    currentExerciseLevel: {},
    levelsLoadedMap: {},
    workoutLog: {},
    workoutSummary: {},
    sectionsExpanded: [],
    showCalender: true,
    previewVisible: false,
    showChooseWorkoutModal: false,
    showWorkoutStartMessage: false,
    showProfileModal: false,
    showPicker: false,
    showChangePasswordModal: false,
    workoutName: "",
    showResponsiveModal: false,
    isMobile: false,
    showMyProgramModal: false,
    log: {
        sets: "", reps: "", weight: "", resistance: "", time: "",
    },
    enabled: {
        sets: false, reps: false, weight: false, resistance: false, time: false,
    },
    showConsistencyModal: false,
    showAlarmSet: false,
    showWorkoutStartMessageModal: false,
    workoutStartMessage: null,
    showResetProgramModal: false,
    messages: {},
};

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.initialize();
        this.currentDayUnix = moment().startOf("day").unix();
        this.todayUnix = moment().startOf("day").unix();

        this.sectionedWorkoutRef = React.createRef();
        //We will store the current log target if there was nothing logged today
        this.freshLogID = null;
    }

    async initialize() {
        this.programData = null;
        this.userData = await this.getUserData();
        this.assignedExercises = null;
        this.workoutId = null;
        this.workoutSummary = null;
        this.similarExercises = [];
        this.dateVSWorkoutIds = {};
        this.workoutsData = {};
        this.selectedDayUnix = null;
        this.dayIndex = null;
    }

    componentDidMount = async () => {

        if (this?.props?.user !== null) {

            await window.FortisForma.database.getTrainerData(this?.props?.user).then((response) => {
                if (response?.tier === "Coaching Solution") {
                    this.setState({chatStatus: false})
                }
            })


        }
        if (!window.FortisForma.database.currentUser()) {
            return this.logout();
        }

        this.afterMount();
        if (isMobile) {
            this.setState({
                showResponsiveModal: true, isMobile: true,
            });
        }
    };

    isWeekStart() {
        let weekStart = moment().startOf("week");
        let today = moment().startOf("day");
        var diff = Math.abs(Math.ceil(moment.duration(weekStart.diff(today)).asDays()));
        if (diff === 0) {
            return false;
        }
        if (diff <= 3) {
            return true;
        }
        return false;
    }

    isLastWeekGoal(lastGoalSetTime) {
        lastGoalSetTime = moment.unix(lastGoalSetTime);
        if (!lastGoalSetTime.isValid()) {
            return true;
        }
        let todayUnix = moment().startOf("day");
        var diff = Math.abs(Math.ceil(moment.duration(todayUnix.diff(lastGoalSetTime)).asDays()));
        if (diff >= 7) {
            return true;
        }
        return false;
    }

    hasSkippedThisWeek = () => {
        let thisWeekStart = moment().startOf("week").unix();
        let lastGoalWeek = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_GOAL_DATE);
        if (!lastGoalWeek || thisWeekStart > +lastGoalWeek) {
            return false;
        }
        return true;
    };

    checkChallengeSelected() {
        var result = false;
        var user = this.userData;
        if (user) {
            if (user.programId && user?.challenges) {
                if (user.challenges.length) {
                    var filtered = user.challenges.filter((challenge) => challenge.id === user.programId);
                    if (filtered.length > 0) {
                        result = true;
                    }
                }
            }
        }
        return result;
    }

    afterMount() {


        this.currentDayUnix = moment().startOf("day").unix();
        if (!this.props.user.accountState || this.props.user.accountState === ACCOUNT_STATES.PENDING) {
            this.setState({accountState: ACCOUNT_STATES.PENDING});
            if (!this.props.user.emailVerified) {
                this.props.recheckEmailVerification();
            }
            if (this.props.user.emailVerified && this.props.user.programId) {
                this.setState({accountState: ACCOUNT_STATES.APPROVED});
                this.fetchData();
            }
        } else if (this.props.user.accountState === ACCOUNT_STATES.REJECTED) {
            this.setState({accountState: ACCOUNT_STATES.REJECTED});
            this.fetchData();
        } else {
            this.setState({accountState: ACCOUNT_STATES.APPROVED});
            this.fetchData();
        }
        if (this.props.user.trainerId) {
            this.setState({trainerId: this.props.user.trainerId});
        }
        this.checkGoals();
        this.initialize();
        this.addProgramListener();
    }

    checkGoals() {
        let goals = this.props.user.goals;
        let lastGoalSetTime = goals && Object.keys(goals).length && Object.keys(goals)[0];
        let hasSkippedThisWeek = this.hasSkippedThisWeek();
        if ((!lastGoalSetTime || this.isLastWeekGoal(lastGoalSetTime)) && this.isWeekStart() && !hasSkippedThisWeek) {
            this.setState({
                showConsistencyModal: true,
            });
        }
    }

    onGoalsSet = (goals) => {
        this.setState({
            goals, showAlarmSet: true,
        });
    };

    onReminderSet = async (reminders, setGoals) => {
        let update = {};
        if (reminders) {
            update.reminders = reminders;
        }

        if (setGoals) {
            update.goals = this.state.goals;
        }
        this.updateGoalsLocally();
        this.closeConsistencyModal();
        this.setState({
            showAlarmSet: false,
        });
        try {
            await this.saveGoals(update);
            window.NotificationUtils.showSuccess("Saved reminders successfully");
            if (setGoals) {
                let weekStamp;
                for (let timestamp of Object.keys(this.state.goals)) {
                    weekStamp = timestamp;
                }
                await window.FortisForma.database.saveGoalsToSummary(update, weekStamp);
            }
            window.NotificationUtils.showSuccess("Saved goals successfully");
        } catch (e) {
            window.NotificationUtils.showError("Unable to save goals");
            console.error(e);
        }
    };

    async saveGoals(update) {
        try {
            await window.FortisForma.database.saveUserGoalsAndReminders(update);
        } catch (e) {
            throw e;
        }
    }

    fetchData = async () => {
        await this.fetchWorkout();
        this.fetchWorkoutSummary((summary) => {
            this.fetchWorkoutHistory(summary, () => {
                this.setHistory(summary);
            });
        });
    };

    fetchWorkoutHistory(summary, callback) {
        let programId = summary.programId;
        if (programId) {
            ProgramHistoryHandler.getProgramHistory(programId)
                .then((results) => {
                    this.setState({programHistory: results}, (results) => {
                        if (callback) {
                            callback(results);
                        }
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }

    setSpecificDateLog(date, workout) {
        let logsKey = window.workoutLogsKey(date);
        let dateLogs = this.workoutSummary?.logs[logsKey];
        let todayDate = moment().startOf("day");
        if (!dateLogs) {
            this.setState({workoutLog: {}});
            return;
        }
        if (workout && workout.id) {
            dateLogs = dateLogs[workout.id] || {};
            this.setState({workoutLog: this.mapWorkoutLogs(dateLogs)}, () => {
                this.forceUpdate();
            });
        }
    }

    async getProgramForDate(date, programId) {
        let history = this.state.programHistory;
        let programData = ProgramHistoryHandler.getProgramForDate(history, date, programId);
        return programData;
    }

    setHistory = async (summary) => {
        let startDates = summary.startDates || [moment.unix(summary.assignDate).startOf("day").unix(),];
        let summaryEndDate = moment
            .unix(summary.endDate || new Date().getTime() / 1000)
            .startOf("day");
        startDates.push(summaryEndDate.unix());
        let pairs = this.breakInPairs(startDates);
        pairs = pairs.reverse();
        for (let pair of pairs) {
            let startDate = moment.unix(pair[0]).startOf("day");
            let endDate = moment.unix(pair[1]).startOf("day");

            let programData;
            let dateVsWorkoutIds = {};

            let diff = Math.abs(endDate.diff(startDate, "days"));
            for (let day = 0; day <= diff; day++) {
                let date = moment
                    .unix(startDate.unix())
                    .add(day, "days")
                    .startOf("day");
                let timeStamp = date.unix();
                programData = await this.getProgramForDate(timeStamp, summary.programId);
                if (!programData) {
                    continue;
                }
                let dateKey = this.dateKeyFormat(date);
                let daysCount = Object.keys(programData.workoutDaysMap || {}).length;
                let i = day;
                if (programData.repeating) {
                    i = i % daysCount;
                }
                if (programData.workoutDaysMap[i]) {
                    dateVsWorkoutIds[dateKey] = programData.workoutDaysMap[i] + "_" + timeStamp;
                } else if (!this.dateVSWorkoutIds[dateKey]) {
                    dateVsWorkoutIds[dateKey] = null;
                }

                let workoutDataMap = Object.assign({}, programData.workoutDataMap);

                let workoutKeys = Object.keys(workoutDataMap);
                for (let key of workoutKeys) {
                    workoutDataMap[key + "_" + timeStamp] = workoutDataMap[key];
                }
                this.workoutsData = Object.assign({}, this.workoutsData, workoutDataMap);
            }

            this.dateVSWorkoutIds = Object.assign({}, this.dateVSWorkoutIds, dateVsWorkoutIds);
            //this.changeDate(moment.unix(this.currentDayUnix));
        }
    };

    changeDate = (date) => {
        let todayDate = moment().startOf("day");
        this.setState({
            selectedCalendarDate: date,
        });
        if (!this.checkChallengeSelected() && (!this.userData || !this.programData || this.state.accountState === ACCOUNT_STATES.PENDING || this.state.accountState === ACCOUNT_STATES.REJECTED)) {
            return window.NotificationUtils.showError(NO_WORKOUT_MESSAGE);
        }

        this.currentDayUnix = date.startOf("day").unix();
        let data = new Date(this.currentDayUnix * 1000);

        let actualValue = data.toString().split(" ")[0];
        let filterday = weekendDays.filter((item) => item.value === actualValue)[0];

        if (date < todayDate) {
            let formattedDate = moment(date).format("MM-DD-YYYY");
            let idSplit = (this.dateVSWorkoutIds[formattedDate] || "").split("_");
            let id = (idSplit || [])[0];
            let workout = this.workoutsData[this.dateVSWorkoutIds[formattedDate]] || null;
            workout && (workout.id = id);

            if (!workout) {
                // TODO: What is user did workout on this day ?
                this.setState({exerciseOrder: []});
                return;
            }
            this.setWorkout(workout, () => {
                this.setSpecificDateLog(date, workout);
            });
            return;
        }

        let daysConfig = {total: 7, repeating: false};

        if (this.programData && this.programData.workoutDaysMap) {
            daysConfig.total = Object.keys(this.programData.workoutDaysMap).length;
        }
        if (this.programData.repeating) {
            daysConfig.repeating = true;
        }

        let dayIndex = getCurrentWorkoutIndex(this.userData.programStartDate, date, daysConfig);

        this.dayIndex = dayIndex;

        if (dayIndex < 0) {
            this.setState({
                noWorkout: true, showCalender: false, calenderVisible: false,
            });

            return window.NotificationUtils.showError(NO_WORKOUT_MESSAGE);
        }

        this.currentDayUnix = date.startOf("day").unix();

        if (this.programData.workoutDisplayType) {
            if (this.programData.repeating) {
                this.setWorkoutDayIndex(filterday.index, (workout) => {
                    if (workout) {
                        this.setSpecificDateLog(date, workout);
                        if (this.sectionedWorkoutRef && this.sectionedWorkoutRef.current) {
                            this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(0, 0, 0);
                        }
                    }
                });
            } else if (dayIndex < 7 && this.programData.repeating === false) {
                this.setWorkoutDayIndex(filterday.index, (workout) => {
                    if (workout) {
                        this.setSpecificDateLog(date, workout);
                        if (this.sectionedWorkoutRef && this.sectionedWorkoutRef.current) {
                            this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(0, 0, 0);
                        }
                    }
                });
            } else {
                this.setWorkoutDayIndex(dayIndex, (workout) => {
                    if (workout) {
                        this.setSpecificDateLog(date, workout);
                        if (this.sectionedWorkoutRef && this.sectionedWorkoutRef.current) {
                            this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(0, 0, 0);
                        }
                    }
                });
            }
        } else {
            this.setWorkoutDayIndex(dayIndex, (workout) => {
                if (workout) {
                    this.setSpecificDateLog(date, workout);
                    if (this.sectionedWorkoutRef && this.sectionedWorkoutRef.current) {
                        this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(0, 0, 0);
                    }
                }
            });
        }
    };

    breakInPairs(array) {
        const pairs = [];
        for (var i = 0; i < array.length; i++) {
            pairs.push([array[i], array[i + 1]]);
        }
        return pairs;
    }

    dateKeyFormat(moment) {
        return moment.format("L").replace(/\//g, "-");
    }

    logout = async () => {
        window.localStorage.clear();
        try {
            await window.FortisForma.database.signOut();
            this.props.history.replace("/login");
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Unable to sign out");
        }
    };

    addProgramListener() {
        this.unsubscribeUserUpdates = window.FortisForma.database.subscribeToProgramUpdates(async (data) => {
            window.FortisForma.database.setUserData(data);

            if (!this.props.user.emailVerified && data && data.emailVerified) {
                this.props.recheckEmailVerification();
            }

            if (this.userData && data && (data.programId !== this.userData.programId || data.emailVerified !== this.userData.emailVerified || (data.programStartDate && this.userData.programStartDate && data.programStartDate !== this.userData.programStartDate)) && data.emailVerified) {
                window.FortisForma.database.setUserData(data);
                await this.initialize();
                this.fetchData();
                await new Promise((resolve) => {
                    this.setState(INITIAL_STATE, resolve);
                });
            }

            if (this.userData && data && data.state !== this.state.accountState) {
                if (data.state === ACCOUNT_STATES.APPROVED) {
                    this.setState({accountState: ACCOUNT_STATES.APPROVED});
                }
                if (data.state === ACCOUNT_STATES.REJECTED) {
                    this.setState({accountState: ACCOUNT_STATES.REJECTED});
                }
                if (data.state === ACCOUNT_STATES.PENDING) {
                    this.setState({accountState: ACCOUNT_STATES.PENDING});
                }
            }

            if (this.userData && data && data.trainerId) {
                if (this.state.trainerId !== data.trainerId) {
                    window.FortisForma.database.setUserData(data);
                    this.setState({
                        trainerId: data.trainerId,
                    });
                }
            }
            if (data) {
                this.setState({
                    showCalender: Boolean(data.programId), calenderVisible: false,
                });
            }
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeUserUpdates) {
            try {
                this.unsubscribeUserUpdates();
            } catch (e) {
                console.error(e);
            }
        }
    }

    async getUserData() {
        try {
            return await window.FortisForma.database.getUserData(true);
        } catch (e) {
            console.error(e);
        }
    }

    logExercise = async (log) => {
        let update = {};
        let split = this.state.logId.split("_");
        log.exerciseId = split[0];
        let sectionIndex = split[1];
        let exerciseIndex = split[2];
        let index = split[3];
        update[index] = true;
        let newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
        let isComplete = this.isWorkoutComplete(newWorkoutLog);

        // TODO: index will not always be sequential
        if (!isComplete && sectionIndex && exerciseIndex && index) {
            let toSectionIndex = +sectionIndex;
            let toExerciseIndex = +exerciseIndex;
            let toLogIndex = +index;
            try {
                if (toExerciseIndex === this.state.workoutSections[sectionIndex].exercises.length - 1) {
                    toSectionIndex = toSectionIndex + 1;
                    toExerciseIndex = 0;
                    toLogIndex = toLogIndex + 1;
                } else {
                    toExerciseIndex = toExerciseIndex + 1;
                    toLogIndex = toLogIndex + 1;
                }
            } catch (e) {
            }

            this.scrollTo(toSectionIndex, toExerciseIndex);
            if (this.sectionedWorkoutRef && this.sectionedWorkoutRef.current) {
                this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(toSectionIndex, toExerciseIndex, toLogIndex);
            }
        }

        requestAnimationFrame(async () => {
            let isChanged = this.state.logExercise?.id !== (this.assignedExercises[index] || {})?.id;

            if (isChanged) {
                log.exercise = this.state.logExercise;
            }

            requestAnimationFrame(() => {
                window.NotificationUtils.showInfo("Saving summary");
            });
            let params = {
                programId: this.programData.id,
                log,
                logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
                exerciseIndex,
                sectionIndex,
                workoutId: this.workoutId,
            };

            let startDate = this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
            if (!this.workoutSummary) {
                this.workoutSummary = {
                    logs: {
                        [params.logDate]: {
                            [this.workoutId]: {},
                        },
                    },
                };
            }

            try {
                update[index] = await window.FortisForma.database.addWorkoutLog(params, !startDate);

                if (!startDate) {
                    // Set to true so that subsequent calls dont update it
                    this.workoutSummary[DB_KEYS.START_DATES] = true;
                }

                if (!isComplete) {
                    window.NotificationUtils.showSuccess("Workout log saved");
                }
            } catch (e) {
                console.error(e);
                window.NotificationUtils.showError("Could not save log");
                update = {};
            }

            if (isComplete) {
                requestAnimationFrame(() => {
                    this.setCompleteModalVisible(true);
                });
            }

            newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
            if (!this.workoutSummary.logs[params.logDate]) {
                this.workoutSummary.logs[params.logDate] = {};
            }
            this.workoutSummary.logs[params.logDate][this.workoutId] = newWorkoutLog;

            this.setState({
                logId: null, logExercise: null, workoutLog: newWorkoutLog || {}, workoutComplete: isComplete,
            });
        });
    };

    scrollTo(toSectionIndex, toExerciseIndex) {
        let element = document.getElementById(`exerciseCard_${toSectionIndex}_${toExerciseIndex}`);
        if (element && element.scrollIntoView) {
            try {
                element.scrollIntoView({
                    behavior: "smooth", block: "start", inline: "nearest",
                });
            } catch (e) {
            }
        }
    }

    scrollToVideoPlayer() {
        let element = document.getElementById(`mainVideoContainer`);
        if (element && element.scrollIntoView) {
            try {
                element.scrollIntoView({
                    behavior: "smooth", block: "start", inline: "nearest",
                });
            } catch (e) {
            }
        }
    }

    setCompleteModalVisible = (visible) => {
        this.setState({workoutComplete: visible, showCompleteModal: visible});
    };

    isTimeEnabled = (exercise) => {
        return Boolean(Number(exercise.time));
    };
    isSetsEnabled = (exercise) => {
        return Boolean(Number(exercise.sets));
    };
    isRepsEnabled = (exercise) => {
        return Boolean(Number(exercise.reps));
    };

    isResistanceEnabled = (exercise) => {
        return Boolean(exercise.resistance);
    };

    isWeightEnabled = (exercise) => {
        return Boolean(Number(exercise.weight));
    };

    getEnabledFields = (exercise) => {
        let enabled = {};
        enabled.reps = this.isRepsEnabled(exercise);
        enabled.sets = this.isSetsEnabled(exercise);
        enabled.resistance = this.isResistanceEnabled(exercise);
        enabled.weight = this.isWeightEnabled(exercise);
        enabled.time = this.isTimeEnabled(exercise);
        return enabled;
    };

    isWorkoutComplete = (log) => {
        for (let exLog of Object.keys(log)) {
            if (!log[exLog]) {
                delete log[exLog];
            }
        }
        return (Object.keys(log || {}).length === Object.keys(this.state.exercises || {}).length);
    };
    mapWorkoutToExercises = (workout = {}) => {
        if (workout.exercises) {
            workout.exercises = workout.exercises.map((exercise, index) => {
                exercise.exerciseIndexInSection = index;
                exercise.workoutType = WORKOUT_SECTIONS_NAMES.CIRCUIT;
                exercise.section = 0;
                return exercise;
            });
            let workoutSections = [{
                workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT, set: 1, exercises: workout.exercises,
            },];
            this.setState({
                workoutSections,
            });
        } else {
            let exercises = [];

            for (let sIndex = 0; sIndex < workout.workoutSections.length; sIndex++) {
                let section = workout.workoutSections[sIndex];
                let sExercises = section.exercises;
                sExercises = sExercises.map((exercise, index) => {
                    exercise.exerciseIndexInSection = index;
                    exercise.workoutType = section.workoutType;
                    exercise.section = sIndex;
                    return exercise;
                });
                exercises = exercises.concat(sExercises);
                workout.exercises = exercises;
            }
        }
        return this.mapWorkoutToExercisesOld(workout);
    };

    mapWorkoutToExercisesOld = (workout = {}) => {
        let exercises = {};
        let sortedExercises = (workout.exercises || []).sort((a, b) => {
            return Number(a.index) - Number(b.index);
        });
        let originalIndex = 0;
        for (let exercise of sortedExercises) {
            if (!exercises[originalIndex]) {
                exercises[originalIndex] = {};
            }
            exercises[originalIndex][Number(exercise.level)] = exercise;
            originalIndex++;
        }
        return exercises;
    };

    setWorkoutDayIndex = (index, callback) => {
        this.setState({
            loading: false,
        });

        if (!this.programData) {
            window.NotificationUtils.showError(NO_WORKOUT_MESSAGE);
        }
        let workoutId = this.programData.workoutDaysMap[index || 0];
        if (!workoutId) {
            this.setState({
                exerciseOrder: [], workoutIndex: index,
            });
            return;
        }
        this.setState({
            exerciseOrder: [], workoutLog: {}, currentExerciseLevel: {}, levelsLoadedMap: {}, workoutIndex: index || 0,
        });

        const setupLogs = (workout) => {
            if (this.pendingLogSetup) {
                this.setupTodayWorkoutLog(this.pendingLogSetup[workoutId]);
                this.pendingLogSetup = null;
            }
            callback && callback(workout);
        };
        if (workoutId) {
            this.workoutId = workoutId;
            let workout = this.programData.workoutDataMap[workoutId];
            if (workout) {
                try {
                    this.setWorkout(workout, () => {
                        setupLogs(workout);
                    });
                } catch (e) {
                    console.error(e);
                    window.NotificationUtils.showError("Something went wrong on our side, error reported!");
                }
            } else {
                setupLogs();
                console.error("Missing workout data for id", workoutId);
                window.NotificationUtils.showError("Something went wrong on our side, error reported!");
            }
        } else {
            this.setState({
                noWorkout: true, showCalender: false, calenderVisible: false,
            });
        }
    };

    setWorkout(newWorkout, callback) {
        let workout = Object.assign({}, newWorkout);
        this.setState({
            workoutSections: workout.workoutSections,
        });
        let exercises = this.mapWorkoutToExercises(workout);
        this.workoutId = workout.id;

        let keys = Object.keys(exercises);
        let assignedLevels = {};
        this.assignedExercises = workout.exercises || [];

        for (let index of keys) {
            let exerciseList = exercises[index];
            let assigned = Object.values(exerciseList);
            assignedLevels[index] = Number(assigned[0].level);
        }

        this.assignedLevels = assignedLevels;

        let order = Object.keys(exercises);
        (order || []).sort(sorter);
        this.setState({
            workoutName: workout.name || "",
            exercises: exercises,
            exerciseOrder: order,
            currentExerciseLevel: assignedLevels,
        }, callback);
    }

    mapWorkoutLogs = (log = {}) => {
        let logState = {};
        let isOldType = isOldLogType(log);
        if (isOldType) {
            logState = log;
        } else {
            let order = this.state.exerciseOrder;
            for (let index of order) {
                let exercise = this.state.exercises[index][this.assignedLevels[index]];
                let logValue = log[exercise.section] && log[exercise.section][exercise.exerciseIndexInSection];
                if (logValue) {
                    logState[index] = Object.assign({}, logValue);
                }
            }
        }

        let items = Object.keys(logState);
        for (let itemIndex of items) {
            let logValue = logState[itemIndex];

            if (!logValue.section) {
                logValue.section = 0;
            }
            if (!logValue.exerciseIndexInSection) {
                logValue.exerciseIndexInSection = logValue.index || logValue.exerciseIndexInSection || 0;
            }
            logState[itemIndex] = logValue;
        }

        return logState;
    };

    getWorkoutIndex = (workoutId) => {
        for (let key of Object.keys(this.programData.workoutDaysMap)) {
            if (this.programData.workoutDaysMap[key] === workoutId) {
                return key;
            }
        }
        return undefined;
    };

    fetchWorkoutSummary = (callback) => {
        window.FortisForma.database
            .getWorkoutSummary()
            .then(async (results) => {
                if (!results) {
                    return;
                }
                if (callback) {
                    callback(results);
                }
                this.setState({workoutSummary: results});
                let today = window.workoutLogsKey();
                this.workoutSummary = results;
                let todayLogs = results.logs[today];
                let workout = this.programData.workoutDataMap[this.workoutId];
                if (!todayLogs) {
                    this.freshLogID = today;
                    return;
                }

                if (this.workoutId) {
                    todayLogs = todayLogs[this.workoutId] || {};
                    this.setupTodayWorkoutLog(todayLogs);
                } else {
                    this.pendingLogSetup = todayLogs;
                }
            })
            .catch((error) => {
                // console.warn("Error fetching workout summary", error);
            });
    };

    setupTodayWorkoutLog(todayLogs = {}) {
        let workoutLog = this.mapWorkoutLogs(todayLogs);
        let isComplete = this.isWorkoutComplete(workoutLog);
        this.setState({
            workoutLog, showCompleteModal: isComplete, workoutComplete: isComplete,
        }, () => {
            try {
                let indexes = Object.keys(workoutLog);
                (indexes || []).sort(sorter);
                let lastIndex = indexes.pop();
                if (lastIndex && !isComplete) {
                    setTimeout(() => {
                        requestAnimationFrame(() => {
                            let exerciseList = this.state.exercises[+lastIndex + 1];

                            if (exerciseList) {
                                let assignedLevel = this.assignedLevels[+lastIndex + 1] || 1;
                                let entry = exerciseList[assignedLevel];
                                if (entry && entry.section >= 0 && entry.exerciseIndexInSection >= 0) {
                                    this.scrollTo(entry.section, entry.exerciseIndexInSection);
                                }
                            }
                        });
                    }, 500);
                }
            } catch (e) {
            }
        });
    }

    fetchWorkout = async () => {
        this.setState({
            loading: true,
        });
        await window.FortisForma.database
            .getUserProgram()
            .then(async (results) => {
                let userData = await window.FortisForma.database.getUserData();
                let startDate = userData.programStartDate;
                if (!startDate) {
                    startDate = moment().startOf("day").unix();
                    await window.FortisForma.database.changeWorkoutStartDate();
                }
                //else user has never started a program
                if (results.chatTrainer) {
                    this.setState({chatTrainer: results.chatTrainer});
                }
                this.programData = results;
                this.userData = userData;

                let daysConfig = {total: 7, repeating: false};

                if (results && results.workoutDaysMap) {
                    daysConfig.total = Object.keys(results.workoutDaysMap).length;
                }
                if (results.repeating) {
                    daysConfig.repeating = true;
                }
                let dayIndex = 0;
                let data = new Date(this.currentDayUnix * 1000);

                if (results.workoutDisplayType) {
                    let actualValue = data.toString().split(" ")[0];
                    let days = weekendDays.filter((item) => item.value === actualValue)[0];
                    dayIndex = days.index;
                } else {
                    dayIndex = getCurrentWorkoutIndex(startDate, moment(), daysConfig);
                }

                this.dayIndex = dayIndex;

                if (dayIndex < 0) {
                    this.setState({
                        noWorkout: true, showCalender: false, calenderVisible: false, loading: false,
                    });
                    return window.NotificationUtils.showError(NO_WORKOUT_MESSAGE);
                }

                let dayIndexInStorage;
                try {
                    dayIndexInStorage = await localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_WORKOUT_DAY);
                } catch (e) {
                    window.NotificationUtils.showError("Unable to get selected workout from local storage");
                }
                // dayIndexInStorage = JSON.parse(dayIndexInStorage);
                dayIndexInStorage = null;
                if (dayIndexInStorage && results.workoutDaysMap && results.id === dayIndexInStorage.id && results.workoutDaysMap[dayIndexInStorage.selectedDayIndex] && moment(dayIndexInStorage.day).unix() === moment().startOf("day").unix()) {
                    dayIndex = dayIndexInStorage.selectedDayIndex;
                }
                this.setWorkoutDayIndex(dayIndex);
            })
            .catch((error) => {
                if (error.code && error.code === 404) {
                    window.NotificationUtils.showError(error.message);
                } else {
                    console.error(error);
                }

                this.setState({
                    loading: false,
                });
            });
    };

    onRemoveLog = (logResults, exercise) => {
        this.removeLogsData({logResults, exercise, paramsModalVisible: false, exerciseToLog: null}, () => {
            this.removeLog(this.state.log);
            this.setState({
                exerciseToLog: null,
            });
        });
    };

    removeLogsData({logResults, exercise, paramsModalVisible}, callback) {
        this.setState({
            logId: logResults.logId, logExercise: null, paramsModalVisible, enabled: {
                sets: this.isSetsEnabled(exercise),
                reps: this.isRepsEnabled(exercise),
                resistance: this.isResistanceEnabled(exercise),
                weight: this.isWeightEnabled(exercise),
                time: this.isTimeEnabled(exercise),
            }, log: {
                reps: "" + exercise.reps,
                sets: "" + exercise.sets,
                resistance: exercise.resistance,
                weight: "" + exercise.weight,
                time: "" + exercise.time,
            },
        }, callback);
    }

    removeLog = async () => {
        let update = {};
        let split = this.state.logId.split("_");
        let sectionIndex = split[1];
        let exerciseIndex = split[2];
        let index = split[3];
        update[index] = false;
        let newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
        let params = {
            programId: this.programData.id,
            logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
            exerciseIndex,
            sectionIndex,
            workoutId: this.workoutId,
        };
        let startDate = this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
        try {
            await window.FortisForma.database.removeWorkoutLog(params, !startDate);
            window.NotificationUtils.showSuccess("Workout log updated");
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Could not update log");
        }
        update[index] = false;

        this.setState({
            logId: null, logExercise: null, workoutLog: newWorkoutLog || {}, workoutComplete: false,
        });
    };

    onResendEmailVerification = async () => {
        try {
            await window.FortisForma.database.sendEmailVerification();
            window.NotificationUtils.showSuccess("Verification email sent");
        } catch (error) {
            console.error(error);
            window.NotificationUtils.showError("Unable to send verification email");
        }
    };

    onTrainerSelect = async (selectedTrainerId) => {
        let requestData = {};
        requestData.id = this.props.user.id;
        requestData.trainerId = selectedTrainerId;
        requestData.name = this.props.user.name;
        requestData.email = this.props.user.email;
        requestData.phone = this.props.user.phone || "";
        requestData.postal = this.props.user.postal || "";
        requestData.city = this.props.user.city || "";
        requestData.service = this.props.user.service || "";
        requestData.active = true;

        try {
            await window.FortisForma.database.createClientRequest(requestData);
            window.NotificationUtils.showSuccess("Request sent successfully");
            this.setState({accountState: ACCOUNT_STATES.PENDING});
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Unable to send request");
        }
    };
    removeSectionLog = async (section) => {
        this.setSectionLogsData(section, async () => {
            let update = {};
            let logData = this.getSectionLogData(section, true);
            let startDate = this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
            try {
                await window.FortisForma.database.removeWorkoutSectionLog(logData, !startDate);
                for (let exercise of section.data) {
                    update[exercise.index] = false;
                }
                window.NotificationUtils.showSuccess("Workout log updated");
            } catch (e) {
                console.error(e);
                window.NotificationUtils.showError("Could not update log");
            }
            let newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
            this.setState({
                logId: null, logExercise: null, workoutLog: newWorkoutLog || {}, workoutComplete: false,
            });
        });
    };

    addSectionLog = async (section) => {
        this.setSectionLogsData(section, async () => {
            let update = {};
            let logData = this.getSectionLogData(section);
            let startDate = this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
            let isComplete = false;
            let newWorkoutLog = {};
            try {
                let log = await window.FortisForma.database.addWorkoutSectionLog(logData, !startDate);
                let logIndex = 0;
                for (let exercise of section.data) {
                    update[exercise.index] = log[logIndex];
                    logIndex++;
                }
                if (!startDate) {
                    // Set to true so that subsequent calls dont update it
                    this.workoutSummary[DB_KEYS.START_DATES] = true;
                }
                newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
                isComplete = this.isWorkoutComplete(newWorkoutLog);
                if (!isComplete) {
                    window.NotificationUtils.showSuccess("Workout log saved");
                }
            } catch (e) {
                console.error(e);
                window.NotificationUtils.showError("Could not save log");
                update = {};
            }

            if (isComplete) {
                requestAnimationFrame(() => {
                    this.setCompleteModalVisible(true);
                });
            }

            if (!this.workoutSummary.logs[logData[0].logDate]) {
                this.workoutSummary.logs[logData[0].logDate] = {};
            }
            this.workoutSummary.logs[logData[0].logDate][this.workoutId] = newWorkoutLog;

            this.setState({
                logId: null, logExercise: null, workoutLog: newWorkoutLog || {}, workoutComplete: isComplete,
            });
        });
    };

    getSectionLogData = (section, removed = false) => {
        let index = 0;
        let workoutLog = [];
        for (let exercise of section.data) {
            let data;
            if (removed) {
                data = this.getRemovedExerciseLogData(index, exercise.exercise);
            } else {
                data = this.getExerciseLogData(index, exercise.exercise);
            }

            workoutLog.push(data);
            index++;
        }
        return workoutLog;
    };

    getRemovedExerciseLogData = (index) => {
        let update = {};
        if (!this.state.sectionLogs || (this.state.sectionLogs && !this.state.sectionLogs[index])) {
            return false;
        }
        let exerciseLogData = Object.assign({}, this.state.sectionLogs[index]);
        let split = exerciseLogData.logId.split("_");
        let sectionIndex = split[1];
        let exerciseIndex = split[2];
        let exindex = split[3];
        update[exindex] = false;
        let params = {
            programId: this.programData.id,
            logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
            exerciseIndex,
            sectionIndex,
            workoutId: this.workoutId,
        };
        return params;
    };

    getExerciseLogData = (index, exercise) => {
        let update = {};
        if (!this.state.sectionLogs || (this.state.sectionLogs && !this.state.sectionLogs[index])) {
            return false;
        }
        let exerciseLogData = Object.assign({}, this.state.sectionLogs[index]);
        let log = Object.assign({}, exerciseLogData.log);
        let split = exerciseLogData.logId.split("_");
        log.exerciseId = split[0];
        let sectionIndex = split[1];
        let exerciseIndex = split[2];
        let exindex = split[3];
        update[exindex] = true;
        let params = {};
        let isChanged = exerciseLogData.logExercise.id !== (this.assignedExercises[exindex] || {}).id;

        if (isChanged) {
            log.exercise = exerciseLogData.logExercise;
        }

        requestAnimationFrame(() => {
            window.NotificationUtils.showInfo("Saving summary");
        });
        params = {
            programId: this.programData.id,
            log,
            logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
            exerciseIndex,
            sectionIndex,
            workoutId: this.workoutId,
            feedback: this.state.workoutLog[Number(exindex)] && this.state.workoutLog[Number(exindex)].feedback,
        };
        if (!this.workoutSummary) {
            this.workoutSummary = {
                logs: {
                    [params.logDate]: {
                        [this.workoutId]: {},
                    },
                },
            };
        }
        return params;
    };

    setSectionLogsData = (section, callback) => {
        let sectionLogs = [];
        let exerciseLog = {};
        for (let exercise of section.data) {
            exerciseLog = {
                logId: exercise.logId, logExercise: exercise.exercise, enabled: {
                    reps: this.isRepsEnabled(exercise.exercise),
                    sets: this.isSetsEnabled(exercise.exercise),
                    resistance: this.isResistanceEnabled(exercise.exercise),
                    weight: this.isWeightEnabled(exercise.exercise),
                    time: this.isTimeEnabled(exercise.exercise),
                }, log: {
                    reps: "" + exercise.exercise.reps || "",
                    sets: "" + exercise.exercise.sets || "",
                    resistance: exercise.exercise.resistance || "",
                    weight: "" + exercise.exercise.weight || "",
                    time: "" + exercise.exercise.time || "",
                },
            };
            sectionLogs.push(exerciseLog);
        }
        this.setState({
            sectionLogs,
        }, () => {
            if (callback) {
                callback();
            }
        });
    };

    onDonePress = (logResults, exercise) => {
        this.setLogsData({logResults, exercise, exerciseToLog: null}, () => {
            this.logExercise(this.state.log);
            this.setState({
                exerciseToLog: null,
            });
        });
    };

    setLogsData({logResults, exercise, paramsModalVisible}, callback) {
        this.setState({
            logId: logResults.logId, logExercise: exercise, paramsModalVisible, enabled: {
                reps: this.isRepsEnabled(exercise),
                sets: this.isSetsEnabled(exercise),
                resistance: this.isResistanceEnabled(exercise),
                weight: this.isWeightEnabled(exercise),
                time: this.isTimeEnabled(exercise),
            }, log: {
                reps: "" + logResults.log.reps,
                sets: "" + logResults.log.sets,
                resistance: logResults.log.resistance,
                weight: "" + logResults.log.weight,
                time: "" + logResults.log.time,
                feedback: {
                    difficulty: logResults.log.difficulty || "",
                    painLevel: logResults.log.painLevel || 0,
                    feedbackText: logResults.log.feedbackText || "",
                },
            },
        }, callback);
    }

    getMaxValues = (loggedExercise, defaultExercise) => {
        let maxValues = {};
        maxValues.reps = Math.max(loggedExercise.reps, defaultExercise.reps);
        maxValues.sets = Math.max(loggedExercise.sets, defaultExercise.sets);
        maxValues.time = Math.max(loggedExercise.time, defaultExercise.time);
        maxValues.weight = Math.max(loggedExercise.weight, defaultExercise.weight);
        maxValues.resistance = valueResistanceMap[Math.max(resistanceValueMap[loggedExercise.resistance], resistanceValueMap[defaultExercise.resistance])];
        return maxValues;
    };

    openChooseWorkoutModal = () => {
        this.setState({
            showChooseWorkoutModal: true,
        });
    };

    closeChooseWorkoutModal = () => {
        this.setState({
            showChooseWorkoutModal: false,
        });
    };

    showPicker = () => {
        this.setState({
            showPicker: true,
        });
    };

    hidePicker = () => {
        this.setState({
            showPicker: false,
        });
    };

    onSkipGoals = () => {
        this.updateGoalsLocally();
        this.closeConsistencyModal();
    };

    updateGoalsLocally = () => {
        let weekStart = "" + moment().startOf("week").unix();
        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_GOAL_DATE, weekStart);
    };

    onResetProgram = async () => {
        this.setState({loading: true, showResetProgramModal: false});
        try {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_FEEDBACK_DATE);
            await window.FortisForma.database.removeDayLogs();
            await window.FortisForma.database.changeWorkoutStartDate();
            await this.initialize();
            this.fetchData();
        } catch (e) {
            console.error(e);
            this.setState({loading: false});
            window.NotificationUtils.showError("Something went wrong");
        }
    };

    renderEmailNotVerifiedPage() {
        return (<div className="homeEmailNotVerifiedContainer" style={{marginTop: 36}}>
            <img alt="Empty" className="emptyImage mailBox" src={emailWait}/>
            <div id="resendEmailContainer">
                <Typography variant="h6" color="primary">
                    Please verify your email to continue
                </Typography>
                <Button
                    onClick={this.onResendEmailVerification}
                    variant="contained"
                    color="primary"
                >
                    Resend email
                </Button>
            </div>
        </div>);
    }

    renderWaitingPage() {
        return (<div style={{textAlign: "center", marginTop: 36}}>
            <img alt="Empty" className="emptyImage" src={trainerWait}/>
            <Typography variant="h6" color="primary">
                Waiting for health practitioner to accept your request
            </Typography>
        </div>);
    }

    renderTrainerList = () => {
        return <TrainerListScreen trainerScreenDoneButton={this.onTrainerSelect}/>;
    };

    renderRejectedPage() {
        //Temporarily Disabled Trainer search
        return this.renderWaitingPage();
        return (<div className="homeTrainerListContainer">
            <Typography
                gutterBottom={true}
                align="center"
                variant="h6"
                color="primary"
            >
                Your request has been rejected, Select a new health practitioner
            </Typography>
            {this.renderTrainerList()}
            {/*from release branch*/}
            {/*  <a href="#/trainer-search" className="nonStyledLink">*/}
            {/*  <Button className="" variant="outlined" color="primary">*/}
            {/*    Connect to a health practitioner*/}
            {/*  </Button>*/}
            {/*</a>*/}
        </div>);
    }

    renderMainContent() {
        var isChallengeSelected = this.checkChallengeSelected();
        if (this.state.accountState === ACCOUNT_STATES.PENDING) {
            if (!this.props.user.emailVerified) {
                return this.renderEmailNotVerifiedPage();
            }
            return this.renderWaitingPage();
        }
        if (this.state.accountState === ACCOUNT_STATES.REJECTED) {
            return this.renderRejectedPage();
        }
        if (!this.state.exerciseOrder.length && !this.state.loading) {
            return this.renderEmptyDay();
        }
        return this.state.workoutSections && this.renderWorkoutSections();
    }

    renderEmptyDay() {
        let message = "It's a Rest Day";
        if (this.isProgramComplete()) {
            message = this.programData?.programCompleteMessage;
        }

        return (<div id="restDay" style={{textAlign: "center", marginTop: 32}}>
            <img alt="Empty" className="emptyImage" src={restDay}/>
            <Typography variant="h4" color="primary">
                {message}
            </Typography>
        </div>);
    }

    progressIndicator() {
        return (<div
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                margin: "auto",
                textAlign: "center",
                height: 50,
            }}
        >
            <Spinner color="primary"/>
        </div>);
    }

    getWorkoutName = memoize((workoutName) => {
        return workoutName;
    });

    showWorkoutSwitcher = () => {
        if (this.programData?._workoutDayIds?.length > 0 && this.programData?.id) {
            return true;
        } else {
            return false;
        }
    };

    renderHeaderContent = () => {
        let workoutName = this.getWorkoutName(this.state.workoutName);
        if (this.state.exerciseOrder?.length === 0) {
            workoutName = "Rest Day";
        }
        let showWorkoutSwitcher = this.showWorkoutSwitcher();
        return (<div id="workoutNameContainer">
            {showWorkoutSwitcher && (<Button
                variant="outlined"
                color="primary"
                id="chooseWorkoutButton"
                onClick={this.openChooseWorkoutModal}
                endIcon={<ArrowDropDownIcon/>}
            >
                {workoutName}
            </Button>)}
            <div id="datePickerContainer">
                <DatePicker
                    ref={this.datePicker}
                    variant="inline"
                    open={this.state.showPicker}
                    onOpen={this.showPicker}
                    onClose={this.hidePicker}
                    inputVariant="outlined"
                    format="MMM DD, yyyy"
                    id="datePicker"
                    value={moment(this.state.selectedCalendarDate)}
                    onChange={(date) => this.onDatePick(date)}
                />
                <IconButton
                    size="small"
                    id="datePickerIcon"
                    onClick={this.showPicker}
                >
                    <EventIcon fontSize="small"/>
                </IconButton>
            </div>
        </div>);
    };


    renderAppBar = () => {

        return (<Header
            {...this.props}
            renderHeaderContent={this.renderHeaderContent}
            user={this.props.user}
            canChat={this.state.chatStatus}
            trainer={this.props.user.chatTrainer || []}
            handleGoalSet={this.handleGoalSet}
            handleProfile={this.handleProfileClick}
            handleMessageCenter={this.handleMessageCenterClick}
            handleChangePassword={this.handleChangePasswordClick}
            handleMyPrograms={this.handleMyProgramsClick}
            handleResetProgram={this.handleResetProgramCick}
        />);
    };

    handleMyProgramsClick = () => {
        this.setState({
            showMyProgramModal: true,
        });
    };

    handleGoalSet = () => {
        this.setState({
            showConsistencyModal: true, showAlarmSet: false,
        });
    };

    handleProfileClick = () => {
        this.setState({
            showProfileModal: true,
        });
    };

    handleMessageCenterClick = () => {
        this.setState({
            showMessageCenterModal: true,
        });
    };
    handleResetProgramCick = () => {
        this.setState({
            showResetProgramModal: true,
        });
    };

    closeMyProgramsModal = () => {
        this.setState({
            showMyProgramModal: false,
        });
    };

    closeProfileModal = () => {
        this.setState({
            showProfileModal: false,
        });
    };
    closeMessageCenterModal = () => {
        this.setState({
            showMessageCenterModal: false,
        });
    };
    handleChangePasswordClick = () => {
        this.setState({
            showChangePasswordModal: true,
        });
    };

    closeChangePasswordModal = () => {
        this.setState({
            showChangePasswordModal: false,
        });
    };

    closeResponsiveModal = () => {
        this.setState({
            showResponsiveModal: false,
        });
    };

    closeResetProgramClick = () => {
        this.setState({
            showResetProgramModal: false,
        });
    };

    previousDate = () => {
        let currentDate = this.state.selectedCalendarDate || moment();
        let newDate = moment(currentDate).add(-1, "d").startOf("day");
        this.changeDate(newDate);
    };

    nextDate = () => {
        let currentDate = this.state.selectedCalendarDate || moment();
        let newDate = moment(currentDate).add(1, "d").startOf("day");
        this.changeDate(newDate);
    };

    onDatePick = (date) => {
        this.hidePicker();
        let newDate = moment(date).startOf("day");
        this.changeDate(newDate);
    };

    changeWorkout = async (workout) => {
        this.closeChooseWorkoutModal();
        this.setState({
            sectionsExpanded: [], showWorkouts: true,
        });
        let dayIndex = this.getWorkoutIndex(workout.id);
        if (dayIndex < 0) {
            window.NotificationUtils.showError("Unable to find workout");
            return;
        }
        let programStateToStore = {};
        if (workout.selectedDay) {
            programStateToStore = {
                id: this.programData.id,
                selectedDayIndex: dayIndex,
                day: moment().startOf("day"),
                selectedDay: workout.selectedDay,
            };
        } else {
            programStateToStore = {
                id: this.programData.id, selectedDayIndex: dayIndex, day: moment().startOf("day"),
            };
        }
        programStateToStore = JSON.stringify(programStateToStore);
        try {
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_WORKOUT_DAY, programStateToStore);
        } catch (e) {
            global.NotificationUtils.showError("Unable to store updated workout to storage");
        }
        let date = moment.unix(this.currentDayUnix);
        this.setWorkoutDayIndex(dayIndex, (workout) => {
            if (workout) {
                this.setSpecificDateLog(date, workout);
                if (this.sectionedWorkoutRef && this.sectionedWorkoutRef.current) {
                    this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(0, 0, 0);
                }
            }
        });
    };

    onChangeProgram = async (selectedProgramId) => {
        this.closeMyProgramsModal();

        if (this.userData.programId === selectedProgramId) {
            return;
        }
        try {
            await window.FortisForma.database.updateActiveProgram(selectedProgramId);
            window.NotificationUtils.showSuccess("Program changed successfully");
        } catch (e) {
            window.NotificationUtils.showError("Unable to change program");
            console.error(e);
        }
    };

    renderWorkoutSections = () => {
        let sections = this.getData(this.state.exerciseOrder, this.state.exercises, this.state.currentExerciseLevel, this.state.workoutLog);
        let isEnabled = this.currentDayUnix <= this.todayUnix;

        return (<React.Fragment>
            <div id="homePageWorkoutContainer">
                <SectionedWorkout
                    ref={this.sectionedWorkoutRef}
                    workoutSection={sections}
                    onDonePress={this.onDonePress}
                    onRemoveLog={this.onRemoveLog}
                    addSectionLog={this.addSectionLog}
                    removeSectionLog={this.removeSectionLog}
                    getEnabledFields={this.getEnabledFields}
                    workoutLog={this.state.workoutLog}
                    todayUnix={this.todayUnix}
                    currentDayUnix={this.currentDayUnix}
                    scrollToVideoPlayer={this.scrollToVideoPlayer}
                    isEnabled={isEnabled}
                />
            </div>
        </React.Fragment>);
    };

    workoutCompleteDone = async (feedback, updateDate) => {
        this.setCompleteModalVisible(false);
        let feedbackAdded = false;
        for (let key of Object.keys(feedback)) {
            if (feedback[key]) {
                feedbackAdded = true;
            }
        }

        let data = {
            programId: this.programData.id,
            logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
            workoutId: this.workoutId,
        };

        if (feedbackAdded && feedback) {
            data.feedback = feedback;
        }

        try {
            if (feedbackAdded) {
                await this.updateFeedbackDateLocally();
            }
            let weekStartUnix = moment().utc().startOf("week").unix();
            let todayDay = moment().day();
            let day;
            if (todayDay === 0) {
                day = "SUN";
            } else {
                day = DAYS_MAP[todayDay];
            }
            let goalUpdate = {};
            if (this.props.user.goals && this.props.user.goals[weekStartUnix] && this.props.user.goals[weekStartUnix][day] === GOAL_STATUS.PENDING) {
                goalUpdate.weekUnix = weekStartUnix;
                goalUpdate.day = day;
            }
            if (feedbackAdded || goalUpdate.day) {
                await window.FortisForma.database.saveWorkoutFeedback(data, updateDate, goalUpdate);
            }
            if (goalUpdate.day) {
                await window.FortisForma.database.updateUserGoals(goalUpdate);
            }
            window.NotificationUtils.showSuccess("Feedback saved successfully");
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Unable to save feedback");
        }
    };

    updateFeedbackDateLocally = async () => {
        try {
            await localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_FEEDBACK_DATE, this.todayUnix);
        } catch (e) {
            console.error(e);
        }
    };

    isProgramComplete = (isWorkoutComplete = false) => {
        if (this.currentDayUnix < this.todayUnix) {
            return false;
        }
        let totalWorkoutDays = Object.keys(this.programData?.workoutDaysMap || {}).length;
        let finalDayIndex = totalWorkoutDays - 1;
        if (isWorkoutComplete) {
            return this.dayIndex >= finalDayIndex;
        }
        return this.dayIndex > finalDayIndex;
    };

    renderWorkoutCompleteModal = () => {
        let message = this.programData?.workoutCompleteMessages?.[this.state.workoutIndex];
        if (this.isProgramComplete(true)) {
            message = this.programData?.programCompleteMessage;
        }
        return (<Dialog id="workoutCompleteDialog" open={this.state.showCompleteModal}>
            <WorkoutCompleteModal
                message={message}
                workoutCompleteDone={this.workoutCompleteDone}
                setCompleteModalVisible={this.setCompleteModalVisible}
                lastFeedbackDate={this.state.workoutSummary.feedbackDate}
                todayUnix={this.todayUnix}
            />
        </Dialog>);
    };

    renderChooseWorkoutModal = () => {
        return (<Dialog
            fullScreen={this.state.isMobile}
            id="chooseWorkoutDialog"
            open={this.state.showChooseWorkoutModal}
        >
            <ChooseWorkout
                onClose={this.closeChooseWorkoutModal}
                workouts={Object.assign({}, this.programData)}
                selectedWorkoutId={this.workoutId}
                changeWorkout={this.changeWorkout}
            />
        </Dialog>);
    };

    getData = memoize((exerciseOrder, exercises, currentExerciseLevel, workoutLogs) => {
        let mapped = exerciseOrder
            .map((index) => {
                let exerciseList = exercises[index];
                let selectedLevel = currentExerciseLevel[index];
                if (!selectedLevel) {
                    selectedLevel = 0;
                }
                let exercise = exerciseList[selectedLevel];
                if (!exercise) {
                    return {index};
                }
                let assigned = this.assignedExercises[index];
                if (!assigned) {
                    console.error(`Missing assigned exercises index ${index}, assigned keys, ${Object.keys(this.assignedExercises)}`);
                    return {index, exclude: true};
                }
                if (assigned.id !== exercise.id) {
                    exercise.reps = assigned.reps;
                    exercise.sets = assigned.sets;
                    exercise.weight = assigned.weight;
                    exercise.time = assigned.time;
                    exercise.resistance = assigned.resistance;
                }
                if (this.currentDayUnix <= this.todayUnix) {
                    let workoutLog = workoutLogs && workoutLogs[index];
                    if (workoutLog && workoutLog.exerciseId !== exercise.id) {
                        if (workoutLog.exercise) {
                            if (workoutLog.exerciseId !== exercise.id && exercise.id === assigned.id) {
                                workoutLog.exercise.workoutType = exercise.workoutType;
                                workoutLog.exercise.section = exercise.section;
                                workoutLog.repeatCircuit = exercise.repeatCircuit;
                                workoutLog.exercise.index = exercise.index;
                                workoutLog.exercise.exerciseIndexInSection = exercise.exerciseIndexInSection;
                                exercise = workoutLog.exercise;
                            }
                            if (this.currentDayUnix === this.todayUnix && !Boolean(this.freshLogID)) {
                                let maxValues = this.getMaxValues(workoutLog, exercise);
                                exercise = Object.assign({}, exercise, maxValues);
                            } else {
                                exercise = Object.assign({}, exercise, workoutLog);
                            }
                        }
                    } else if (workoutLog) {
                        let logged = Object.assign({}, exercise, workoutLog);
                        if (this.currentDayUnix === this.todayUnix && !Boolean(this.freshLogID)) {
                            let maxValues = this.getMaxValues(logged, exercise);
                            delete maxValues.section;
                            delete maxValues.exerciseIndexInSection;
                            delete maxValues.index;
                            delete maxValues.workoutType;
                            exercise = Object.assign({}, exercise, maxValues);
                        } else {
                            delete logged.section;
                            delete logged.exerciseIndexInSection;
                            delete logged.index;
                            delete logged.workoutType;
                            exercise = Object.assign({}, exercise, logged);
                        }
                    }
                }
                if (!exercise) {
                    return {index};
                }
                let logId = `${exercise.id}_${exercise.section}_${exercise.exerciseIndexInSection}_${index}`;
                return {
                    logId, index, exercise, exerciseList,
                };
            })
            .filter((entry) => !entry.exclude);
        // REVIEW decide if need mapping
        let sections = [];
        for (let entry of mapped) {
            if (!sections[entry.exercise.section]) {
                sections[entry.exercise.section] = {
                    workoutType: entry.exercise.workoutType,
                    repeatCircuit: this.state.workoutSections[entry.exercise.section].repeatCircuit,
                    index: entry.exercise.section,
                    sets: this.state.workoutSections?.[entry.exercise.section]?.sets || null,
                    data: [],
                };
            }
            sections[entry.exercise.section].data.push(entry);
        }
        return sections;
    });

    closeConsistencyModal = () => {
        this.setState({
            showConsistencyModal: false,
        });
    };

    renderConsistencyModal() {
        return (<Dialog
            id="consistencyModal"
            open={this.state.showConsistencyModal}
            onClose={this.onSkipGoals}
        >
            {!this.state.showAlarmSet ? (<Consistency
                onClose={this.onSkipGoals}
                onGoalsSet={this.onGoalsSet}
                user={this.userData}
            />) : (<ReminderSetter
                goals={this.state.goals}
                onReminderSet={this.onReminderSet}
                onSkip={this.onReminderSet}
            />)}
        </Dialog>);
    }

    renderResetProgramModal = () => {
        return (<Dialog
            open={this.state.showResetProgramModal}
            onClose={this.closeResetProgramClick}
        >
            <DialogTitle>Reset Program</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to reset this program?</Typography>
                <Typography variant="subtitle2" className="greyColor marginTop8">
                    Note: This will reset the program and remove today's workout logs,
                    it may also change the day, you can re-select this day at the top of
                    the screen, if you want.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={this.closeResetProgramClick}>
                    Cancel
                </Button>
                <Button color="primary" onClick={this.onResetProgram}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>);
    };

    render() {
        if (this.state.loading) {
            return this.progressIndicator();
        }
        this.shouldShowStartMessage(this.state.workoutSummary, this.workoutId);
        return (<MuiPickersUtilsProvider utils={MomentUtils}>
            {this.renderAppBar()}
            {this.renderMainContent()}
            {this.renderWorkoutCompleteModal()}
            {this.renderChooseWorkoutModal()}
            {this.renderResponsiveModal()}
            {this.state.isMobile && this.renderFloatingScrollButton()}
            {this.state.showConsistencyModal && this.userData && this.renderConsistencyModal()}
            {this.renderWorkoutStartMessage()}
            {this.renderMyProgramsModal()}
            {this.renderResetProgramModal()}
        </MuiPickersUtilsProvider>);
    }

    getWorkoutStartMessage = (workoutId) => {
        let index = this.getWorkoutIndex(workoutId);
        if (!index) {
            return false;
        }
        let message = this.programData?.workoutStartMessages?.[index];
        if (!message) {
            return false;
        }
        return message;
    };

    hasLogs = (workoutSummary, workoutId) => {
        let today = window.workoutLogsKey();
        let todayLogs = (workoutSummary?.logs && workoutSummary?.logs[today]) || {};
        let workoutLogs = todayLogs[workoutId];
        if (workoutLogs && Object.keys(workoutLogs).length > 0) {
            return true;
        }
        return false;
    };

    shouldShowStartMessage = memoize((workoutSummary, workoutId) => {
        let todayDate = moment().startOf("day");
        if (!workoutId || !workoutSummary || (workoutSummary && Object.keys(workoutSummary).length < 1) || this.state.showCompleteModal || (this.state.selectedCalendarDate && !moment(this.state.selectedCalendarDate).isSame(moment(todayDate)))) {
            return false;
        }
        let message = this.getWorkoutStartMessage(workoutId);
        if (!message) {
            return false;
        }
        if (this.hasLogs(workoutSummary, workoutId)) {
            return false;
        }
        this.setState({
            workoutStartMessage: message, showWorkoutStartMessageModal: true,
        });
    });

    renderWorkoutStartMessage = () => {
        const closeDialog = () => {
            this.setState({
                showWorkoutStartMessageModal: false,
            });
        };
        return (<Dialog
            open={this.state.showWorkoutStartMessageModal}
            onClose={closeDialog}
            aria-labelledby="workout-start-title"
        >
            <DialogTitle id="workout-start-title">Before you begin</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {this.state.workoutStartMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="default">
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
    };

    renderFloatingScrollButton() {
        return (<IconButton
            color="primary"
            id="floatingScrollButton"
            onClick={this.scrollToVideoPlayer}
        >
            <ExpandMoreIcon style={{color: "white"}}/>
        </IconButton>);
    }

    renderProfileEditorModal() {
        return (<ProfileModal
            fullScreen={this.state.isMobile}
            open={this.state.showProfileModal}
            onClose={this.closeProfileModal}
            user={this.props.user}
        />);
    }

    renderMessageCenterModal() {
        // let trainerData = [];
        // if (this.state.chatTrainer) {
        //     trainerData = [...new Map(this.state.chatTrainer.map((m) => [m.id, m])).values()];
        // }

        return (<ChatModal
            fullScreen={this.state.isMobile}
            open={this.state.showMessageCenterModal}
            onClose={this.closeMessageCenterModal}
            user={this.props.user}
            trainer={this.state.chatTrainer || []}
        />);
    }

    renderChangePasswordModal() {
        return (<ChangePasswordModal
            fullScreen={this.state.isMobile}
            open={this.state.showChangePasswordModal}
            onClose={this.closeChangePasswordModal}
            user={this.props.user}
        />);
    }

    renderMyProgramsModal() {
        return (<Dialog
            open={this.state.showMyProgramModal}
            onClose={this.closeMyProgramsModal}
            aria-labelledby="programs-selector"
            maxWidth="md"
        >
            <DialogTitle id="programs-selector">
                My Programs {"&"} Challenges
            </DialogTitle>
            <MyPrograms
                accountState={this.state.accountState}
                onClose={this.closeMyProgramsModal}
                user={this.userData}
                onChangeProgram={this.onChangeProgram}
            />
        </Dialog>);
    }

    renderResponsiveModal() {
        return (<Dialog open={this.state.showResponsiveModal}>
            <div id="responsiveDialog">
                <Typography align="center" variant="h6" className="mg-bt-16">
                    Please use our Android or iOS app for the best experience
                </Typography>
                <AppDownload excludeDesktop={true}/>
                <Button
                    variant="contained"
                    color="primary"
                    className="mg-tp-16"
                    onClick={this.closeResponsiveModal}
                >
                    Continue anyway
                </Button>
            </div>
        </Dialog>);
    }
}
