import moment from 'moment';

let programCache;
export default class ProgramHistoryHandler {

    static async getProgramHistory(programId) {
        try {
            return window.FortisForma.database.getProgramHistory(programId);
        } catch (e) {
            throw e;
        }
    }

    static async getProgramForDate(programHistory, date, programId) {
        
        let keys = Object.keys(programHistory);
        keys = keys.filter((key) => {
            return isNaN(key) === false;
        })
        let keysMap = {}
        keys = keys.map((key) => {
            let value = moment.unix(key).startOf('day').unix()
            keysMap[value] = key;
            return value;
        })

        let timestamp = Math.max.apply(Math, keys.filter((key) => key <= date))
        
        let programData = programHistory[keysMap[timestamp]];
        
        if(typeof programData === 'string' || programData instanceof String) {
            if (!programCache) {
                programCache = {};
            }
            if (programCache[programData + programId]) {
                programData = programCache[programData + programId];
            } else {
                let newProgram = await window.FortisForma.database.getProgramFromHistory(programData, programId);
                programCache[programData + programId] = newProgram;
                programData = newProgram;
            }
        }
        return programData || null
    }

}