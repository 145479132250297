import {
  Card,
  CardContent,
  CardActions,
  Link,
  InputAdornment,
  Typography,
  Button
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import styled from "styled-components";
import PasswordInput from "../../components/passwordField/passwordComponent";
import TaButton from "../../components/taButton";
import Signup from "../signup/index";
  const InputContainer = styled.div`
    margin-bottom: 2.5rem;
  `;

  
  export default class Invite extends Signup {  
    renderSignupForm() {
      return (
        <div className="inviteSignUpContentContainer" >  
          <InputContainer>
            <TextField
              size="small"
              fullWidth = {true}
              label="Email"
              variant="outlined"
              required
              disabled={this.state.disableEmail}
              value={this.state.email}
              onChange={this.onChange_email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon color="disabled" fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </InputContainer>
  
          <InputContainer>
            <PasswordInput
              label={"Password"}
              showIndicator={true}
              value={this.state.password}
              onChange={this.onChange_password}
            />
          </InputContainer>
  
          <InputContainer>
            <PasswordInput
              label={"Confirm Password"}
              showIndicator={true}
              value={this.state.confirmPassword}
              onChange={this.onChange_confirmPassword}
            />
          </InputContainer>
        </div>
      );
    }
  
    renderSignupFields = () => {
      return (
        <React.Fragment>
          <CardContent>{this.renderSignupForm()}</CardContent>
  
          <CardActions className="justifyContentFlexEnd">
            <TaButton
              isLoading={this.state.loading}
              loadingMessage={"Creating Account"}
              variant="contained"
              color="primary"
              onClick={() => this.signUp(true)}
            >
              Sign Up
            </TaButton>
          </CardActions>
        </React.Fragment>
      );
    };

    renderAppBar() {
      return (
        <div id="inviteLandingAppBar">
          <div className="flexCenter">
            <img alt="Logo" src={"/assets/arrow-logo.png"} id="inviteArrowLogo" />
            <img alt="Logo" src={"/assets/text-logo.png"} id="textLogo" />
          </div>
          <div>
            <Button
              style={{ marginTop: 1 }}
              variant="outlined"
              color="primary"
              onClick={this.gotoLogin}
            >
              Log in
            </Button>
          </div>
        </div>
      );
    }
  
    render() {
      return <div className="signUpCardContainer">
        {this.renderAppBar()}
        <Card className="signUpInviteCardContainer" elevation={0} variant="outlined">
          <Typography variant="h4" className="boldFont mg-bt-32">
            Set a password to complete the sign up process
          </Typography>
          {this.renderSignupFields()}
          </Card>
        </div>
    }
  }
  