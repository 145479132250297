import React, { Component } from "react";
import { Typography, Button, Collapse, IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export default class TrainerPrograms extends Component {
  state = {
    collapsed: {},
  };

  toggleSection = (pIndex, index) => {
    let collapsed = Object.assign([], this.state.collapsed);
    if (!collapsed[pIndex]) {
      collapsed[pIndex] = {};
    }
    collapsed[pIndex][index] = !Boolean(collapsed[pIndex][index]);
    this.setState({
      collapsed,
    });
  };

  renderPackage(pack, index, pIndex) {
    return (
      <div key={index} className="trainerPackage">
        <div className="packageNameContainer">
          <Typography
            variant="body2"
            className="trainerProgramNameContainer"
            color="primary"
          >
            {pack.name}
          </Typography>
          {this.state.collapsed[pIndex] &&
          this.state.collapsed[pIndex][index] ? (
            <IconButton
              size="small"
              onClick={() => this.toggleSection(pIndex, index)}
            >
              <ExpandLessIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => this.toggleSection(pIndex, index)}
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </div>
        <Collapse
          in={
            Boolean(
              this.state.collapsed[pIndex] &&
                this.state.collapsed[pIndex][index]
            ) || false
          }
          timeout="auto"
        >
          <div className="pricingContainer">
            <div className="flexRow programPrice">
              <Typography variant="h5">$</Typography>
              <Typography variant="h5">{pack.price}</Typography>
              {pack.per && (
                <Typography
                  variant="caption"
                  className="greyColor letterSpacingInc"
                >
                  /{pack.per}
                </Typography>
              )}
              {Boolean(pack.extras?.length) &&
                pack.extras.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      variant="caption"
                      className="greyColor letterSpacingInc"
                    >
                      +{item}
                    </Typography>
                  );
                })}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  renderProgram(program, pindex) {
    return (
      <div key={pindex} className="trainerProgram">
        <div className="programsHeader">
          <Typography variant="subtitle2" className="boldFont">
            {program.name}
          </Typography>
        </div>
        <Typography variant="body2" className="greyColor">
          {program.description}
        </Typography>
        <Typography
          variant="caption"
          color="initial"
          style={{ marginTop: 16, display: "inline-block" }}
        >
          Service Variations and Price
        </Typography>
        {program.packages?.length &&
          program.packages.map((pack, index) => {
            return this.renderPackage(pack, index, pindex);
          })}
      </div>
    );
  }
  render() {
    return (
      <div
        className={
          this.props.isActive
            ? "trainerCard trainerCardRadius"
            : "trainerCard trainerCardRadius opacityZero"
        }
      >
        <div className="trainerCardContentContainer">
          <Typography variant="subtitle2" className="trainerInfoTitle">
            Services Offered
          </Typography>
          {this.props.selectedTrainer?.programs?.length &&
            this.props.selectedTrainer.programs.map((program, index) => {
              return this.renderProgram(program, index);
            })}
        </div>
        <div className="trainerProfileButtons">
          <Button
            variant="outlined"
            color="primary"
            className="mg-tp-16 onBoardButton"
            size="small"
            onClick={() => this.props.onClose()}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="mg-tp-16 onBoardButton"
            size="small"
            onClick={() =>
              this.props.selectTrainer(this.props.selectedTrainer)
            }
          >
            Contact
          </Button>
        </div>
      </div>
    );
  }
}
