import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import Fitness from "../../assets/fitness.png";
import { GOAL_STATUS, DAYS_MAP, DAYS_NAME_MAP } from '../../constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import memoize from "memoize-one";

export default class Consistency extends React.Component {
    constructor(props){
        super(props);
        this.state={
            selectedDays: {}
        }
    }
    
    onDaySelect = (selectedDayIndex) => {
        let selectedDays = Object.assign({}, this.state.selectedDays);
        if(selectedDays[selectedDayIndex] === true) {
            delete selectedDays[selectedDayIndex];
        }
        else {
            selectedDays[selectedDayIndex] = true;
        }
        this.setState({
            selectedDays
        })
    }

    onSkipPress = () => {
        this.props.onClose();
    }

    onContinuePress = () => {
        let goals = {}
        let weekStartUnix = moment().utc().startOf('week').unix();
        goals[weekStartUnix] = {};
        for(let dayIndex of Object.keys(this.state.selectedDays)) {
            goals[weekStartUnix][DAYS_MAP[dayIndex]] = GOAL_STATUS.PENDING
        }
        this.props.onGoalsSet(goals);
    }

    getCurrentGoals = memoize((user) => {
        let allDays = [];
        let goalTime = null;
        if (user && user.goals && Object.keys(user.goals).length) {
            let weeks = Object.keys(user.goals);
            let daysObject = user.goals[weeks[0]];
            let days = Object.keys(daysObject);
            for (let day of days) {
                allDays.push(DAYS_NAME_MAP[day]);
            }
        }
        if (user.reminders && Object.keys(user.reminders).length) {
            let reminders = Object.keys(user.reminders);
            let firstReminder = reminders[0];
            goalTime = moment.unix(firstReminder).format("hh:mm a");
        }
        return { allDays, goalTime };
    })

    getAllDaysText = (allDays) => {
        return (
                allDays.map((day, index) => {
                    if (index && index === allDays.length - 1) {
                        return ` & ${day}`
                    }
                    return `${index > 0 ? `,` : ""} ${day}`
                })
        )
    }

    renderImage=()=>{
        return (<img id="fitnessImg" className="marginTop32" src={Fitness} alt="Consistency" />)
    }

    renderTitle =() =>{
        return (
            <Typography className="marginTop32" variant="h6" color="primary">
                How many workouts do you plan to do this week ?
            </Typography>
        )
    }

    renderActiveGoals = (allDays, goalTime) => {
        if (!allDays.length) {
            return null;
        }
        return (
            <div id="currentGoals" className="marginTop32">
                <Typography variant="body2" color="secondary">
                    Current Goal: Every {this.getAllDaysText(allDays)}{goalTime && " at " + goalTime}
                </Typography>
            </div>
        )
    }

    renderNumberOfDays =() =>{
        let count = Object.keys(this.state.selectedDays).length;
        return(
            <Typography className="marginTop32 inputLabelBold" variant="h6" color="primary">
                {count} days a week
            </Typography>
        )
    }

    renderDays = () => {
        return (
            <div id="dayContainer" className="marginTop32"> 
                {this.renderDay("S", 1)}
                {this.renderDay("M", 2)}
                {this.renderDay("T", 3)}
                {this.renderDay("W", 4)}
                {this.renderDay("T", 5)}
                {this.renderDay("F", 6)}
                {this.renderDay("S", 7)}
            </div>
        )
    }

    renderDay = (day, dayIndex) => {
        let isSelected = Object.keys(this.state.selectedDays).includes(""+dayIndex);
        if(isSelected) {
            return (
                <Button  className="workoutDays" variant="contained" color="primary" onClick={() => this.onDaySelect(dayIndex)}>
                    {day}
                </Button>
            )
        }
        return (
            <Button className="workoutDays " variant="outlined" color="primary" onClick={() => this.onDaySelect(dayIndex)}>
                {day}
            </Button>
        )
    }

    renderActionButton = ()=>{
        let count = Object.keys(this.state.selectedDays).length;
        return(
            <React.Fragment>
                <Button className="marginTop32 marginBottom32" fullWidth={true} color="primary" onClick={this.onSkipPress}>
                    Skip
                </Button>
                <Button className="marginTop32 marginBottom32" disabled={Boolean(count<1)} fullWidth={true} color="primary" variant="contained" onClick={this.onContinuePress}>
                    Continue
                </Button>
            </React.Fragment>
        )
    }
    
    
    render(){
        let { allDays, goalTime } = this.getCurrentGoals(this.props.user);
        return(
            <React.Fragment>
                <DialogContent id="consistencyMainContainer" className="textAlignCenter skinColor" >
                    {this.renderImage()}
                    {this.renderTitle()}
                    {this.renderActiveGoals(allDays, goalTime)}
                    {this.renderNumberOfDays()}
                    {this.renderDays()}
                </DialogContent>
                <DialogActions id="consistencyActionButton" className="skinColor">
                    {this.renderActionButton()}   
                </DialogActions>
            </React.Fragment>
        )
    }

}