import React from 'react';
import {Dialog, DialogTitle} from '@material-ui/core';
import ChatRoom from './ChatRoom';



class ChatModal extends React.Component {

    render() {

        return (
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                fullWidth
                maxWidth="md"
                open={this.props.open}
                onClose={this.props.onClose} disableBackdropClick disableEscapeKeyDown aria-labelledby="ChatRoomTitle">


                <ChatRoom shouldCloseOnOverlayClick={false}  {...this.props} />
            </Dialog>
        );
    }
}

export default ChatModal;