import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px;
`;

const Heading = styled(Typography)`
  margin-top: 16px;
`;

class Terms extends Component {
  render() {
    return (
      <Container>
        <Typography variant="h3" color="primary">
          Fortis Forma
        </Typography>
        <Heading variant="h6" color="initial">
          Terms and Conditions
        </Heading>
        <Typography variant="body2" color="initial">
          Information published on this web site is provided for the use of its
          visitors and you are advised that, although care has been taken to
          ensure technical and factual accuracy, some errors may occur. No
          guarantee is given of the accuracy or completeness of information on
          these pages. Please be aware that Fortis Forma may alter the
          information on its web site from time to time. Fortis Forma shall have
          no liability arising from the use by any party of the information on
          this web site. You are advised to make your own virus checks and to
          implement your own precautions in this respect. Fortis Forma excludes
          all liability for contamination or damage caused by any virus or
          electronic transmission.
        </Typography>

        <Heading variant="h6" color="initial">
          Privacy Policy
        </Heading>

        <Typography variant="body2" color="initial">
          Fortis Forma understands your right to privacy of personal
          information. We recognize that you have provided us with, and will be
          providing us on an ongoing basis, information such as your contact
          information. This information will be used to help us connect you with
          health practitioners and communicate with you directly. Your personal
          information will not be released without your consent.  All employees
          and contractors of Fortis Forma will adhere to our privacy policy.
        </Typography>
      </Container>
    );
  }
}

export default Terms;
