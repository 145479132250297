import React, { useEffect, useState } from 'react';
import { DialogContent, DialogActions, Divider, Typography, Button } from '@material-ui/core';
import ProgramListItem from './programListItem';
import ChallengeListItem from './challengeListItem';

export default function MyPrograms(props){
    const [selectedProgram, setSelectedProgram] = useState(null);

    useEffect(() => {
        if(props?.user?.programId) {
            setSelectedProgram(props.user.programId);
        }
    }, [])

    function onSelectProgram(programId) {
        setSelectedProgram(programId);
    }

    function onChangeProgram() {
        props.onChangeProgram(selectedProgram);
    }

    function renderPrograms() {
        return (
            <div>
                {props?.user?.programs?.map((program) => {
                    return <ProgramListItem key={program.id} data={program} selected={selectedProgram} onSelectProgram={onSelectProgram} />
                })}
            </div> 
        )
    }

    function renderChallenges() {
        return (
            <div>
                {props?.user?.challenges?.map((challenge) => {
                    return <ChallengeListItem key={challenge.id} data={challenge} selected={selectedProgram} onSelectProgram={onSelectProgram}/>
                })}
            </div>
        )
    }

    function renderPlaceholder(text) {
        return (
            <Typography align="center" className="padVert16 textColorGray" >
                No {text} Found
            </Typography>
        )
    }

    return (
        <>
            <DialogContent className="myProgramsContent">
                <Typography variant="subtitle1">Programs</Typography>
                {(props?.user?.programs && props.user.programs.length > 0) ? renderPrograms() : renderPlaceholder("Programs")}
                <Divider className="marVert16" />
                <Typography variant="subtitle1">Challenges</Typography>
                {(props?.user?.challenges && props.user.challenges.length > 0) ? renderChallenges() : renderPlaceholder("Challenges")}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onChangeProgram} color="primary">
                    Save
                </Button>
            </DialogActions>
        </>
    )
}