import {DialogActions, DialogContent, InputAdornment, Link,} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import React from "react";
import styled from "styled-components";
import {isEmail} from "validator";
import SignupBase from "../../components/base/signUpBase";
import PasswordInput from "../../components/passwordField/passwordComponent";
import TaButton from "../../components/taButton";
import {ERROR_CODES, ROLES, SIGNUP_SCREEN, SIGNUP_STATES} from "../../constants";
import {sendRequest} from "../../modules/http/HttpHelper";
import {isAndroid, isIOS} from "react-device-detect";

const InputContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const INITIAL_STATE = {
    termsCheck: false,
    loading: false,
    currentScreen: SIGNUP_SCREEN.SIGNUP,
    clientDetails: null,
    trainer: "",
    invite: "",
    disableEmail: false,
};

const INVITE_EXISTS_ERROR_DURATION = {
    duration: 8000
}

const INVITE_EXISTS_ERROR = {
    code: ERROR_CODES.INVITE_EXISTS,
    message: "Looks like you have already been invited. Please sign up using the link sent to your email."
}

export default class Signup extends SignupBase {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.fields = ["email", "password", "confirmPassword", "name", "message"];

        this.state = Object.assign(this.state, {
            authButtonEnabled: true,
        });

        for (let field of this.fields) {
            this.state[field] = "";

            this["onChange_" + field] = this.onChange.bind(this, field);
        }

        this.state.role = ROLES.TRAINER;
    }

    componentDidMount() {
        this.checkEmail();
        try {
            window.FortisForma.database.signOut(false);
        } catch (e) {
            console.error(e);
        }
    }

    checkEmail = () => {
        let querySearchParams = this.props.location.search;
        let params = new URLSearchParams(querySearchParams);
        let email = params.get("email");
        if (email) {
            this.setState({
                email,
                disableEmail: true,
            });
        }
    };

    onChange(key, event, value) {
        let update = {};
        update[key] = event.target.value || (value ? value.newValue : "");
        this.setState(update);
    }

    signUp = async () => {

        if (this.checkErrorsInForm()) {
            return;
        }

        this.setState({
            loading: true,
        });

        let inviteCode = this.state.invite;

        if (!inviteCode) {
            let results = await this.checkForPendingInvite(this.state.email);
            const hasInvite = Boolean(results && results.inviteCode);

            if (!this.state.disableEmail && hasInvite) {
                //disableEmail is true only when email is pre filled from invite link
                return this.onSignupError(INVITE_EXISTS_ERROR);
            }

            if (!hasInvite) {
                return this.handleSignUpWithoutInvite();
            }
            //invite link sign up
            inviteCode = results.inviteCode;
            this.createAcconut(inviteCode);
        }
    };

    handleSignUpWithoutInvite = () => {
        if (this.props.withoutTrainer) {
            // Challenges && public workout sign up
            return this.createClientAccountWithoutTrainer();
        }

        if (!this.props.withoutTrainer && this.props.trainerId) {
            //trainer seach sign up
            return this.createAcconut();
        }
        //no invite found
        return this.onSignupError("Invalid Invite");
    }

    createAcconut = async (inviteCode) => {
        window.FortisForma.database.signOut();
        window.SIGNUP_STATE = SIGNUP_STATES.IN_PROCESS;

        let userData = {
            email: window.emailTrimAndLowerCase(this.state.email),
            password: this.state.password,
            name: this.state.name,
            comments: this.state.message,
            ...this.props.signupParams
        };

        try {
            await window.FortisForma.database.createClientAccount(
                userData,
                inviteCode
            );
            window.setUserEmailInLocalStorage(userData.email);
            this.onSignupComplete(inviteCode);

        } catch (error) {
            this.onSignupError(error);
        }
    }

    createClientAccountWithoutTrainer = async () => {
        let userData = {
            email: window.emailTrimAndLowerCase(this.state.email),
            password: this.state.password,
            name: this.state.name,
            ...this.props.signupParams
        };

        try {
            await window.FortisForma.database.createClientAccountWithoutTrainer(userData);
            window.setUserEmailInLocalStorage(userData.email);
            this.onSignupComplete();
        } catch (error) {
            this.onSignupError(error);
        }
    }

    onSignupComplete(inviteCode) {
        const isAppstagingUrl = window.location.pathname.includes("appstaging");
        const redirect_url = isAppstagingUrl ? "https://appstaging.fortisforma.com/#/login" : "https://app.fortisforma.com/#/login"
        // window.location.assign("https://appstaging.fortisforma.com/#/login")
        window.NotificationUtils.showSuccess("Sign Up Successful");
        this.setState(INITIAL_STATE);
        !inviteCode && this.sendVerificationEmail();
        window.SIGNUP_STATE = SIGNUP_STATES.COMPLETE;

        if (this.props?.publicWorkoutId || inviteCode) {
            this.props.history.replace(`/login?route=public-workout?id=${this.props.publicWorkoutId}`);
        } else {
            if (isAndroid) {
                window.open('https://play.google.com/store/apps/details?id=com.fitnessforfreedom.fortisforma', "_self")
            } else if (isIOS) {
                window.open('https://apps.apple.com/in/app/fortis-forma/id1474110332', "_self")
            } else {
                window.open("https://appstaging.fortisforma.com/#/login", "_self")
            }
            if (this.props.onSignupComplete) {
                if (isAndroid) {
                    window.open('https://play.google.com/store/apps/details?id=com.fitnessforfreedom.fortisforma', "_self")
                } else if (isIOS) {
                    window.open('https://apps.apple.com/in/app/fortis-forma/id1474110332', "_self")
                } else {
                    window.open("https://appstaging.fortisforma.com/#/login", "_self")
                }

                this.props.onSignupComplete();
                this.props.onClose();

                return;
            }
            if (isAndroid) {
                window.open('https://play.google.com/store/apps/details?id=com.fitnessforfreedom.fortisforma', "_self")
            } else if (isIOS) {
                window.open('https://apps.apple.com/in/app/fortis-forma/id1474110332', "_self")
            } else {
                window.open("https://appstaging.fortisforma.com/#/login", "_self")
            }
        }


        this.props.onUserChange && this.props.onUserChange();
        this.props.onClose && this.props.onClose();
    }

    onSignupError(error) {
        console.error(error);
        this.setState({loading: false});
        this.showError(error);
        window.SIGNUP_STATE = SIGNUP_STATES.PENDING;
        window.FortisForma.database.signOut();
    }

    sendVerificationEmail = async () => {
        try {
            await window.FortisForma.database.sendEmailVerification();
            window.NotificationUtils.showSuccess(
                "Please check your email for verification link"
            );
        } catch (error) {
            console.warn(error);
            window.NotificationUtils.showError("Unable to send verification email");
        }
    };

    async checkForPendingInvite(emailId) {
        let query = {
            email: window.emailTrimAndLowerCase(emailId),
        };
        try {
            let invites = await new Promise((resolve, reject) =>
                sendRequest("userInvites", "get", query, resolve, {
                    errorCallback: reject,
                })
            );

            let numberOfInvites = (invites && invites.length) || 0;
            if (numberOfInvites === 1) {
                return Promise.resolve({
                    inviteCode: invites[0].inviteId,
                    trainer: {
                        data: invites[0].trainer,
                    },
                });
            } else {
                return Promise.resolve({});
            }
        } catch (e) {
            console.error(e);
        }
    }

    checkErrorsInForm() {
        for (let field of this.fields) {
            if (!this.state[field]) {
                let name = field;
                if (name === "confirmPassword") {
                    name = "password";
                }
            }
        }
        if (!this.state.email || !isEmail(this.state.email)) {
            window.NotificationUtils.showError("Please enter a valid email");
            return true;
        }
        if (this.state.password.length < 6) {
            window.NotificationUtils.showError(
                "Password length should be greater than 5"
            );
            return true;
        }
        if (this.state.password !== this.state.confirmPassword) {
            window.NotificationUtils.showError(`Passwords do not match`);
            return true;
        }

        if (!this.state.termsCheck && !this.state.disableEmail) {
            window.NotificationUtils.showError("Please check terms and conditions");
            return true;
        }

        return false;
    }

    showError(error) {
        console.error(error);

        switch (error.code) {
            case ERROR_CODES.DENIED:
                return window.NotificationUtils.showError(
                    "This account is already taken, please use another one."
                );

            case ERROR_CODES.WEAK_PASSWORD:
                window.NotificationUtils.showError("Please select a strong password.");
                return;

            case ERROR_CODES.INVITE_EXISTS:
                return window.NotificationUtils.showError(error.message, INVITE_EXISTS_ERROR_DURATION)

            default:
                window.NotificationUtils.showError("Unknown error code during signup.");
                console.error(error);
        }
    }

    progressIndicator() {
        return (
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    margin: "auto",
                    textAlign: "center",
                    height: 50,
                }}
            >
                {/* <Spinner color="primary" /> */}
            </div>
        );
    }

    handleChangeCheckbox = () => {
        this.setState({
            termsCheck: !this.state.termsCheck,
        });
    };

    gotoLogin = () => {
        this.props.history.replace("/login");
    };


    renderSignupForm() {
        return (
            <div id={`${"signUpModalWidth"}`}>
                <InputContainer>
                    <TextField
                        size="small"
                        fullWidth
                        label="Name"
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonOutlineIcon color="disabled" fontSize="small"/>
                                </InputAdornment>
                            ),
                        }}
                        value={this.state.name}
                        onChange={this.onChange_name}
                    />
                </InputContainer>

                <InputContainer>
                    <TextField
                        size="small"
                        fullWidth
                        label="Email"
                        variant="outlined"
                        required
                        disabled={this.state.disableEmail}
                        value={this.state.email}
                        onChange={this.onChange_email}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AlternateEmailIcon color="disabled" fontSize="small"/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <PasswordInput
                        label={"Password"}
                        showIndicator={true}
                        value={this.state.password}
                        onChange={this.onChange_password}
                    />
                </InputContainer>

                <InputContainer>
                    <PasswordInput
                        label={"Confirm Password"}
                        showIndicator={true}
                        value={this.state.confirmPassword}
                        onChange={this.onChange_confirmPassword}
                    />
                </InputContainer>

                {!this.props.withoutTrainer ?
                    <InputContainer>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            maxrows={4}
                            size="small"
                            variant="outlined"
                            label="Message for trainer"
                            value={this.state.message}
                            onChange={this.onChange_message}
                        />
                    </InputContainer> : null
                }

                <div className="termsCheckLink" style={{marginTop: 16}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="termsCheck"
                                checked={this.state.termsChecked}
                                onChange={this.handleChangeCheckbox}
                                value="TermsChecked"
                                color="primary"
                                size="small"
                            />
                        }
                        label={
                            <div>
                                I agree to the{" "}
                                <Link href="/#/terms" target="_blank">
                                    terms and conditions
                                </Link>{" "}
                                of Fortis Forma
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }

    renderSignupFields = () => {
        return (
            <React.Fragment>
                <DialogContent>{this.renderSignupForm()}</DialogContent>

                <DialogActions>
                    <Button variant="text" color="default" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <TaButton
                        disabled={!Boolean(this.state.termsCheck)}
                        isLoading={this.state.loading}
                        loadingMessage={"Creating Account"}
                        variant="contained"
                        color="primary"
                        onClick={() => this.signUp(true)}
                    >
                        Sign Up
                    </TaButton>
                </DialogActions>
            </React.Fragment>
        );
    };

    render() {
        return <React.Fragment>{this.renderSignupFields()}</React.Fragment>;
    }
}
