import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { strengthColor, strengthIndicator } from "./strength";

const TEXT = "text";
const PASSWORD = "password";

export default class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: PASSWORD,
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  renderInput() {
    return (
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        label={this.props.label}
        value={this.props.value}
        onChange={this.props.onChange}
        required={true}
        type={this.state.showPassword ? TEXT : PASSWORD}
        InputProps={{
          startAdornment: this.props.showIcon !== false && (
            <InputAdornment position="start">
              <LockOutlinedIcon color="disabled" fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                tabIndex="-1"
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                size="small"
              >
                {this.state.showPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }

  _renderStrength() {
    if (!this.props.value || !this.props.value.trim()) {
      return  null;
    }
    const strength = strengthIndicator(this.props.value);
    const str = strengthColor(strength);


    return (
      <span
        className="password-strength-label"
        style={{ color: `${str.color}` }}
      >
        {str.label}
      </span>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderInput()}
        {this.props.showIndicator && this._renderStrength()}
      </React.Fragment>
    );
  }
}
