import React, { Component } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, Button } from "@material-ui/core";
import { SERVICES } from "../../constants";
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from "@material-ui/core/TextField";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default class TrainerFilters extends Component {

    onChangePostalCode = (postalCode) => {
        this.props.onChangePostalCode(postalCode, true);
    }

    renderInitialFilters() {
        return (
            <div className="flexRow" id="trainerSearchFiltersContainer">
                <div className="flexCenter flexColumn">
                    <Typography
                        variant="h6"
                        className="pd-top-16 textAlignCenter mg-bt-16"
                        style={{ color: "grey" }}
                    >
                        Please enter first 3 letters of your postal code to find trainers.
                    </Typography>
                    <TextField 
                    value={this.props.postalCode}
                    onChange={(e)=>this.onChangePostalCode(e.target.value.toUpperCase())}
                    placeholder="XXX"
                    variant="outlined"
                    inputProps={{ maxLength: 3, spellCheck: 'false' }}
                    id="postalCodeTextField"
                    />
                </div>
            </div>
        )
    }

    onClickBackButton=()=>{
        this.props.history.goBack();
    }

    renderFinalFilters() {
        return (
            <div className="flexRow" id="trainerSearchFinalFiltersContainer">
                <IconButton id="backIcon" aria-label="back" onClick={this.onClickBackButton} >
                    <ArrowBackIcon fontSize="large" />
                </IconButton>
                <div className="flexCenter flexColumn">
                    <TextField 
                    value={this.props.postalCode}
                    onChange={(e)=>this.props.onChangePostalCode(e.target.value.toUpperCase())}
                    placeholder="XXX"
                    variant="outlined"
                    inputProps={{ maxLength: 3, spellCheck: 'false' }}
                    id="postalCodeTextField"
                    />
                </div>
                <FormControl variant="outlined" id="servicesMultiselect">
                    <InputLabel id="servicesSelectlabel">
                        Required Services
                    </InputLabel>
                    <Select
                        labelId="servicesSelectlabel"
                        id="servicesSelect"
                        multiple
                        value={this.props.selectedServices}
                        onChange={this.props.handleServiceSelect}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {SERVICES.map((name) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={this.props.selectedServices.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button className="filtersSubmit onBoardButton" variant="contained" disabled={this.props.searchDisabled} onClick={this.props.searchTrainers} color="primary" size="large">
                    Search
                </Button>
            </div>
        )
    }

    render() {
        return (
            this.props.initial ? this.renderInitialFilters() : this.renderFinalFilters()
        );
    }
}