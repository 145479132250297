import IconButton from '@material-ui/core/IconButton';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { HOST_URL } from '../constants';
import React from 'react';

export default function AppDownload(props) {
    return (
        <div id="appDownloadIcons">
            <IconButton onClick={() => window.open('https://apps.apple.com/in/app/fortis-forma/id1474110332', "_blank")}>
                <AppleIcon fontSize="large" color="primary" />
            </IconButton>
            <IconButton onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fitnessforfreedom.fortisforma', "_blank")}>
                <AndroidIcon fontSize="large" color="primary" />
            </IconButton>
            {!Boolean(props.excludeDesktop) ? 
            (<IconButton onClick={() => window.open(HOST_URL, "_blank")}>
                <DesktopWindowsIcon fontSize="large" color="primary" />
            </IconButton>) : null }
        </div>
    )
}