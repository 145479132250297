import React, {Component} from 'react';
import SectionedExerciseCard from './sectionedListExerciseCard';
import memoize from 'memoize-one';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';
import {Button} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import {WORKOUT_SECTIONS_NAMES} from '../../constants';

export default class WorkoutSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: [true],
        };
    }

    expandSection = (index, e) => {
        e.stopPropagation();
        let expanded = Object.assign([], this.state.expanded);
        expanded[index] = true;
        this.setState({
            expanded,
        });
    };

    collapseSection = (index, e) => {
        e.stopPropagation();
        let expanded = Object.assign([], this.state.expanded);
        expanded[index] = false;
        this.setState({
            expanded,
        });
    };

    onSectionDone = (isDone) => {
        if (isDone) {
            this.unmarkExercises();
        } else {
            this.markExercises();
        }
    };

    unmarkExercises = () => {
        this.props.removeSectionLog(this.props.section);
    };

    markExercises = () => {
        this.props.addSectionLog(this.props.section);
    };

    isSectionDone = memoize((section) => {
        let completeCount = 0;
        let logs = this.props.workoutLog || {};
        if (!section) {
            return false;
        }
        for (let exercise of section.data) {
            if ((this.props.currentDayUnix <= this.props.todayUnix) && Boolean(logs[exercise.index])) {
                completeCount++;
            }
        }
        if (completeCount === section.data.length) {
            return true;
        }
        return false;
    });

    render() {
        let section = this.props.section;
        let isDone = this.isSectionDone(section);

        return (<div className="viewWorkoutSection" onClick={(e) => e.stopPropagation && e.stopPropagation()}>
            <div className="viewWorkoutSectionHeader">
                <div style={{display: 'flex'}}>
                    <Typography variant="h6" className="inputLabelBold">
                        {`${section.workoutType}${section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT ? ` ${this.props.firstSection.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT ? this.props.listIndex + 1 : this.props.listIndex}` : ''}`}
                    </Typography>
                    {section.repeatCircuit && (<div className="circuitDisplay">
                        Circuit Count <span>{section.repeatCircuit.count}</span>


                    </div>)}
                   {section.repeatCircuit?section.repeatCircuit.description&& <Tooltip arrow={true} title={section.repeatCircuit.description}>
                            <p className="descBtn">Description</p>
                        </Tooltip>:null}
                </div>
                {this.props.collapsible !== false && <>{this.state.expanded[this.props.listIndex] !== false ?
                    <IconButton size="small" style={{marginLeft: 4}}
                                onClick={(e) => this.collapseSection(this.props.listIndex, e)}>
                        <ExpandLessIcon color="primary"/>
                    </IconButton> : <IconButton size="small" style={{marginLeft: 4}}
                                                onClick={(e) => this.expandSection(this.props.listIndex, e)}>
                        <ExpandMoreIcon color="primary"/>
                    </IconButton>}</>}
            </div>
            <Collapse in={this.state.expanded[this.props.listIndex] !== false}>
                <div style={{paddingLeft: 12, paddingRight: 12}}>
                    {section.data && section.data.length > 0 && section.data.map((item, index) => {
                        let arrayIndex = index;
                        let entry = item;
                        let exerciseIndex = entry.index;
                        let exercise = entry.exercise;
                        let logId = entry.logId;
                        let exerciseList = entry.exerciseList;
                        if (exercise.loading === true) {
                            return null;
                        }
                        let hasMoreLevels = true;
                        let levelsLoaded = this.props.levelsLoadedMap && this.props.levelsLoadedMap[exerciseIndex];
                        if (levelsLoaded === true) {
                            hasMoreLevels = Object.values(exerciseList || {}).length > 1;
                        }
                        if (hasMoreLevels) {
                        }

                        let logs = this.props.workoutLog || {};
                        return (<SectionedExerciseCard
                            selected={Boolean(this.props.selectedSectionIndex === this.props.listIndex && this.props.selectedExerciseIndex === index)}
                            onClickExerciseMiniCard={() => this.props.onClickExerciseMiniCard(exercise, this.props.listIndex, index, logId, exerciseIndex)}
                            key={index}
                            onDonePress={this.props.onDonePress}
                            index={arrayIndex + 1}
                            logId={logId}
                            isComplete={((this.props.currentDayUnix <= this.props.todayUnix)) && Boolean(logs[exerciseIndex])}
                            logUpdateEnabled={this.props.currentDayUnix <= this.props.todayUnix}
                            data={exercise}
                            getEnabledFields={this.props.getEnabledFields}
                            onRemoveLog={this.props.onRemoveLog}
                            levels={exerciseList}
                            isHistory={this.props.currentDayUnix < this.props.todayUnix}
                            disabled={this.props.disabled}
                        />);
                    })}
                </div>
                <div id="sectionDoneContainer">
                    <Button size="small" variant={isDone ? 'contained' : 'outlined'} color="primary"
                            onClick={() => this.onSectionDone(isDone)} disabled={this.props.disabled}>
                        {isDone ? 'Mark Undone' : 'Mark Done'}
                    </Button>
                </div>
            </Collapse>
        </div>)
    }
}
