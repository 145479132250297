import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import ChangePasswordModal from "../../../views/changePassword/changePasswordModal";
import ProfileModal from "../../../views/profile/profileModal";
import Signup from "../../../views/signup/index";
import ChatModal from "../../../views/chatRoom/chatMadal";
import AvatarMenu from "../../avatarMenu";
import TAButton from "../../taButton";
import {IconButton, Typography} from "@material-ui/core";
import {COLLECTIONS} from "../../../constants";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileModal: false,
            chatStatus: true,
            showMessageModal: false,
            showChangePasswordModal: false,
            showSignupModal: false,
            useWorkoutLoader: false,
            showChooseWorkoutModal: false,
            counter: 0,
            messages: {},
        };
    }

    openChooseWorkoutModal = () => {
        this.setState({
            showChooseWorkoutModal: true,
        });
    };

    closeChooseWorkoutModal = () => {
        this.setState({
            showChooseWorkoutModal: false,
        });
    };
    checkTrainerData = () => {
        window.FortisForma.database.getTrainerData(this.props?.user).then((response) => {
            if (response?.tier === "Coaching Solution") {
                this.setState({chatStatus: false})
            }
        })
    }

    componentDidMount() {

        if (this.props?.user) {
            this.checkTrainerData();
            try {
                this.subscribe = window.firebase
                    .firestore()
                    .collection(COLLECTIONS.MESSAGE)
                    .where("clientId", "==", this.props.user.id)
                    .where("client_read_status", "==", false)
                    .onSnapshot((doc) => {
                        let messages = {};
                        if (doc.docs.length) {
                            let result = [...this.props.trainer];
                            result.map((item) => {
                                let count = 0;
                                doc.docs.map((subItem, index) => {
                                    let data = subItem.data();
                                    if (data.trainerId === item.id) {
                                        data.count = count + 1;
                                        messages[data.trainerId] = [data]
                                        count++;
                                    }
                                })
                            })
                            if (messages) {
                                this.setState({messages});
                            } else {
                                this.setState({messages: {}});
                            }
                        }
                        this.setState({
                            counter: doc.docs.length,
                        });
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }

    componentWillUnmount() {
        if (this.props?.user) {
            this.subscribe && this.subscribe();
        }

    }

    removeLastMessage = (item) => {
        let last = this.state.messages;
        delete last[item.id];
        this.setState({messages: last});
    }

    renderProfileEditorModal() {
        return (<ProfileModal
            fullScreen={this.state.isMobile}
            open={this.state.showProfileModal}
            onClose={this.closeProfileModal}
            user={this.props.user}
            handleLogout={this.logout}
            {...this.props}
        />);
    }

    renderMessageCenter() {


        return (<ChatModal
            fullScreen={this.state.isMobile}
            open={this.state.showMessageModal}
            onClose={this.closeMessageModal}
            user={this.props.user}
            handleLogout={this.logout}
            trainer={this.props.user.chatTrainer || []}
            unreadMessages={this.state.messages}
            removeLastMessage={this.removeLastMessage}
            {...this.props}
        />);
    }

    onClickUseWorkout = async () => {
        this.setState({
            useWorkoutLoader: true,
        });
        await this.props.onClickUseWorkout();
        this.setState({
            useWorkoutLoader: false,
        });
    };

    renderChangePasswordModal() {
        return (<ChangePasswordModal
            fullScreen={this.state.isMobile}
            open={this.state.showChangePasswordModal}
            onClose={this.closeChangePasswordModal}
            user={this.props.user}
        />);
    }

    logout = async () => {
        window.clearLocalStorage();
        this.loadedUser = false;

        try {
            await window.FortisForma.database.signOut();
            this.props.history.replace("/login");
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Unable to sign out");
        }
    };

    closeSignupModal = () => {
        this.setState({
            showSignupModal: false,
        });
    };

    renderSignUpModal = () => {
        return (<Dialog
            maxWidth="sm"
            open={this.state.showSignupModal}
            onClose={this.closeSignupModal}
        >
            <DialogTitle>Create Account</DialogTitle>
            <Signup
                {...this.props}
                trainerId={""}
                withoutTrainer={true}
                signupParams={this.state.signupParams}
                onClose={this.closeSignupModal}
                onSignupComplete={this.props.onSignupComplete}
            />
        </Dialog>);
    };

    onClickSignUp = () => {
        this.setState({
            showSignupModal: true,
        });
    };

    onClickLogin = () => {
        this.props.history.replace(`/login?route=public-workout?id=${this.props.publicWorkoutId}`);
    };

    renderProfileAvatar() {
        let name = this.props.user.name || "";
        let secondName = name.split(" ")[1];
        if (secondName) {
            secondName = secondName[0];
        } else {
            secondName = "";
        }

        name = name[0];

        return (<AvatarMenu
            label={name + secondName}
            canChat={this.state.chatStatus}
            counter={this.state.counter}
            handleGoalSet={this.props.handleGoalSet}
            handleProfile={this.handleProfileClick}
            handleMessageCenter={this.handleMessageCenter}
            handleChangePassword={this.handleChangePasswordClick}
            handleLogout={this.logout}
            handleMyPrograms={this.props.handleMyPrograms}
            handleResetProgram={this.props.handleResetProgram}
            user={this.props.user}
            trainer={this.props.trainer}
        />);
    }

    handleProfileClick = () => {
        this.setState({
            showProfileModal: true,
        });
    };
    handleMessageCenter = () => {
        this.setState({
            showMessageModal: true,
        });
    };
    closeProfileModal = () => {
        this.setState({
            showProfileModal: false,
        });
    };
    closeMessageModal = () => {
        this.setState({
            showMessageModal: false,
        });
    };
    handleChangePasswordClick = () => {
        this.setState({
            showChangePasswordModal: true,
        });
    };

    closeChangePasswordModal = () => {
        this.setState({
            showChangePasswordModal: false,
        });
    };

    handleGoalSet = () => {
        this.setState({
            showConsistencyModal: true, showAlarmSet: false,
        });
    };

    renderAppBarContent = () => {
        if (this.props.isLoggedIn) {
            return (<div className="dispFlex">
                <TAButton
                    className="mg-right-16"
                    color="primary"
                    variant="contained"
                    onClick={this.onClickUseWorkout}
                    isLoading={this.state.useWorkoutLoader}
                    loadingMessage={"Adding Workout"}
                >
                    Use this workout
                </TAButton>
                <div id="headerActions">{this.renderProfileAvatar()}</div>
            </div>);
        } else {
            return (<React.Fragment>
                <div id="signUpButton" className="dispFlex">
                    <Button
                        className="mg-right-16"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickSignUp}
                    >
                        Register to start using this workout
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.onClickLogin}
                    >
                        Login
                    </Button>
                </div>
            </React.Fragment>);
        }
    };

    renderContent = () => {
        if (this.props.type === "public") {
            return <>{this.renderAppBarContent()}</>;
        } else {
            return (<>
                {this.props.renderHeaderContent()}
                <div id="headerActions">
                    {(this.props.trainer && this.props.canChat) && (<div className="messagecenter" color="primary">
                        <IconButton aria-label="message" color="primary"
                                    className="msgRelBtn">
                            <i
                                className="icon material-icons pointer"
                                onClick={() => this.handleMessageCenter()}
                                alt="message"
                            >
                                message
                            </i>
                            {this.state.counter !== 0 && <span className="unreadMsg">{this.state.counter}</span>}

                        </IconButton>
                    </div>)}

                    {this.renderProfileAvatar()}
                </div>
            </>);
        }
    };

    onClickLogo = () => {
        if (this.props.isLoggedIn) {
            this.props.history.replace("/home");
        } else {
            this.props.history.replace("/");
        }
    };

    renderWorkoutName = () => {
        return (<Typography className="inputLabelBold" variant="h6" color="primary">
            {this.props.publicWorkoutName || ""}
        </Typography>);
    };

    renderAppBar = () => {
        return (<div id="appBar">
            <div
                id="appBarLogoContainer"
                className="cursorPointer"
                onClick={this.onClickLogo}
            >
                <img alt="Empty" src={"/assets/arrow-logo.png"} id="arrowLogo"/>
                <img alt="Empty" src={"/assets/text-logo.png"} id="textLogo"/>
            </div>
            {this.props.publicWorkoutName && this.renderWorkoutName()}
            {this.renderContent()}
        </div>);
    };

    render() {

        return (<React.Fragment>
            {this.renderAppBar()}
            {this.renderProfileEditorModal()}
            {this.props?.user?.chatTrainer && this.renderMessageCenter()}
            {this.renderChangePasswordModal()}
            {this.renderSignUpModal()}
        </React.Fragment>);
    }
}
