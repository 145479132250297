import React from 'react';
import Reps from '../../assets/repsD.png';
import Sets from '../../assets/repsD.png';
import Weight from '../../assets/weightD.png';
import Clock from '../../assets/timeD.png';
import Resistance from '../../assets/resistanceD.png';
import Typography from '@material-ui/core/Typography';

import {EQUIPMENT_TYPES} from '../../constants';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const RESISTANCE_OPTIONS = {
    EXTRA_LIGHT: 'Extra Light',
    LIGHT: 'Light',
    MEDIUM: 'Medium',
    HEAVY: 'Heavy',
    EXTRA_HEAVY: 'Extra Heavy',
};

export default class SectionedListExerciseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: true,
            showReplay: false,
        };
        this.playerRef = null;
        this.posterURL = props.data.posterURL;
    }
    
    onPressReplay = () => {
        this.setState({
            playing: true,
            showReplay: false,
        });
    };
    
    onFinishVideo = () => {
        this.playerRef && this.playerRef.seekTo(0);
        this.setState({
            playing: false,
            showReplay: true,
        });
    };
    
    componentDidUpdate(prevProps) {
        // HACK placeholder not refreshing
        if (this.posterURL !== this.props.data.posterURL) {
            this.posterURL = this.props.data.posterURL;
            let showPreview =
                this.playerRef &&
                this.playerRef.state &&
                this.playerRef.state.showPreview;
            if (showPreview && this.playerRef) {
                this.playerRef.setState(
                    {
                        showPreview: !showPreview,
                    },
                    () => {
                        if (this.playerRef) {
                            this.playerRef.setState({
                                showPreview: showPreview,
                            });
                        }
                    },
                );
            }
        }
    }
    
    isEmptyEquipmentType() {
        let types = Object.values(this.props.data.equipmentTypes);
        let isEmptyType = true;
        for (let type of types) {
            if (type) {
                isEmptyType = false;
            }
        }
        return isEmptyType;
    }
    
    isSetsDisabled = () => {
        if (this.setsDisabled !== undefined) {
            return this.setsDisabled;
        }
        this.setsDisabled = !this.props.data.sets;
        return this.setsDisabled;
    };
    isRepsDisabled = () => {
        if (this.repsDisabled !== undefined) {
            return this.repsDisabled;
        }
        this.repsDisabled = !this.props.data.reps;
        return this.repsDisabled;
    };
    isSetsDisabled = () => {
        if (this.setsDisabled !== undefined) {
            return this.setsDisabled;
        }
        this.setsDisabled = !this.props.data.sets;
        return this.setsDisabled;
    };
    isTimeDisabled = () => {
        if (this.timeDisabled !== undefined) {
            return this.timeDisabled;
        }
        this.timeDisabled = !this.props.data.time;
        return this.timeDisabled;
    };
    
    isResistanceDisabled = () => {
        let equipmentTypes = Object.values(this.props.data.equipmentTypes);
        let resistanceDisabled = !equipmentTypes.includes(
            EQUIPMENT_TYPES.RESISTANCE,
        );
        if (resistanceDisabled && this.isEmptyEquipmentType()) {
            resistanceDisabled = !this.props.data.resistance;
        }
        return resistanceDisabled;
    };
    
    isWeightDisabled = () => {
        if (this.weightDisabled !== undefined) {
            return this.weightDisabled;
        }
        let equipmentTypes = Object.values(this.props.data.equipmentTypes);
        this.weightDisabled = !equipmentTypes.includes(EQUIPMENT_TYPES.FREEWEIGHT);
        if (this.weightDisabled && this.isEmptyEquipmentType()) {
            this.weightDisabled = !this.props.data.weight;
        }
        return this.weightDisabled;
    };
    
    onDonePress = (e, removeLog = false) => {
        e.stopPropagation();
        let params = this.prepareParameters();
        if (removeLog === true) {
            this.props.onRemoveLog(params.exercise, params.data);
            return;
        }
        this.props.onDonePress(params.exercise, params.data);
    };
    
    prepareParameters() {
        let exercise = {};
        exercise.logId = this.props.logId;
        exercise.name = this.props.data.name;
        exercise.index = this.props.index;
        exercise.log = {};
        exercise.log.sets = this.props.data.sets;
        exercise.log.reps = this.props.data.reps;
        exercise.log.time = this.props.data.time;
        exercise.log.weight = this.props.data.weight;
        exercise.log.resistance = this.props.data.resistance;
        return {exercise, data: this.props.data};
    }
    
    renderExtra(key, value, icon, options) {
        return (
            <div className="configMainContainer">
                <div className="configImageKey">
                    {icon && (
                        <img
                            style={{
                                width: 13,
                                height: 13,
                                marginRight: 5,
                            }}
                            src={icon}
                            alt={key}
                        ></img>
                    )}
                    <span className="configKey">{key}:</span>
                </div>
                <div className="configValue">
                    {value} {options && options.unit}
                </div>
            </div>
        );
    }
    
    renderConfig() {
        return (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {(this.props.data.resistance&&this.props.data.equipmentTypes) && !this.isResistanceDisabled() && (
                    <div>
                        {this.renderExtra(
                            'Resistance',
                            RESISTANCE_OPTIONS[this.props.data.resistance],
                            Resistance,
                        )}
                    </div>
                )}
                {Boolean(Number(this.props.data.sets)) && !this.isSetsDisabled() && (
                    <div>{this.renderExtra('Sets', this.props.data.sets, Sets)}</div>
                )}{Boolean(Number(this.props.data.reps)) && !this.isRepsDisabled() && (
                <div>{this.renderExtra('Reps', this.props.data.reps, Reps)}</div>
            )}
                {Boolean(Number(this.props.data.weight)) &&
                    !this.isWeightDisabled() && (
                        <div>
                            {this.renderExtra('Weight', this.props.data.weight, Weight)}
                        </div>
                    )}
                {Boolean(Number(this.props.data.time)) && !this.isTimeDisabled() && (
                    <div>{this.renderExtra('Time', this.props.data.time, Clock)}</div>
                )}
            </div>
        );
    }
    
    renderExerciseLevel() {
        return (
            <Typography style={{fontWeight: '600', fontSize: 10}} color="primary">
                Level {this.props.data.level}
            </Typography>
        );
    }
    
    render() {
        return (
            <div
                id={`exerciseCard_${this.props.data.section}_${this.props.data.exerciseIndexInSection}`}
                className={
                    !this.props.selected
                        ? 'sectionedExerciseCard'
                        : 'sectionedExerciseCard sectionedExerciseCardSelected'
                }
                onClick={this.props.onClickExerciseMiniCard}
            >
                <div className="sectionedVideoPlayer">
                    {this.props.data.posterURL ? (
                        <img
                            alt="Poster"
                            src={this.props.data.posterURL}
                            className="miniCardPoster"
                        />
                    ) : (
                        <div className="miniCardPoster"/>
                    )}
                </div>
                <div className="sectionedCardContent">
                    <div className="sectionedExerciseNameLevel">
                        <Typography variant="body2">{this.props.data.name}</Typography>
                    </div>
                    <div className="sectionedExerciseConfig">{this.renderConfig()}</div>
                    <div>{this.renderExerciseLevel()}</div>
                </div>
                <div id="miniCardDoneContainer">
                    {this.props.isComplete ? (
                        <IconButton
                            size={'small'}
                            onClick={(e) => this.onDonePress(e, true)}
                            disabled={this.props.disabled}
                        >
                            <CheckCircleIcon color={this.props.disabled ? 'disabled' : 'primary'}/>
                        </IconButton>
                    ) : (
                        <IconButton size={'small'} onClick={(e) => this.onDonePress(e)} disabled={this.props.disabled}>
                            <CheckCircleOutlineIcon color={this.props.disabled ? 'disabled' : 'primary'}/>
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}
