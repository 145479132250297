import React, { useState, useEffect } from "react";
import ChallengeCard from "./challengeCard";
import styled from 'styled-components'
import Skeleton from "@material-ui/lab/Skeleton";
import {Dialog, DialogTitle, Typography, DialogContent, DialogContentText} from  "@material-ui/core";
import Signup from '../signup/index';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { isMobileOnly, isMobile } from "react-device-detect";

const ChallengesContainer = styled.div`
    margin: 32px auto;
    display: flex;
    flex-wrap: wrap;
    // justify-content: ${isMobileOnly?"center":"space-between"};
    max-width: ${isMobile?"680px":"1180px"};
    flex-direction: row;
    @media(min-width:300px){
        flex-direction:column;
    }
    @media (min-width:768px){
        flex-direction:row;
    }
    @media (max-width:1176px){
        justify-content:center;
    }
`
const StyledSkeleton = styled(Skeleton)`
    border-radius: 6px;
    margin: 0 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
`

const BackIcon = styled(IconButton)`
    position: absolute;
    left: 8px;
`
const MainContainer = styled.div`
    position: relative
`


const SELECT_MODES = {
    "TRY": "try",
    "START": "start"
}
export default function Challenges(props) {
    const [challenges, setChallenges] = useState(null);
    const [loading, setLoading] = useState(false);
    const [signupParams, setSignupParams] = useState(null);
    const [showSignupModal, setSignupModal] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState({});
    const [showPaymentDialog, setPaymentDialog] = useState(false);
    const [selectMode, setSelectMode] = useState(null);

    useEffect(() => {
        setOnboardData();
        async function getChallenges() {
            setLoading(true);
            try {
                let challengesData = await window.FortisForma.database.getChallenges();
                setChallenges(challengesData.data);
                setLoading(false);
            }
            catch(e) {
                console.error(e);
                window.NotificationUtils.showError("Unable to get challenges");
                setLoading(false);
            }
        }
        getChallenges();
        if(isMobileOnly){
            scrollToTop()

        }
    }, [])

    function setOnboardData() {
        let onboardingData = window.sessionStorage.getItem(
            LOCAL_STORAGE_KEYS.ONBOARDING_DATA
        );
        if (!onboardingData) {
            onboardingData = "{}";
        }
        onboardingData = JSON.parse(onboardingData);
        let signupParams = {...onboardingData};
        setSignupParams(signupParams);
    }

    function onClickStart(selectedChallenge) {
        setSignupModal(true);
        setSelectedChallenge(selectedChallenge);
        setSelectMode(SELECT_MODES.START);
    }

    function onClickTry(selectedChallenge) {
        setSignupModal(true);
        setSelectedChallenge(selectedChallenge);
        setSelectMode(SELECT_MODES.TRY);
    }

    function closeSignupModal() {
        setSignupModal(false);
    }

    async function onSignupComplete() {
        if(SELECT_MODES.TRY === selectMode) {
            await assignFirstPhaseToUser();
            setSelectMode(null);
            props.onUserChange && props.onUserChange();
            return
        }
        startPayment();
        setSelectMode(null);
        setTimeout(async()=> {
            await assignChallengeToUser();
            paymentComplete();
            props.onUserChange && props.onUserChange();
        }, 200)
    }

    async function assignFirstPhaseToUser() {
        try{
            await window.FortisForma.database.assignFirstPhaseToUser(selectedChallenge)
        }
        catch(e) {
            console.error(e);
            window.NotificationUtils.showError("Unable to assign challenge, please log in to your account to start your challenge later.")
        }
    }

    async function assignChallengeToUser() {
        try{
            await window.FortisForma.database.assignChallengeToUser(selectedChallenge)
        }
        catch(e) {
            console.error(e);
            window.NotificationUtils.showError("Unable to assign challenge, your payment has been recorded please log in to your account to start your challenge later.")
        }
    }

    function startPayment() {
        setPaymentDialog(true)
    }

    function paymentComplete() {
        setPaymentDialog(false)
    }

    function renderSkeleton() {
        return (
          <StyledSkeleton
            variant="rect"
            width={320}
            height={462}
          />
        );
    }

    function renderContactForm() {
        return (
            <Dialog
                maxWidth="sm"
                open={showSignupModal}
                onClose={closeSignupModal}
            >
                <DialogTitle>Create Account</DialogTitle>
                <Signup
                    {...props}
                    trainerId={""}
                    withoutTrainer={true}
                    signupParams={signupParams}
                    onSignupComplete={onSignupComplete}
                    onClose={closeSignupModal}
                />
            </Dialog>
        );
    };

    function scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'})
      };

    function renderPaymentDialog() {
        return (
          <Dialog
            maxWidth="sm"
            open={showPaymentDialog}
            onClose={() => setPaymentDialog(false)}
          >
            <DialogTitle>Payment Complete</DialogTitle>
            <DialogContent>
              <DialogContentText>Setting up your challenge</DialogContentText>
            </DialogContent>
          </Dialog>
        );
    }

    function renderLoading() {
        return (
            <>
                {renderSkeleton()}
                {renderSkeleton()}
                {renderSkeleton()}
            </>
        )
    }

    function renderChallenges() {
        return (
            <>
                {challenges?.map((data, index) => {
                    return <ChallengeCard key={index} data={data} onClickTry={onClickTry} onClickStart={onClickStart} />
                })}
                {renderContactForm}
            </>
        )
    }

    function renderEmptyPlaceholder() {
        return (
            <Typography>
                No challenges found
            </Typography>
        )
    }

    function renderContent() {
        if(loading) {
            return renderLoading()
        }
        if(!loading && challenges?.length) {
            return renderChallenges()
        }
        else {
            return renderEmptyPlaceholder()
        }
    }

    function onClickBackButton(){
        props.history.goBack();
    }

    return (
        <MainContainer>
            <BackIcon aria-label="back" onClick={onClickBackButton} >
                <ArrowBackIcon fontSize="large" />
            </BackIcon>
            <Typography variant="h4" style={{margin: "32px 80px 0px 80px", paddingTop: "10px"}}>
                Challenges
            </Typography>
            <ChallengesContainer>
                {renderContent()}
            </ChallengesContainer>
            {renderContactForm()}
            {renderPaymentDialog()}
        </MainContainer>
    )
}