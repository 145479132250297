import React, { Component } from "react";
import "./App.css";
import { Route, Router, Switch } from "react-router-dom";
import database from "./database/database";
import theme from "./theme";
import indexRoutes from "./modules/routes/index";
import { createHashHistory } from "history";
import Snackbar from "./components/common/snackbar/snackbar";
import ReactGA from "react-ga";
import { ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import {
  ACTION_STATES,
  DB_KEYS,
  SIGNUP_STATES,
  LOCAL_STORAGE_KEYS,
  ROLES,
} from "./constants";
import Spinner from "@material-ui/core/CircularProgress";
import _ from "underscore";
import * as Sentry from "@sentry/browser";
import { Notifications } from "react-push-notification";

const hist = createHashHistory();
window.FortisForma.database = database;
window.NotificationUtils = {};
const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(trackingId);
const debouncedLogging = _.debounce(logAnalytics, 500);

function logAnalytics(location) {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
}

hist.listen((location) => {
  debouncedLogging(location);
});

window.workoutLogsKey = (date) => {
  if (!date) {
    date = moment();
  }
  return date.format("L").replace(/\//g, "-");
};

class App extends Component {
  constructor(props) {
    super(props);
    let profile;
    try {
      profile = JSON.parse(
        window.localStorage.getItem(DB_KEYS.USER_PROFILE_KEY)
      );
    } catch (e) {
      console.error(e);
    }
    let verificationPending = window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.VERIFICATION_PENDING,
      false
    );
    verificationPending = Boolean(verificationPending);

    this.state = {
      isLoggedIn: profile != null,
      user: profile,
      loading: true,
    };

    if (verificationPending) {
      this.state.user = {
        emailVerified: false,
      };
    }

    if (!this.state.user) {
      window.FortisForma.database.signOut();
    }
    this.snackbarRef = React.createRef();
    this.onUserChange = this.onUserChange.bind(this);

    window.firebase.auth().onAuthStateChanged((user) => {
      let signupState = window.localStorage.getItem(DB_KEYS.SIGN_UP_STATE);
      if (signupState && signupState !== ACTION_STATES.COMPLETE) {
        return;
      }
      if (user) {
        if (window.SIGNUP_STATE === SIGNUP_STATES.IN_PROCESS) {
          return;
        }
        this.onAuthUserChanged(user);
      } else {
        window.clearLocalStorage();
        let currentRoute = window.location.hash.split("/")[1];
        if (
          !currentRoute.includes("signup") &&
          !currentRoute.includes("login") &&
          !currentRoute.includes("waiting") &&
          !currentRoute.includes("trainer-search") &&
          !currentRoute.includes("terms") &&
          !currentRoute.includes("challenges") &&
          !currentRoute.includes("public-workout") &&
          !currentRoute.includes("invite")
        ) {
          hist.replace("/");
        }
        this.setState({
          isLoggedIn: false,
          user: null,
          loading: false,
        });
      }
    });
  }

  requestPermission = () => {
    // Notification.permission
  };

  componentDidMount() {
    window.NotificationUtils.showSuccess = (message, extras = {}) => {
      this.snackbarRef.current.showSuccess(message, extras);
    };
    window.NotificationUtils.showError = (message, extras = {}) => {
      this.snackbarRef.current.showError(message, extras);
    };
    window.NotificationUtils.showWarning = (message, extras = {}) => {
      this.snackbarRef.current.showWarning(message, extras);
    };
    window.NotificationUtils.showInfo = (message, extras = {}) => {
      this.snackbarRef.current.showInfo(message, extras);
    };
    window.emailTrimAndLowerCase = (email) => {
      let trimmedEmail = email.trim();
      let result = trimmedEmail.toLowerCase();
      return result;
    };
    window.setUserEmailInLocalStorage = (email) => {
      window.localStorage.setItem(DB_KEYS.LAST_USER_EMAIL, email);
    };
    window.clearLocalStorage = () => {
      let email = window.localStorage.getItem(DB_KEYS.LAST_USER_EMAIL);
      window.localStorage.clear();
      if (email) {
        window.setUserEmailInLocalStorage(email);
      }
    };
    window.onOTPSuccess = this.onOTPSuccess;
    window.hasMFA = this.hasMFA;
  }

  onOTPSuccess = () => {
    this.onAuthUserChanged(this.state.user);
  };

  logout = async () => {
    window.clearLocalStorage();
    this.loadedUser = false;
    this.setState({
      loading: false,
      isLoggedIn: false,
      user: null,
      url: "",
    });
    try {
      await window.FortisForma.database.signOut();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to sign out");
    }
  };

  routeToInvalidLogin = () => {
    hist.replace("/invalid-login");
  };

  gotoOTPScreen = () => {
    hist.replace("/login-authentication");
  };

  async onAuthUserChanged(authUser) {
    try {
      let claims = await window.FortisForma.database.getCustomClaims();
      claims.emailVerified = authUser.emailVerified;
      let accessResults = this.canAccessHome(claims);
      if (accessResults.verified === false) {
        if (accessResults.error) {
          window.NotificationUtils.showError(
            accessResults.error,
            null,
            "bottomCenter"
          );
        }

        if (accessResults.reason === DB_KEYS.ROLE) {
          window.FortisForma.database.signOut();
          this.routeToInvalidLogin();
        } else {
          this.setState({ isLoggedIn: false, user: authUser });
        }
        return;
      }
      if (this.hasMFA(claims)) {
        if (!this.isMFAAuthenticated(claims)) {
          this.setState({ user: authUser });
          return this.gotoOTPScreen();
        }
      }
    } catch (e) {
      console.error(e);
      return window.FortisForma.database.signOut();
    }
    this.onUserChange(authUser);
  }

  hasMFA(claims) {
    if (claims.enableMFA) {
      return true;
    }
    return false;
  }

  isMFAAuthenticated(claims) {
    if (claims.auth_time && claims.auth_time === claims.mfaTime) {
      return true;
    }
    return false;
  }

  canAccessHome(claims) {
    let results = { error: null, verified: false, reason: null };

    if (claims.role && claims.role !== ROLES.CLIENT) {
      results.error = "Invalid access";
      results.reason = DB_KEYS.ROLE;
      return results;
    }

    results.verified = true;

    return results;
  }

  checkRoleValidity(user) {
    var result = false;
    if (user.role === ROLES.CLIENT) {
      result = true;
    }
    return result;
  }

  async onUserChange(user) {
    let claims;
    try {
      claims = await window.FortisForma.database.getCustomClaims();
    } catch (e) {
      global.NotificationUtils.showError(e.message);
      this.setState({ loading: false });
      return;
    }
    if (user) {
      user.accountState = claims.state;
    }

    let accessResults = await this.canAccessHome(claims);
    if (accessResults.verified === false) {
      this.setState({
        deniedAccess: true,
      });
      if (accessResults.error) {
        global.NotificationUtils.showError(accessResults.error);
      }

      if (accessResults.reason === DB_KEYS.ROLE) {
        this.logout();
      } else {
        if (accessResults.reason === DB_KEYS.EMAIL_VERIFIED) {
          global.NotificationUtils.showError("Please verify your email first");
        }
        this.setState({ isLoggedIn: false, user: user });
      }
      this.setState({
        loading: false,
      });
      return;
    }

    this.setState({
      deniedAccess: false,
    });

    try {
      user = await window.FortisForma.database.getUserData(true);
      await window.FortisForma.database.updateUserLoginTime();
      var validAccess = this.checkRoleValidity(user);
      if (!validAccess) {
        window.FortisForma.database.signOut();
        this.routeToInvalidLogin();
        return;
      }
      user.accountState = claims.state;
      await window.FortisForma.database.getUserToken(true);
      this.setState({
        isLoggedIn: true,
        user: user,
        loading: false,
      });
      Sentry.configureScope((scope) => {
        scope.setTag("User ID", user.id);
      });
      window.localStorage.setItem(
        DB_KEYS.USER_PROFILE_KEY,
        JSON.stringify(user)
      );
      let currentRoute = window.location.hash.split("/")[1];

     
      if (currentRoute.includes("login")) {
        let route = this.getURLRoute() || "/home";
        hist.replace(route);
      }
      if (
        currentRoute !== "verify-email" &&
        currentRoute !== "client-email-verified" &&
        !currentRoute.includes("public-workout") &&
        currentRoute !== "invite"
      ) {
        let route;
        const workoutId = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.PUBLIC_WORKOUT_ID))
        if(workoutId){
           route = '/public-workout' + workoutId
        }else{
           route = this.getURLRoute() || "/home";
        }
        hist.replace(route);
      }
    } catch (e) {
      this.setState({ loading: false });
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
      // if (e.code === 404) {
      //   this.setState({
      //     isLoggedIn: true,
      //     user: null
      //   }, () => {
      //     hist.replace("/profile");
      //   })
      // }
    }
  }

  getURLRoute() {
    let queryParams = "";
    try {
      let queryIndex = window.location.hash.indexOf("?");
      if (queryIndex >= 0) {
        queryParams = window.location.hash.substring(queryIndex);
      }
    } catch (e) {
      console.error(e);
    }
    let urlParams = new URLSearchParams(queryParams);
    let route = urlParams.get("route");
    return route;
  }

  waitForVerification() {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.VERIFICATION_PENDING, true);
    hist.replace("/resend-email");
  }

  progressIndicator() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  recheckEmailVerification = async () => {
    try {
      let user = await window.FortisForma.database.reloadCurrentAuthUser();
      if (user.emailVerified) {
        this.onUserChange(user, true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Notifications />
        <ThemeProvider theme={theme}>
          {!this.state.loading ? (
            <Router history={hist}>
              <Switch>
                {indexRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      key={key}
                      render={(props) => (
                        <prop.component
                          {...props}
                          user={this.state.user}
                          isLoggedIn={this.state.isLoggedIn}
                          recheckEmailVerification={
                            this.recheckEmailVerification
                          }
                          onUserChange={this.onUserChange}
                          hist={hist}
                        />
                      )}
                    />
                  );
                })}
              </Switch>
            </Router>
          ) : (
            this.progressIndicator()
          )}
          <Snackbar ref={this.snackbarRef} />
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
