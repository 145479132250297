import React from "react";
import {
  Redirect, Route,
  Switch
} from "react-router-dom";
import mainRoutes from "../../modules/routes/main";
import { LOCAL_STORAGE_KEYS, REDIRECT_ROUTE } from "../../constants"

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    }
  }

  handleActiveClick = (color) => {
    this.setState({
      activeColor: color
    });
  }

  handleBgClick = (color) => {
    this.setState({
      backgroundColor: color
    });
  }

  render() {
    let location = this.props.hist.location;
    let path = (location && location.pathname) || '';
    if (path) {
      path = path.replace('/', '');
    }
    if (path === 'resend-email') {
      path = ''
    }
    if(path === 'public-workout' && location.search){
       path = 'public-workout' + location.search
       window.localStorage.setItem(LOCAL_STORAGE_KEYS.PUBLIC_WORKOUT_ID, JSON.stringify(location.search));
    }

    return (
      <div className="wrapper">
        <div className="main-panel">
          <Switch>
            {mainRoutes(this.props.isLoggedIn, this.props.user, this.props.hist.location).map((prop, key) => {
              if (prop.pro) {
                return null;
              }
              if (prop.redirect) {
                return <Redirect exact={true} from={prop.path} to={prop.pathTo + ((this.props.isLoggedIn || !path) ? '' : `?route=${path}`)} key={key} />;
              }
              if (!prop.component) {
                return null;
              }
              return (
                <Route exact={true} path={prop.path} render={(props) => {
                  if(prop.path === "/"){
                    return window.location.replace(REDIRECT_ROUTE)
                  }
                  return (<div>
                    <prop.component {...this.props} {...props} />
                  </div>)
                }} key={key} />
              );
            })}
          </Switch>
        </div>
      </div>
    );
  }
}

export default Main;
