const DB_KEYS = {
  USER_PROFILE_KEY: "userProfile",
  TRAINER_ID_KEY: "trainerId",
  GROUP_ID_KEY: "groupId",
  CREATOR_ID_KEY: "creatorId",
  PROGRAM_ID_KEY: "programId",
  CREATED_TIME: "createdTime",
  CLIENT_ID_KEY: "id",
  LAST_LOGIN_KEY: "lastLoginTime",
  PENDING_LOGIN_KEY: "pendingLogin",
  REQUEST_STORAGE_KEY: "requests",
  SIGN_UP_STATE: "signupState",
  USER_TOKEN: "token",
  ASSIGN_DATE: "assignDate",
  START_DATES: "startDates",
  END_DATE: "endDate",
  CLIENT_ID: "clientId",
  USER_STATE: "state",
  ROLE: "role",
  COUNTERS: "counters",
  EMAIL_VERIFIED: "emailVerified",
  ACTIVE_STATUS: "active",
  WALKTHROUGH: "walkthrough",
  WORKOUT_TYPE: "type",
  VISIBLE: "visible",
  MOVEMENT_CATEGORY: "movementCategory",
  MOVEMENT_ID: "movementId",
  ADMIN: "admin",
  POSTAL_CODE: "postalCode",
  SERVICE_MODES: "services.mode",
  SERVICE_TYPES: "services.type",
  GLOBAL: "global",
  TRAINER_RADIUS: "trainerSearchRadius",
  LAST_USER_EMAIL: "lastUserEmail",
};
const MONTHS=[{
  num:1,str:'January'},
  {num:2,str:"February"},
  {num:3,str:"March"},
  {num:4,str:"April"},
  {num:5,str:"May"},
  {num:6,str:"June"},
  {num:7,str:"July"},
  {num:8,str:"August"},
  {num:9,str:"September"},
  {num:10,str:"October"},
  {num:11,str:"November"},
  {num:12,str:"December"}
]

const USER_STATES = {
  INVALID: 0,
  PENDING: 1,
  APPROVED: 2,
  BLOCKED: 3
}

const ACTION_STATES = {
  PENDING: "PENDING",
  IN_PROCESS: "IN_PROCESS",
  FAILED: "FAILED",
  COMPLETE: "COMPLETE"
}

const ERROR_CODES = {
  INVALID_EMAIL: "auth/invalid-email",
  USER_DISABLED: "auth/user-disabled",
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASS: "auth/wrong-password",
  WEAK_PASSWORD: "auth/weak-password",
  DENIED: "auth/email-already-in-use",
  TOO_MANY_ATTEMPTS: "auth/too-many-requests",
  PERMISSION_DENIED: "permission-denied",
  MFA_VERIFIED: "account-already-verified",
  INVITE_EXISTS: "invite-already-exists"
};

const LOCAL_STORAGE_KEYS = {
  WORKOUT_ASSIGNMENT_DETAILS: "assignmentDetails",
  WORKOUT_DESIGNER_RESULT: "savedWorkout",
  WORKOUT_DESIGNER_STATE: "savedWorkoutState",
  WORKOUT_GUIDE_COMPLETE: "workoutGuideComplete",
  FILTER_TOOLTIP_SHOWN: "filterTooltipShown",
  MUSCLE_GROUPS: "muscleGroups",
  EQUIPMENT_CATEGORIES: "equipmentCategories",
  FUNCTION_CATEGORIES: "functionCategories",
  WORKOUT_SUMMARY_DETAILS: "workoutSummaryDetails",
  VERIFICATION_PENDING: "verificationPending",
  SELECTED_USER: "selectedUser",
  WORKOUT_DAY_STATE: "workoutDayState",
  SIGNUP_STATE: "signupState",
  LAST_FEEDBACK_DATE: "lastFeedbackDate",
  LAST_GOAL_DATE: "lastGoalDate",
  ONBOARDING_DATA: "onboardingData",
  SELECTED_WORKOUT_DAY: "selectedWorkoutDay",
  PUBLIC_WORKOUT_ID: "publicWorkoutId"
};

const ROLES = {
  ADMIN: "Admin",
  TRAINER: "Trainer",
  CLIENT: "Client",
  PRACTITIONER: "Practitioner"
};

const COLLECTIONS = {
  EXERCISES: "exercises",
  WORKOUT_PROGRAM: "programs",
  WORKOUT_DAYS: "workoutDays",
  USER_DATA: "users",
  EQUIPMENTS: "equipments",
  EQUIPMENT_CATEGORIES: "equipmentCategories",
  MOVEMENT_CATEGORIES: "movementCategories",
  MOVEMENTS: "movements",
  MUSCLES: "muscles",
  MESSAGE:"messages",
  MUSCLE_GROUPS: "musclegroups",
  FUNCTIONS: "functions",
  FUNCTION_CATEGORIES: "functionCategories",
  WORKOUT_SUMMARY: "workoutSummary",
  ANALYTCIS: "analytics",
  PROGRAM_HISTORY: "programHistory",
  INVITES: "invites",
  FEEDBACK: "feedback",
  REQUESTS: "requests",
  TRAINERS: "trainers",
  ENTERPRISES: "enterprises",
  CONFIG: "config"
}

const EQUIPMENT_TYPES = {
  FREEWEIGHT: "freeweight",
  RESISTANCE: "resistance",
  SUPPORT: "support"
}

const RESISTANCE_LEVELS = {
  EXTRA_LIGHT: "EXTRA_LIGHT",
  LIGHT: "LIGHT",
  MEDIUM: "MEDIUM",
  HEAVY: "HEAVY",
  EXTRA_HEAVY: "EXTRA_HEAVY"
}

const RESET_PASSWORD_STATES = {
  LOADING: "loading",
  INVALID_CODE: "invalid",
  VALID_CODE: "valid",
  RESET_COMPLETE: 'complete'
}

const EMAIL_VERIFICATION_STATES = {
  LOADING: "loading",
  INVALID_CODE: "invalid",
  EMAIL_VERIFIED: 'complete'
}

const WELCOME_STATES = {
  LOADING: "loading",
  EMAIL_SENDING_FAILED: "invalid",
  EMAIL_SENT: 'complete'
}

const ACCOUNT_ACTIONS = {
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword"
}

const WALKTHROUGH = {
  DASHBOARD: "dashboard",
  DESIGNER: "designer",
  DESIGNER_EXERCISE: "designerExercise",
  CLIENT_EDITOR: "clientEditor",
  CLIENT_DETAIL: "clientDetail",
  WORKOUT_ASSIGNER: "workoutAssigner",
  WORKOUT_SUMMARY: "workoutSummary",
  WORKOUT_ASSIGNER_CARD: "workoutAssignerCard",
}

const INITIALIZE_WALKTHROUGH = {
  dashboard: true,
  designer: true,
  designerExercise: true,
  clientEditor: true,
  clientDetail: true,
  workoutAssigner: true,
  workoutAssignerCard: true,
  workoutSummary: true
}

const WORKOUT_TYPE = {
  INVALID: 0,
  CLIENT_WORKOUT: 1,
  TRAINER: 2,
  ALL: 3
}

const SUPPORTING_ENTITIES = {
  EQUIPMENT: "equipment",
  MUSCLE: "muscle",
  FUNCTION: "function",
  MOVEMENT: "movement"
}

const CONTINUE_ROUTES = {
  DASHBOARD: "login",
  APP: "client-email-verified"
}

const FILE_TYPE = {
  VIDEO: "video",
  IMAGE: "image"
}

const ANALYTIC_EVENTS = {
  LOGIN: "login",
  SIGNUP: "sign_up",
  NEW_CLIENT: "add_new_client",
  NEW_PROGRAM: "add_new_program",
  PROGRAM_ASSIGN: "program_assign"
}

const WORKOUT_SECTIONS_NAMES = {
  WARMUP: "Warmup",
  CIRCUIT: "Circuit",
  COOLDOWN: "Cooldown"
}

const LEVEL_UPDATE_TYPES = {
  ADD: "add",
  DELETE: "delete"
}

const SIGNUP_STATES = {
  PENDING: "pending",
  IN_PROCESS: "in-process",
  COMPLETE: "complete"
}

const SIGNUP_SCREEN = {
  SIGNUP: "signup",
  DETAILS: "details",
  TRAINER_LIST: "trainerList"
}

const ACCOUNT_STATES = {
  INVALID: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3
}

const GOAL_STATUS = {
  "PENDING": 0,
  "COMPLETE": 1,
  "FAILED": 2 
}

const DAYS_MAP = {
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
  7: "SUN"
}

const DAYS_NAME_MAP = {
  MON: "Mon",
  TUE: "Tue",
  WED: "Wed",
  THU: "Thu",
  FRI: "Fri",
  SAT: "Sat",
  SUN: "Sun"
}

const ONBOARDING_TYPES = {
  CONSISTENCY: "consistency",
  REQUIRED_SERVICE: "requiredService",
  MOTIVE: "motive"
}

const SERVICE_NAMES = {
  STUDIO_GYM_CLINIC: "Studio, Clinic or Gym",
  IN_HOME: "In-Home",
  ONLINE: "Online",
};

const SERVICES_MAP = {
  "Studio, Clinic or Gym": "studioOrGym",
  "In-Home": "inHome",
  "Online": "online",
};

const SERVICES = [
  SERVICE_NAMES.STUDIO_GYM_CLINIC,
  SERVICE_NAMES.IN_HOME,
  SERVICE_NAMES.ONLINE,
];

const CONTINUE_URL = process.env.NODE_ENV === "development" ? "https://appstaging.fortisforma.com/#/client-email-verified" : "https://app.fortisforma.com/#/client-email-verified";

const HOST_URL = "https://app.fortisforma.com/"

const COUNTRY = "Canada";

const TIMEOUT_DELAY = 1000;

const PURCHASED_TYPES = {
  INVALID:0,
  TRIAL:1,
  FULL:2,
}

const PURCHASE_STATUS = {
  INVALID:0,
  UPGRADE:1,
  VALID:2
}

const INVALID_LOGIN_REDIRECT_URL =
  process.env.NODE_ENV === "development"
    ? "https://staging.fortisforma.com/#/login"
    : "https://dashboard.fortisforma.com/#/login";

const LANDING_PAGE_URL = "https://fortisforma.com";

const REDIRECT_ROUTE = "/#/login"

module.exports = {
  INVALID_LOGIN_REDIRECT_URL,
  PURCHASE_STATUS,
  PURCHASED_TYPES,
  DB_KEYS: DB_KEYS,
  ERROR_CODES: ERROR_CODES,
  LOCAL_STORAGE_KEYS: LOCAL_STORAGE_KEYS,
  ROLES: ROLES,
  COLLECTIONS: COLLECTIONS,
  EQUIPMENT_TYPES: EQUIPMENT_TYPES,
  RESISTANCE_LEVELS: RESISTANCE_LEVELS,
  ACTION_STATES: ACTION_STATES,
  USER_STATES: USER_STATES,
  RESET_PASSWORD_STATES: RESET_PASSWORD_STATES,
  EMAIL_VERIFICATION_STATES: EMAIL_VERIFICATION_STATES,
  WELCOME_STATES: WELCOME_STATES,
  ACCOUNT_ACTIONS,
  WALKTHROUGH,
  INITIALIZE_WALKTHROUGH,
  WORKOUT_TYPE,
  CONTINUE_ROUTES,
  CONTINUE_URL,
  FILE_TYPE,
  SUPPORTING_ENTITIES,
  ANALYTIC_EVENTS,
  WORKOUT_SECTIONS_NAMES,
  LEVEL_UPDATE_TYPES,
  SIGNUP_SCREEN,
  SIGNUP_STATES,
  ACCOUNT_STATES,
  HOST_URL,
  GOAL_STATUS,
  DAYS_MAP,
  ONBOARDING_TYPES,
  SERVICES_MAP,
  SERVICES,
  SERVICE_NAMES,
  COUNTRY,
  LANDING_PAGE_URL,
  REDIRECT_ROUTE,
  DAYS_NAME_MAP,
  MONTHS
}
