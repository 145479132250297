import React from 'react';
import ExerciseCard from '../exerciseCard/exerciseCard';
import PublicWorkoutSection from './publicWorkoutSection';

export default class PublicSectionedWorkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedExercise: null,
            selectedExerciseIndex: null,
            selectedSectionIndex: null,
            openExerciseCard: false,
            openExerciseDetailModal: false,
        };
    }
    
    componentDidMount = () => {
        this.setState({
            selectedExercise: this.props.data?.workoutSections[0]?.exercises[0],
            selectedExerciseIndex: 0,
            selectedSectionIndex: 0,
        });
        // this.props.scrollToVideoPlayer();
    };
    
    renderSelectedExerciseCard() {
        return (<ExerciseCard
            data={this.state.selectedExercise}
            disabled
        />);
    }
    
    onClickExerciseMiniCard = (exercise, sectionIndex, exerciseIndex) => {
        this.setState({
            selectedExercise: exercise, selectedExerciseIndex: exerciseIndex, selectedSectionIndex: sectionIndex,
        });
    };
    
    render() {
        let workoutSections = this.props.data.workoutSections;
       
        return (<React.Fragment>
            <div id="homePage">
                <div id="sectionedWorkoutContainer" className="sectionedWorkout">
                    {workoutSections?.map((section, index) => {
                        return <PublicWorkoutSection
                            key={index}
                            section={section}
                            listIndex={index}
                            firstSection={workoutSections[0]}
                            selectedExercise={this.state.selectedExercise}
                            selectedExerciseIndex={this.state.selectedExerciseIndex}
                            selectedSectionIndex={this.state.selectedSectionIndex}
                            onClickExerciseMiniCard={this.onClickExerciseMiniCard}
                        />;
                    })}
                </div>
                <div id="homePageDetailContainer">
                    {this.state.selectedExercise && this.state.selectedExercise.id && this.renderSelectedExerciseCard()}
                </div>
            </div>
        </React.Fragment>);
    }
    
}
