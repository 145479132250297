import { Component } from "react";
import { DB_KEYS } from "../../../constants";

export default class LoginSocial extends Component {
  _socialLogin = (e, functionName) => {
    try {
      window.FortisForma.database.signOut(false);
    } catch (e) {
      console.error(e);
    }

    if (e.nativeEvent) {
      e.nativeEvent.stopPropagation();
    }

    this.setState({
      authButtonEnabled: false,
      loading: true
    });

    window.localStorage.removeItem(DB_KEYS.SIGN_UP_STATE);

    window.FortisForma.database[functionName]()
      .then(async result => {
        try {
          await window.FortisForma.database.updateUserLoginTime();
          this.setState(
            {
              authButtonEnabled: true,
              loading: false
            },
            () => {
              this.onLoginSuccess && this.onLoginSuccess(result && result.user);
            }
          );
        } catch (e) {
          console.error(e);
        }
      })
      .catch(error => {
        this.showError(error);
        try {
          this.setState({
            authButtonEnabled: true,
            loading: false
          });
        } catch (e) {}
      });
  };

  loginWithGoogle = e => {
    return this._socialLogin(e, "loginWithGoogle");
  };

  loginWithFacebook = e => {
    return this._socialLogin(e, "loginWithFacebook");
  };
}
