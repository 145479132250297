import { Button, Divider, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import {
  COUNTRY,
  DB_KEYS,
  LOCAL_STORAGE_KEYS,
  SERVICES_MAP,
  SERVICE_NAMES,
  SERVICES
} from "../../constants";
import DataFetcher from "../../modules/http/dataFetcher";
import Signup from "../../views/signup/index";
import TrainerCard from "./trainerCard";
import TrainerFilters from "./trainerFilters";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from "clsx";
import { isMobileOnly } from "react-device-detect";

var bodybuilder = require("bodybuilder");

const LIMIT = 3;

export default class TrainerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postalCode: "",
      loading: false,
      trainers: [],
      showPrograms: false,
      showSignupModal: false,
      currentPage: 0,
      selectedServices: [],
      initial: true,
      searchDisabled: true,
      showAllFilters: false,
      signupParams: null,
      loggedInParams:null,
      trainerNotFound: false,
      postalCodeChanged: false
    };
    this.placeLocation = {};
    this.trainerFetcher = DataFetcher.trainersFetcher();
    this.gotoChallenges = this.gotoChallenges.bind(this);
  }

  componentDidMount() {
    let onboardingData = window.sessionStorage.getItem(
      LOCAL_STORAGE_KEYS.ONBOARDING_DATA
    );
    if (!onboardingData) {
      this.props.history.replace("/");
    }
    onboardingData = JSON.parse(onboardingData);
    let services = onboardingData?.requiredService;
    this.setState({
      selectedServices: services,
    });
    if(isMobileOnly){
      this.scrollToTop()
    }
  }

  scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  };

  fetchTrainers = async (filters) => {
    this.setState({
      loading: true,
      showAllFilters: true,
    });
    try {
      this.trainerFetcher.clearResults();
      let trainers = await this.trainerFetcher.fetch(filters);
      let currentPage = 0;
      if (!trainers.length && this.state.postalCodeChanged) {
        window.NotificationUtils.showError("No trainers found in your area");
        this.setState({
          selectedServices: [SERVICES[2]],
          postalCodeChanged: false
        }, this.searchOnlineTrainers)
        return 
      }
      this.setState(
        {
          trainers: trainers,
          currentPage: currentPage,
          loading: false,
          postalCodeChanged: false
        }
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Error while fetching trainers");
    }
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  gotoChallenges() {
    this.props.history.push('/challenges')
  }

  searchOnlineTrainers=()=>{
    this.updateTrainerList();
    this.setState({
      trainerNotFound: true
    });
  }

  onChangePostalCode = (postalCode, submit = false) => {
    this.setState(
      {
        postalCode,
        loading: false,
        searchDisabled: true,
        postalCodeChanged: true
      }
    );
  };

  onClickSearchPostalCode=()=>{
    this.setState({
      searchDisabled: false,
      initial: false,
    });
    this.updateTrainerList();

  }

  renderSearchButton=()=>{
    return(
      <Button 
      variant="contained" 
      disabled={this.state.postalCode.length < 3} 
      color="primary" 
      onClick={this.onClickSearchPostalCode}
      className={`marginTop18 onBoardButton`} 
      size={"large"}
      >
        Search
      </Button>
    )
  }

  selectTrainer = (selectedTrainer) => {
    let onboardingData = window.sessionStorage.getItem(
      LOCAL_STORAGE_KEYS.ONBOARDING_DATA
    );
    if (!onboardingData) {
      onboardingData = "{}";
    }
    try {
      onboardingData = JSON.parse(onboardingData);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }

    let formData = {
      trainerId: selectedTrainer.userId,
      postal: `${this.state.postalCode} xxx`,
      ...onboardingData,
    };

    this.setState({
      selectedTrainer,
      showSignupModal: true,
      signupParams: formData,
    });
  };

  closeSignupModal = () => {
    this.setState({
      selectedTrainer: null,
      showSignupModal: false,
    });
  };

  showPrograms = (selectedTrainer) => {
    this.setState({
      showPrograms: true,
      selectedTrainer,
    });
  };

  hidePrograms = () => {
    this.setState({
      selectedTrainer: null,
      showPrograms: false,
    });
  };

  handleServiceSelect = (event) => {
    this.setState({
      selectedServices: event.target.value,
      postalCodeChanged: true
    });
  };

  updateTrainerList = async () => {
    this.setState({
      currentPage: 0,
      loading: true,
      trainerNotFound: false
    });
    let services = [];
    for (let service of this.state.selectedServices) {
      services.push(SERVICES_MAP[service]);
    }
    let queryInHome = services.includes(SERVICES_MAP[SERVICE_NAMES.IN_HOME]);
    let queryStudio = services.includes(SERVICES_MAP[SERVICE_NAMES.STUDIO_GYM_CLINIC]);

    let postalCodeToLocation;
    let trainerSearchRadius = "10km";

    if (queryInHome || queryStudio) {
      try {
        postalCodeToLocation = await this.getLocationFromPostalCode();
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError("Unable to find location");
        return;
      }

      try {
        trainerSearchRadius = await window.FortisForma.database.getTrainerSearchRadius();
      } catch (e) {
        console.error("Unable to get config from global");
      }
    }

    let query = this.buildServicesQuery(
      bodybuilder().filter("term", DB_KEYS.VISIBLE, true),
      {
        postalCodeToLocation,
        trainerSearchRadius,
      }
    );

    let filters = query.build();
    this.fetchTrainers(filters);
  };

  buildServicesQuery = (query, locationConfig) => {
    let services = [];
    for (let service of this.state.selectedServices) {
      services.push(SERVICES_MAP[service]);
      query = query.orFilter("terms", DB_KEYS.SERVICE_MODES, [
        SERVICES_MAP[service],
      ]);
    }

    let queryOnline = services.includes(SERVICES_MAP[SERVICE_NAMES.ONLINE]);


    if (!queryOnline) {
      const { postalCodeToLocation, trainerSearchRadius } = locationConfig;

      query = query.filter("geo_distance", {
        distance: trainerSearchRadius,
        location: postalCodeToLocation,
      });
    }

    return query;
  };

  async getLocationFromPostalCode() {
    return new Promise((resolve, reject) => {
      var service = new window.google.maps.places.AutocompleteService();
      service.getQueryPredictions(
        { input: this.state.postalCode },
        async (predictions) => {
          try {
            let location = await this.getPlace(predictions);
            resolve(location);
          } catch (e) {
            reject(e);
          }
        }
      );
    });
  }

  getPlace = async (predictions) => {
    if (!predictions?.length) {
      throw new Error("No predictions found");
    }
    let selectedPrediction;
    for (let prediction of predictions) {
      if (selectedPrediction) {
        break;
      }
      if (prediction.terms) {
        for (let term of prediction.terms) {
          if (term.value === COUNTRY) {
            selectedPrediction = prediction;
            break;
          }
        }
      }
    }
    if (!selectedPrediction) {
      selectedPrediction = predictions[0];
    }
    let placeData;
    try {
      placeData = await window.FortisForma.database.getPlaceDetails(
        selectedPrediction.place_id
      );
    } catch (e) {
      throw e;
    }
    return {
      lat: placeData?.result?.geometry?.location?.lat,
      lon: placeData?.result?.geometry?.location?.lng,
    };
  };

  getCurrentPageTrainers() {
    let trainers = Object.assign([], this.state.trainers);
    let nextRequiredTrainerIndex = this.state.currentPage * LIMIT + LIMIT;
    if (nextRequiredTrainerIndex > trainers.length) {
      nextRequiredTrainerIndex = trainers.length;
    }
    let trainersToShow = trainers.slice(0, nextRequiredTrainerIndex);
    return trainersToShow;
  }

  onBackPage = () => {
    let currentPage = this.state.currentPage;
    this.setState({
      currentPage: --currentPage,
    });
  };

  onNextPage = () => {
    let currentPage = this.state.currentPage;
    this.setState(
      {
        currentPage: ++currentPage,
      },
      ()=>{
        if(!isMobileOnly){
          this.scrollToEnd()
        }
      }
    );
  };

  scrollToEnd = () => {
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop =
        document.scrollingElement.scrollHeight;
    }
  };

  isBackDisabled = (currentTrainers) => {
    let backDisabled = false;
    if (this.state.trainers) {
      for (let trainer of currentTrainers) {
        if (this.state.trainers[0].id === trainer.id) {
          backDisabled = true;
        }
      }
    }
    return backDisabled;
  };

  isNextDisabled = (currentTrainers) => {
    let nextDisabled = false;
    if (this.state.trainers) {
      for (let trainer of currentTrainers) {
        if (
          this.state.trainers[this.state.trainers.length - 1].id === trainer.id
        ) {
          nextDisabled = true;
        }
      }
    }
    return nextDisabled;
  };

  renderSkeleton() {
    return (
      <Skeleton
        variant="rect"
        width={340}
        height={500}
        className="trainerCardRadius trainerCard"
      />
    );
  }

  renderTrainersLoading() {
    return (
      <div className="trainersContainer">
        <div id="trainerCardContainer">
          {this.renderSkeleton()}
          {this.renderSkeleton()}
          {this.renderSkeleton()}
        </div>
      </div>
    );
  }

  renderTrainers() {
    let currentTrainers = this.getCurrentPageTrainers();
    return (
      <div className="trainersContainer">
        <div id="trainerCardContainer">
          {currentTrainers.map((trainerData, index) => {
            return (
              <TrainerCard
                trainerData={trainerData}
                key={index}
                showPrograms={this.showPrograms}
                selectTrainer={this.selectTrainer}
              />
            );
          })}
        </div>
        <div
          className={clsx({
            flexCenter: true,
            prominent: this.state.firstLoad,
          })}
          id="loadMoreTrainersContainer"
        >
          <Button
            style={{marginRight: 8}}
            variant="outlined"
            color="primary"
            onClick={this.onNextPage}
            disabled={this.isNextDisabled(currentTrainers)}
          >
            Load More
          </Button>

          <Button
            color="secondary"
            variant="outlined"
            endIcon={<ArrowForwardIosIcon color="secondary" />}
            onClick={this.gotoChallenges}
          >
            Show Challenges Instead
          </Button>


        
        </div>
      </div>
    );
  }

  renderContactForm = () => {
    return (
      <Dialog
        maxWidth="sm"
        open={this.state.showSignupModal}
        onClose={this.closeContactForm}
      >
        <DialogTitle>Create Account</DialogTitle>
        <Signup
          {...this.props}
          trainerId={this.state.selectedTrainer?.id || ""}
          signupParams={this.state.signupParams}
          onClose={this.closeSignupModal}
        />
      </Dialog>
    );
  };

  renderSkipToChallengesButton(showDivider) {
    return(
      <React.Fragment>
        {showDivider === true && <div className="trainerSearchOrDivider">
          <Divider className="halfDivider" />
          <Typography className="trainerSearchOR">OR</Typography>
          <Divider className="halfDivider" />
        </div>}
        <div className="flexCenter flexColumn">
          {this.state.initial && <Typography
          variant="h6"
          style={{ color: "grey" }}
          className="marginBottom18 textAlignCenter"
          >
            Just want an exercise program? Try one of our workout challenges!
          </Typography>}
          <Button 
            color="primary" 
            variant="outlined"
            endIcon={<ArrowForwardIosIcon color="primary" />}
            onClick={this.gotoChallenges}
            size={"medium"}
            className="onBoardButton"
            >
            Skip to Challenges
          </Button>

        </div>
      </React.Fragment>
    )
  }

  renderTrainersNotFoundContent() {
    return (
      <div className="noTrainerContentContainer">
        <Typography className="noTrainerText">
          We currently provide In-Home, Studio, Clinic or Gym services in Ottawa and some parts of Toronto<br/>
          You can try searching for online service or go ahead and try our challenges
        </Typography>
        {this.renderSkipToChallengesButton(false)}
      </div>
    )
  }

  renderTrainerNotFoundText(){
    return(
      <Typography variant="h6" className="marginBottom18">
        Sorry, couldn't find any trainer in your area, try connecting to our online trainers instead.
      </Typography>
    )
  }

  render() {
    return (
      <div className={`pageContainer ${this.state.initial && "trainerSearchInitialContainer"}`} id="trainerSearchPage">
        <div className="flexCenter flexColumn" >
          <TrainerFilters
            postalCode={this.state.postalCode}
            onChangePostalCode={this.onChangePostalCode}
            selectedServices={this.state.selectedServices}
            handleServiceSelect={this.handleServiceSelect}
            searchDisabled={this.state.postalCode.length < 3}
            searchTrainers={this.updateTrainerList}
            showAllFilters={this.state.showAllFilters}
            initial={this.state.initial}
            {...this.props}
          />
          {this.state.initial && this.renderSearchButton()}

        </div>
        {this.state.initial && this.renderSkipToChallengesButton(true)}
        {this.state.loading && this.renderTrainersLoading()}
        {Boolean(this.state.trainers.length && !this.state.loading && this.state.trainerNotFound) &&
          this.renderTrainerNotFoundText()}
        {Boolean(this.state.trainers.length && !this.state.loading) &&
          this.renderTrainers()}
        {this.renderContactForm()}
      </div>
    );
  }
}
