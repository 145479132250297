import React from 'react';
import { DialogContent, DialogActions, Button, CircularProgress } from "@material-ui/core";
import PasswordInput from '../../components/passwordField/passwordComponent';

export default class ChangePasswordEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
        this.onChangeOldPassword = this.onChange.bind(this, "oldPassword");
        this.onChangeNewPassword = this.onChange.bind(this, "newPassword");
        this.onChangeConfirmPassword = this.onChange.bind(this, "confirmPassword");
        this.handleClickShowOldPassword = this.handleClickShowPassword.bind(this, "showOldPassword");
        this.handleClickShowNewPassword = this.handleClickShowPassword.bind(this, "showNewPassword");
        this.handleClickShowConfirmPassword = this.handleClickShowPassword.bind(this, "showConfirmPassword");
    }

    onChange(key, event) {
        let update = {};
        update[key] = event.target.value;
        this.setState(update);
    }

    handleClickShowPassword(key) {
        let update = {};
        update[key] = !this.state[key];
        this.setState(update);
    }

    checkErrorsForPasswordChange = () => {
        if (!this.props.user.email || this.props.user.email === "") {
          let alertMsg = "Email not found";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    
        if (!this.state.oldPassword || this.state.oldPassword === "") {
          let alertMsg = "Please enter current password";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    
        if (!this.state.newPassword || this.state.newPassword === "") {
          let alertMsg = "Please enter new password";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    
        if (!this.state.confirmPassword || this.state.confirmPassword === "") {
          let alertMsg = "Please enter confirm password";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    
        if (this.state.oldPassword === this.state.newPassword) {
          let alertMsg = "Please enter a different password";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    
        if (this.state.newPassword.length < 6) {
          let alertMsg = "Password length should be greater than 6";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    
        if (this.state.newPassword !== this.state.confirmPassword) {
          let alertMsg = "Passwords don't match";
          window.NotificationUtils.showError(alertMsg);
          return true;
        }
    }

    changePassword = async () => {
        var email = this.props.user.email;
        var oldPassword = this.state.oldPassword;
        var newPassword = this.state.newPassword;
        const _this = this;
    
        if (this.checkErrorsForPasswordChange()) {
          this.setState({ loader: false });
          return;
        }
        this.setState({ loader: true });
        try {
          await window.FortisForma.database.signInWithEmailAndPassword(email, oldPassword)
        } catch (e) {
          console.error(e)
          window.NotificationUtils.showError("Incorrect password");
          _this.setState({ loader: false });
          return
        }
    
        try {
          await window.FortisForma.database.updatePassword(newPassword);
          window.NotificationUtils.showSuccess("Password change successful");
          this.setState({ loader: false });
          this.props.onClose();
        } catch (e) {
          console.error(e)
          window.NotificationUtils.showError("Password change unsuccessful")
          this.setState({ loader: false });
        }
    }

    render() {
        if(this.state.loader) {
            return (
                <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress color="primary" style={{margin: 32}}/>
                </DialogContent>
            )
        }
        return (
            <>
                <DialogContent>
                    {this.renderForm()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={this.changePassword} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </>
        )
    }

    renderForm() {
        return (
            <div className="changePasswordForm">
                <PasswordInput
                  label={"Old Password"}
                  showIndicator={false}
                  showIcon={false}
                  showPassword={this.state.showOldPassword}
                  value={this.state.oldPassword}
                  onChange={this.onChangeOldPassword}
                  handleClickShowPassword={this.handleClickShowOldPassword}
                />
                <PasswordInput
                  label={"New Password"}
                  showIndicator={false}
                  showIcon={false}
                  showPassword={this.state.showNewPassword}
                  value={this.state.newPassword}
                  onChange={this.onChangeNewPassword}
                  handleClickShowPassword={this.handleClickShowNewPassword}
                />
                <PasswordInput
                  label={"Confirm Password"}
                  showIndicator={false}
                  showIcon={false}
                  showPassword={this.state.showConfirmPassword}
                  value={this.state.confirmPassword}
                  onChange={this.onChangeConfirmPassword}
                  handleClickShowPassword={this.handleClickShowConfirmPassword}
                />
            </div>
        )
    }
}