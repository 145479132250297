import React from 'react';
import ReactPlayer from 'react-player';
import Reps from '../../assets/repsD.png';
import Sets from '../../assets/repsD.png';
import Weight from '../../assets/weightD.png';
import Clock from '../../assets/timeD.png';
import Resistance from '../../assets/resistanceD.png';

import {EQUIPMENT_TYPES} from '../../constants';

const RESISTANCE_OPTIONS = {
    'EXTRA_LIGHT': 'Extra Light',
    'LIGHT': 'Light',
    'MEDIUM': 'Medium',
    'HEAVY': 'Heavy',
    'EXTRA_HEAVY': 'Extra Heavy',
};

export default class SectionedListExerciseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: true,
            showReplay: false,
        };
        this.playerRef = null;
        this.posterURL = props.data.posterURL;
    }
    
    onPressReplay = () => {
        this.setState({
            playing: true,
            showReplay: false,
        });
    };
    
    onFinishVideo = () => {
        this.playerRef && this.playerRef.seekTo(0);
        this.setState({
            playing: false,
            showReplay: true,
        });
    };
    
    componentDidUpdate() {
        // HACK placeholder not refreshing
        if (this.posterURL !== this.props.data.posterURL) {
            this.posterURL = this.props.data.posterURL;
            let showPreview = this.playerRef?.state?.showPreview;
            if (showPreview && this.playerRef) {
                this.playerRef.setState({
                    showPreview: !showPreview,
                }, () => {
                    if (this.playerRef) {
                        this.playerRef.setState({
                            showPreview: showPreview,
                        });
                    }
                });
            }
        }
    }
    
    isEmptyEquipmentType() {
        let types = Object.values(this.props.data.equipmentTypes);
        let isEmptyType = true;
        for (let type of types) {
            if (type) {
                isEmptyType = false;
            }
        }
        return isEmptyType;
    }
    
    isSetsDisabled = () => {
        if (this.setsDisabled !== undefined) {
            return this.setsDisabled;
        }
        this.setsDisabled = !this.props.data.sets;
        return this.setsDisabled;
    };
    
    isRepsDisabled = () => {
        if (this.repsDisabled !== undefined) {
            return this.repsDisabled;
        }
        this.repsDisabled = !this.props.data.reps;
        return this.repsDisabled;
    };
    
    isTimeDisabled = () => {
        if (this.timeDisabled !== undefined) {
            return this.timeDisabled;
        }
        this.timeDisabled = !this.props.data.time;
        return this.timeDisabled;
    };
    
    isResistanceDisabled = () => {
        let equipmentTypes = Object.values(this.props.data.equipmentTypes);
        let resistanceDisabled = !equipmentTypes.includes(EQUIPMENT_TYPES.RESISTANCE);
        if (resistanceDisabled && this.isEmptyEquipmentType()) {
            resistanceDisabled = !this.props.data.resistance;
        }
        return resistanceDisabled;
    };
    
    isWeightDisabled = () => {
        if (this.weightDisabled !== undefined) {
            return this.weightDisabled;
        }
        let equipmentTypes = Object.values(this.props.data.equipmentTypes);
        this.weightDisabled = !equipmentTypes.includes(EQUIPMENT_TYPES.FREEWEIGHT);
        if (this.weightDisabled && this.isEmptyEquipmentType()) {
            this.weightDisabled = !this.props.data.weight;
        }
        return this.weightDisabled;
    };
    
    renderExtra(key, value, icon, options) {
        return (<div className="configMainContainer">
            <div className="configImageKey">
                {
                    icon && (<img style={{
                        width: 13,
                        height: 13,
                        marginRight: 5,
                    }} src={icon} alt={key}></img>)
                }
                <span className="configKey">{key}:</span>
            </div>
            <div className="configValue">
                {value} {options && options.unit}
            </div>
        </div>);
    }
    
    renderConfig() {
        return (
            <div style={{display: 'flex'}}>
                {
                    this.props.data.resistance && !this.isResistanceDisabled() && (
                        <div>
                            {this.renderExtra('Resistance', RESISTANCE_OPTIONS[this.props.data.resistance], Resistance)}
                        </div>
                    )
                }
                {
                    Boolean(Number(this.props.data.sets)) && !this.isSetsDisabled() && (
                        <div>
                            {this.renderExtra('Sets', this.props.data.sets, Sets)}
                        </div>
                    )
                }
                {
                    Boolean(Number(this.props.data.reps)) && !this.isRepsDisabled() && (
                        <div>
                            {this.renderExtra('Reps', this.props.data.reps, Reps)}
                        </div>
                    )
                }
                {
                    Boolean(Number(this.props.data.weight)) && !this.isWeightDisabled() && (
                        <div>
                            {this.renderExtra('Weight', this.props.data.weight, Weight)}
                        </div>
                    )
                }
                {
                    Boolean(Number(this.props.data.time)) && !this.isTimeDisabled() && (
                        <div>
                            {this.renderExtra('Time', this.props.data.time, Clock)}
                        </div>
                    )
                }
            </div>
        );
    }
    
    renderVideoPlayer = () => {
        return (
            <div className="sectionedVideoPlayer">
                <ReactPlayer ref={
                    (ref) => {
                        this.playerRef = ref;
                    }}
                             style={{backgroundColor: '#000', borderRadius: 2}}
                             autoPlay={this.state.playing}
                             playing={this.state.playing}
                             playsinline={this.state.playing}
                             controls={false}
                             muted={true}
                             url={this.props.data.videoURL}
                             width={60}
                             height={60}
                             light={this.props.data.posterURL || true}
                             onEnded={this.onFinishVideo}
                />
                {
                    this.state.showReplay &&
                    <div className="videoReplayButton"
                         style={{top: 0, right: 0}}
                         onClick={this.onPressReplay}
                    >
                        <i className="material-icons videReplayIcon">replay</i>
                    </div>
                }
            </div>
        );
    };
    
    renderPoster = () => {
        return (
            <div className="sectionedVideoPlayer">
                {
                    this.props.data.posterURL ? (
                        <img
                            className="sectionedPoster"
                            alt="Poster"
                            src={this.props.data.posterURL}
                        />
                    ) : (
                        <div className="sectionedNoPoster"/>
                    )
                }
            </div>
        );
    };
    
    render() {
        return (
            <div className="sectionedExerciseCard">
                {
                    this.props.data.videoURL ?
                        this.renderVideoPlayer() :
                        this.renderPoster()
                }
                <div className="sectionedCardContent">
                    <div className="sectionedExerciseNameLevel">
                        <div className="sectionedExercisePName">{this.props.data.name}</div>
                    
                    </div>
                    <div className="sectionedExerciseConfig">
                        {this.renderConfig()}
                    </div>
                
                </div>
            </div>
        );
    }
}