import React from 'react'
import AppDownload from "../../components/appDownload";
import LoginAuthContent from '../../components/loginAuth/loginAuthContent';

export default function LoginAuthentication(props) {
  const routeToHome = () => {
    props.history.replace("/home")
  }
    return (
        <>
        <div className="container">
          <div style={{ display: "flex", height: "100%"}}>
            <div className="imageContainer">
              <img
                alt="client Logo"
                className="imageStyle"
                src="/assets/womenGym.jpg"
              />
            </div>
            <div
              className="loginContainer"
              style={{ width: "35%", alignItems: "center" }}>
                <div className="flexDirection">
                    <LoginAuthContent routeToHome={routeToHome}/>
                </div>
                <div id="appIconsLogin">
                <AppDownload />
              </div>
            </div>
          </div>
        </div>
      </>
    )
}
