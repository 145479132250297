import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, TextField, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import TAButton from '../../components/taButton';
import NumberFormat from 'react-number-format'

const REGIONS = [
    { label: 'Alberta' },
    { label: 'British Columbia' },
    { label: 'Manitoba' },
    { label: 'New Brunswick' },
    { label: 'Newfoundland and Labrador' },
    { label: 'Northwest Territories' },
    { label: 'Nova Scotia' },
    { label: 'Nunavut' },
    { label: 'Ontario' },
    { label: 'Prince Edward Island' },
    { label: 'Quebec' },
    { label: 'Saskatchewan' },
    { label: 'Yukon Territory' },
]
class ProfileEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props.user,
            enableMFA: this.props.user.enableMFA || false,
        }
        if (props.user && props.user.province) {
            this.state.selectedProvince = { label: props.user.province }
        }
        this.state.saving = false;
    }

    onChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    formatPhone = (value) => {
        let newValue = value.split(" ").join("").slice(2);
        return newValue;
    }

    handleMFAChange = (event) => {
        this.setState({
          enableMFA: event.target.checked,
        });
      };

    onClickSave = async () => {
        let profile = this.mapProfile();
        if (this.checkErrors()) {
            return;
        }

        this.setState({
            saving: true
        })
        await window.FortisForma.database.storeUserProfile(profile);
        this.setState({
            saving: false,
            saved: true
        }, () => {
            setTimeout(() => {
                if(!this.props.user.enableMFA && this.state.enableMFA){
                    // When user has mfa disabled, and toggles it on, log the person out
                    return this.props.handleLogout()
                }
                this.closeAfterSave(profile)
            }, 250);
        })
    }

    closeAfterSave(profile) {
        setTimeout(() => {
            this.props.onClose()
            this.props.onUserChange(Object.assign({}, this.props.user, profile));
        }, 250)
    }

    mapProfile() {
        return {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            postal: this.state.postal,
            province: this.state.province,
            enableMFA: this.state.enableMFA
        }
    }

    checkErrors() {
        this.setState({
            checkErrors: true
        })

        if (!this.state.name) {
            return true;
        } else if (!this.state.email) {
            return true;
        }
    }

    render() {
        return (
            <>
                <DialogContent>
                    {this.renderForm()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="secondary">
                        Cancel
                    </Button>
                    <TAButton
                        isLoading={this.state.saving}
                        loadingMessage={'Saving ...'}
                        onClick={this.onClickSave}
                        isSuccess={this.state.saved}
                        color="primary">
                        Save
                    </TAButton>
                </DialogActions>
            </>
        );
    }

    renderForm() {
        return (
            <div className="profileForm">
                <TextField
                    error={this.state.checkErrors && Boolean(!this.state.name)}
                    variant="outlined"
                    label="Name"
                    value={this.state.name}
                    onChange={(e) => {
                        this.onChange('name', e.target.value)
                    }}
                />
                <TextField
                    error={this.state.checkErrors && Boolean(!this.state.email)}

                    variant="outlined"
                    label="Email"
                    value={this.state.email}
                    disabled={true}
                />
                <NumberFormat
                    format="+1 ### ### ####"
                    mask=" "
                    placeholder="e.g. +1 999 999 9999"
                    customInput={TextField}
                    type="tel"
                    variant="outlined"
                    label="Phone No."
                    value={this.state.phone}
                    onChange={(e) => {
                        this.onChange('phone', this.formatPhone(e.target.value))
                    }}
                />
                <Autocomplete
                    value={this.state.selectedProvince}
                    onChange={(e, value) => {
                        if (!value) {
                            return
                        }
                        this.onChange('province', value.label)
                        this.onChange('selectedProvince', value)
                    }}
                    options={REGIONS}
                    getOptionLabel={(option) => option.label}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label="Province" variant="outlined" />}
                />
                <TextField
                    variant="outlined"
                    label="Address"
                    value={this.state.address}
                    onChange={(e) => {
                        this.onChange('address', e.target.value)
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Postal"
                    value={this.state.postal}
                    onChange={(e) => {
                        this.onChange('postal', e.target.value)
                    }}
                />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.enableMFA}
                                onChange={this.handleMFAChange}
                                name="enableClientMFA"
                                color="primary"
                            />
                        }
                        label="Enable email based two-step verification"
                    />
                    <Typography variant="body2" className="greyColor mfaLabelNote">
                        Note: Enabling this will automatically log you out.
                    </Typography>
            </div>
        )
    }
}

export default ProfileEditor;