import React, {Component} from 'react';
import { Button, Typography } from '@material-ui/core';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/landing.json';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

export default class Landing extends Component {

    gotoLogin = () => {
        this.props.history.push('/login')
    }

    startOnboarding = () => {
        this.props.history.push("/signup");
    }

    renderAppBar() {
        return (
            <div id="landingAppBar">
                <div className="flexCenter">
                    <img alt="Logo" src={'/assets/arrow-logo.png'} id="arrowLogo" />
                    <img alt="Logo" src={'/assets/text-logo.png'} id="textLogo" />
                </div>
                <div>
                    <Button style={{marginTop: 1}} variant="outlined" color="primary" onClick={this.gotoLogin}>
                        Log in
                    </Button>
                </div>
            </div>
        )
    }

    goToChallenges=()=>{
        this.props.history.push("/challenges");
    }

    renderLandingLeftSide() {
        return (
            <div id="landingLeftContent">
                <Typography id="landingPageMainHeading" variant="h1" >
                    Connect to your <br />
                    personal trainer
                </Typography>
                <Typography variant="h4" className="pd-top-32">
                    From iron fist to velvet glove find the<br /> right trainer for you.
                </Typography>
                <div id="landingPageButtonContainer" className="pd-top-32">
                        <Button variant="contained" color="primary" size="large" onClick={this.startOnboarding}>
                            Find My Trainer
                        </Button>
                        <Button variant="outlined" id="challengesButton" color="primary" size="large" onClick={this.goToChallenges}>
                            Try our challenges
                        </Button>

                </div>
            </div>
        )
    }

    renderLandingRightSide() {
        return (
            <div id="landingRightContent">
                <Lottie options={defaultOptions}
                    height={520}
                    width={550}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="pageContainer">
                {this.renderAppBar()}
                <div className="landingMainContent">
                    {this.renderLandingLeftSide()}
                    {this.renderLandingRightSide()}
                </div>
            </div>
        )
    }
}