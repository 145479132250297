import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Avatar} from '@material-ui/core';

export default function AvatarMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        handleClose();
        props.handleProfile();
    };

    const handleGoalSet = () => {
        handleClose();
        props.handleGoalSet();
    };

    const handleChangePassword = () => {
        handleClose();
        props.handleChangePassword();
    };

    const handleLogout = () => {
        handleClose();
        props.handleLogout();
    };

    const handleMyPrograms = () => {
        handleClose();
        props.handleMyPrograms();
    };

    const handleResetProgram = () => {
        handleClose();
        props.handleResetProgram();
    };
    const handleMessageCenter = () => {
        handleClose();
        props.handleMessageCenter();
    };
    return (<div>
        <Avatar id="userProfilePic" aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>
            {props.label}
        </Avatar>
        <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleMyPrograms}>My Programs/Challenges</MenuItem>
            {props.handleGoalSet && <MenuItem onClick={handleGoalSet}>Set Weekly Goals</MenuItem>}
            {props.user?.programId && <MenuItem onClick={handleResetProgram}>Reset Program</MenuItem>}
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            {(props.user?.chatTrainer&&props.canChat )&& <MenuItem onClick={handleMessageCenter}>Message Center{props?.counter !== 0 &&
                <span className='textMsgRead'> {props?.counter}</span>}</MenuItem>}
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    </div>);
}
