import { Avatar, Button, Chip, Typography } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { Component } from "react";
import ReactCardFlip from "react-card-flip";
import TrainerPrograms from "./trainerPrograms";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton' ;
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isMobileOnly } from "react-device-detect";

export default class TrainerCard extends Component {
  state = {
    isFlipped: false,
    expand: false,
    isMobile: false,

  };

  componentDidMount(){
    if (isMobileOnly) {
      this.setState({
        isMobile: true,
      });
  }
  }
  showPrograms = () => {
    this.setState({
      isFlipped: true,
    });
    setTimeout(() => {
      document.querySelector(
        `#trainerCard-${this.props.trainerData.id} .react-card-back`
      ).style["z-index"] = 3;
    }, 500);
  };

  hidePrograms = () => {
    document.querySelector(
      `#trainerCard-${this.props.trainerData.id} .react-card-back`
    ).style["z-index"] = 0;
    this.setState({
      isFlipped: false,
    });
  };

  renderTrainerInfo = (title, info, verified) => {
    return (
      <div className="flexColumn pd-top-16">
        <div className="dispFlex alignItemCenter">
          <Typography variant="caption" className="trainerInfoTitle">
            {title}
          </Typography>
          {verified && <Tooltip className="mg-left-8" title={`${title} Verified`} placement="top">
            <i className="material-icons verifiedIcon" fontSize="small" color="primary">verified</i>
          </Tooltip>
          }

        </div>
        <div>
          {!Array.isArray(info) ? (
            <Typography variant="caption">{info}</Typography>
          ) : (
            <ul className="educationList">
              {info.map((item, index) => {
                return (
                  <li key={index}>
                    <Typography variant="caption">{item.name}</Typography>
                    {Boolean(item.link) && (
                      <OpenInNewIcon
                        className="certificateIcon"
                        onClick={() => window.open(item.link, "_blank")}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  };

  onClickArrowIcon=()=>{
    if(!this.state.isMobile){
      return
    }
    let expandContent = true
    if(this.state.expand){
      expandContent = false
    }
    this.setState({
      expand: expandContent
    })
  }

  onClickEducationAndCertification=()=>{
    if(this.state.expand){
      return
    }
    this.onClickArrowIcon()
  }

  renderTrainerCard = (trainerData) => {
    let shortCodes = [];
    if (
      trainerData.documents &&
      trainerData.documents.educationAndCertification
    ) {
      for (let edu of trainerData.documents.educationAndCertification) {
        if (edu.shortCode) {
          shortCodes.push(edu.shortCode);
        }
      }
    }
    return (
      <div
        className={`trainerCard
          ${this.state.isFlipped
            ? "trainerCardRadius opacityZero"
            : "trainerCardRadius"}
            ${this.state.isMobile && (this.state.expand ?"heightInitial":  "trainerCardDefaultHeight")}`
        }
      >
        <div className="trainerCardContentContainer">
          <div className="trainerProfileTopContainer" onClick={this.onClickArrowIcon}>
            <Avatar
              alt={trainerData.name}
              src={trainerData.picURL}
              className="trainerProfilePic"
            />
            <div className="trainerMainDetails">
              <div id="trainerCardNameContainer" >
              <Typography variant="h6" style={{ fontWeight: "800" }}>
                {trainerData.name}
              </Typography>
              {this.state.isMobile && <IconButton color="primary" aria-label="expand-more" size="small">
                {this.state.expand ? <ExpandLessIcon color="primary" />: <ExpandMoreIcon color="primary" />}
              </IconButton>}
              </div>
              <div className="displayRow" style={{ marginBottom: 4 }}>
                {Boolean(shortCodes && shortCodes.length) &&
                  shortCodes.map((code, index) => {
                    return (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={code}
                        size="small"
                        className="trainerChip"
                      />
                    );
                  })}
              </div>
              <Typography
                variant="caption"
                style={{ color: "grey", marginBottom: 8 }}
                className={!this.state.expand ? "displayNone": ""}
              >
                {trainerData.valueProposition}
              </Typography>
            </div>
          </div>
          <div className={`${this.state.isMobile && !this.state.expand && "displayNone"}`}>
              {trainerData.experience && 
                  this.renderTrainerInfo("Work Experience", `${new Date().getFullYear() - parseInt(trainerData.experience)} Years`)
              }
              {this.renderTrainerInfo("Bio", trainerData.bio)}
            </div>
          <div onClick={this.onClickEducationAndCertification}>
              {trainerData.documents &&
                trainerData.documents.educationAndCertification &&
                this.renderTrainerInfo(
                  "Education & Certifications",
                  trainerData.documents.educationAndCertification,
                  trainerData.verified
                )}
            </div>
          </div>
          <div className={`trainerProfileButtons ${this.state.isMobile && !this.state.expand && "displayNone"}`}>
            <Button
              variant="outlined"
              color="primary"
              className="mg-tp-16 onBoardButton"
              size={"small"}
              onClick={() => this.showPrograms()}
            >
              View Offered Services
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="mg-tp-16 onBoardButton"
              size={"small"}
              onClick={() => this.props.selectTrainer(trainerData)}
            >
              Contact
            </Button>
          </div>
          </div>

    );
  };

  render() {
    return (
      <div className={`flipCardContainer  ${this.state.isMobile && (this.state.expand ?"heightInitial":  "flipCardDefaultHeight")}`}>
        <div id={`trainerCard-${this.props.trainerData.id}`}>
          <ReactCardFlip
            isFlipped={this.state.isFlipped}
            flipDirection="horizontal"
          >
            {this.renderTrainerCard(this.props.trainerData)}
            <TrainerPrograms
              onClose={this.hidePrograms}
              isActive={this.state.isFlipped}
              selectedTrainer={this.props.trainerData}
              selectTrainer={this.props.selectTrainer}
            />
          </ReactCardFlip>
        </div>
      </div>
    );
  }
}
