import {CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ReactPlayer from "react-player";
import EditExerciseDetail from "../editExerciseDetail/editExerciseDetail";
import ExerciseFeedback from "../exerciseFeedback/exerciseFeedback";

const FEEDBACK_DATA = {
    selectedDifficulty: "", painLevel: 0, feedbackText: "",
};
export default class ExerciseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: Object.assign({}, this.props.data),
            feedbackData: FEEDBACK_DATA,
            showLoading: false,
            isComplete: Boolean(this.props.workoutLog?.[this.props.selectedExerciseLogIndex]),
        };
        this.exerciseDetailRef = React.createRef();
        this.feedbackRef = React.createRef();
    }


    componentDidMount() {
        if (this.props?.feedbackLog !== undefined) {
            let feedbackData = {
                selectedDifficulty: this.props?.feedbackLog?.difficulty || "",
                painLevel: this.props?.feedbackLog?.painLevel || 0,
                feedbackText: this.props?.feedbackLog?.feedbackText || "",
            };
            let data = this.props.data && Object.assign({}, this.props.data);
            this.setState({
                feedbackData: feedbackData,
                showLoading: false,
                data: data,
                isComplete: Boolean(this.props.workoutLog?.[this.props.selectedExerciseLogIndex]),
            }, () => {
                this.playerRef && this.playerRef.showPreview();
            });
            this.feedbackRef.current && this.feedbackRef.current.reset(feedbackData);
        }


    }


    componentDidUpdate(prevProps) {

        if (prevProps !== this.props) {
            if (prevProps?.data !== this.props?.data || prevProps?.data.id !== this.props?.data?.id || prevProps?.feedbackLog?.selectedDifficulty !== this.props?.feedbackLog?.selectedDifficulty || prevProps?.feedbackLog?.painLevel !== this.props?.feedbackLog?.painLevel || prevProps?.feedbackLog?.feedbackText !== this.props?.feedbackLog?.feedbackText) {
                let feedbackData = {
                    selectedDifficulty: this.props?.feedbackLog?.difficulty || "",
                    painLevel: this.props?.feedbackLog?.painLevel || 0,
                    feedbackText: this.props?.feedbackLog?.feedbackText || "",
                };
                let data = this.props.data && Object.assign({}, this.props.data);
                this.setState({
                    feedbackData: feedbackData,
                    showLoading: false,
                    data: data,
                    isComplete: Boolean(this.props.workoutLog?.[this.props.selectedExerciseLogIndex]),
                }, () => {
                    this.playerRef && this.playerRef.showPreview();
                });
                this.feedbackRef.current && this.feedbackRef.current.reset(feedbackData);
            }
        }


    }

    changeInExerciseDetail = (exerciseDetail) => {

        this.setState({
            data: exerciseDetail,
        });
    };

    onDonePress = (removeLog = false) => {
        let params = this.prepareParameters();
        params.exercise.log.difficulty = this.state.feedbackData.selectedDifficulty;
        params.exercise.log.painLevel = this.state.feedbackData.painLevel;
        params.exercise.log.feedbackText = this.state.feedbackData.feedbackText;
        if (removeLog === true) {
            this.props.onRemoveLog(params.exercise, params.data);
            return;
        }
        this.props.onDonePress(params.exercise, params.data);
    };

    prepareParameters() {
        let exercise = {};
        exercise.logId = this.props.logId;
        exercise.name = this.props.data.name;
        exercise.index = this.props.index;
        exercise.log = {};
        let details = this.exerciseDetailRef.current.getDetails();
        exercise.log.sets = details.sets || "";
        exercise.log.reps = details.reps || "";
        exercise.log.time = details.time || "";
        exercise.log.weight = details.weight || "";
        exercise.log.resistance = details.resistance || "";
        return {exercise, data: this.props.data};
    }

    renderExerciseCard() {
        if (!this.state.data) {
            return null;
        }
        return (<Card id="exerciseCardMainContainer">
            <div className="displayFlex padding0">
                {this.renderCardMedia()}
                {this.renderCardContent()}
            </div>
        </Card>);
    }

    renderDoneButton() {
        return (<div id="doneButton">
            <Button
                color="primary"
                fullWidth={true}
                variant="contained"
                onClick={() => this.onDonePress(this.state.isComplete)}
                disabled={this.props.disabled}
            >
                {this.state.isComplete ? "Remove Log" : "Save Log"}
            </Button>
        </div>);
    }

    onChangeFeedbackData = (key, value) => {
        let stateFeedbackData = Object.assign({}, this.state.feedbackData);
        stateFeedbackData[key] = value;
        this.setState({
            feedbackData: stateFeedbackData,
        });
    };

    renderExerciseFeedback() {
        return (<ExerciseFeedback
            ref={this.feedbackRef}
            data={this.state.feedbackData}
            onChangeDifficultyLevel={this.onChangeFeedbackData}
            onChangeFeedbackText={this.onChangeFeedbackData}
            onChangePainSlider={this.onChangeFeedbackData}
            disabled={this.props.disabled}
        />);
    }

    renderLoading = () => {
        if (this.state.showLoading) {
            return (<div style={{position: "absolute"}}>
                <CircularProgress/>
            </div>);
        }
    };

    getPosterUrl = () => {
        if (this.state.data.posterURL) {
            return `url(${this.state.data.posterURL})`;
        }
    };

    getPosterStyle = () => {
        return {
            backgroundImage: this.getPosterUrl(),
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        };
    };

    renderNoVideoPlaceholder = () => {
        let exerciseCardContent = this.state.data;
        if (!exerciseCardContent.posterURL && !exerciseCardContent.videoURL) {
            return (<div className="noVideoPlaceholder">
                <Typography variant="h4">No video</Typography>
            </div>);
        }
    };

    renderCardMedia() {
        return (<div
            id="mainVideoContainer"
            className={this.state.showLoading ? "mainVideoLoaderContainer" : ""}
        >
            {this.renderLoading()}
            {this.renderVideoPlayer()}
            {this.renderNoVideoPlaceholder()}
        </div>);
    }

    renderVideoPlayer = () => {
        let exerciseCardContent = this.state.data;
        if (exerciseCardContent.posterURL || exerciseCardContent.videoURL) {
            return (<ReactPlayer
                style={!exerciseCardContent.videoURL ? this.getPosterStyle() : null}
                id="mainVideoPlayer"
                ref={(ref) => {
                    this.playerRef = ref;
                }}
                onPlay={() => {
                    this.setState({showLoading: false});
                }}
                onClickPreview={() => this.setState({showLoading: true})}
                autoPlay={true}
                playing={true}
                playsinline={false}
                muted={true}
                loop={true}
                controls={false}
                url={exerciseCardContent.videoURL}
                width={500}
                height={500}
                light={exerciseCardContent.posterURL || true}
            />);
        }
    };

    renderCardContent() {
        let exerciseCardContent = this.state.data;

        return (<CardContent
            id="exerciseCardContentDetail"
            className="padding0 pd-right-16"
        >
            <Typography className="inputLabelBold" variant="h6">
                {exerciseCardContent.name}
            </Typography>
            <div id="levelAndTimerContainer">
                <Typography
                    className="marginBottom12 inputLabelBold"
                    variant="body2"
                    color="primary"
                >
                    Level {exerciseCardContent.level}
                </Typography>
            </div>

            <EditExerciseDetail
                ref={this.exerciseDetailRef}
                exerciseDetail={this.props.data}
                changeInExerciseDetail={this.changeInExerciseDetail}
                disabled={this.props.disabled}
            />
            {this.renderExerciseFeedback()}
            {this.renderDoneButton()}
        </CardContent>);
    }

    render() {
        return <React.Fragment>{this.renderExerciseCard()}</React.Fragment>;
    }
}
