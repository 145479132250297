import React from "react";
import ExerciseCard from "../exerciseCard/exerciseCard";
import WorkoutSection from "./workoutSection";
import {Dialog} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircle from "@material-ui/icons/CheckCircle";

export default class SectionedWorkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedExercise: null,
            selectedExerciseIndex: null,
            selectedSectionIndex: null,
            openExerciseCard: false,
            openExerciseDetailModal: false,
            showAddNote: false,
            selectedExerciseLogIndex: null,
            nextBtnStatus: false,
            previousBtnStatus: true,
            circuitChange: {status: false, currentSec: "", prevSec: ""},
            modalTop: "",
            modalBottom: "",
        };
    }

    componentDidMount = () => {
        this.setState({
            selectedExercise: this.props.workoutSection[0].data[0].exercise,
            selectedExerciseIndex: 0,
            selectedSectionIndex: 0,
            logId: this.props.workoutSection[0].data[0].logId,
            selectedExerciseLogIndex: this.props.workoutSection[0].data[0].index,
        });
        this.props.scrollToVideoPlayer();
    };

    componentDidUpdate = (prevProps) => {
        if (Number(this.state.selectedSectionIndex) === 0 && Number(this.state.selectedExerciseIndex) === 0 && (prevProps.workoutLog !== this.props.workoutLog || prevProps.workoutLog.sets !== this.props.workoutLog.sets || prevProps.workoutLog.reps !== this.props.workoutLog.reps || prevProps.workoutLog.weight !== this.props.workoutLog.weight || prevProps.workoutLog.time !== this.props.workoutLog.time)) {
            this.setState({
                selectedExercise: this.props.workoutSection[0].data[0].exercise,
                selectedExerciseIndex: 0,
                selectedSectionIndex: 0,
                logId: this.props.workoutSection[0].data[0].logId,
                selectedExerciseLogIndex: this.props.workoutSection[0].data[0].index,
            });
            this.props.scrollToVideoPlayer();
        }
        if (this.props.showCompleteModal && this.state.circuitChange.status) {
            this.setState({
                circuitChange: {status: false}, openExerciseDetailModal: false,
            });
        }
    };

    onClickExerciseMiniCard = (exercise, sectionIndex, exerciseIndex, logId, logIndex) => {
        if (sectionIndex === 0 && exerciseIndex === 0) {
            this.setState({
                previousBtnStatus: true, nextBtnStatus: false, circuitChange: {status: false},
            });
        } else {
            this.setState({
                previousBtnStatus: false, nextBtnStatus: false, circuitChange: {status: false},
            });
        }

        this.setState({
            selectedExercise: exercise,
            selectedSectionIndex: sectionIndex,
            selectedExerciseIndex: exerciseIndex,
            openExerciseCard: true,
            openExerciseDetailModal: true,
            logId: logId,
            selectedExerciseLogIndex: logIndex,
        });
        this.props.scrollToVideoPlayer();
    };

    onClickCloseExerciseCard = () => {
        this.setState({
            openExerciseCard: false,
        });
    };

    onClickExerciseDetail = () => {
        this.setState({
            openExerciseDetailModal: true,
        });
    };

    updateSelectedExerciseFromParent = (sectionIndex, exerciseIndex, logIndex) => {
        if (!this.props.workoutSection[sectionIndex]) {
            return;
        }
        if (!this.props.workoutSection[sectionIndex].data[exerciseIndex]) {
            return;
        }
        let exercise = this.props.workoutSection[sectionIndex].data[exerciseIndex].exercise;
        let logId = this.props.workoutSection[sectionIndex].data[exerciseIndex].logId;
        this.setState({
            previousBtnStatus: false, modalTop: sectionIndex, modalBottom: sectionIndex,
        });
        this.setState({
            selectedExercise: exercise,
            selectedExerciseIndex: exerciseIndex,
            selectedSectionIndex: sectionIndex,
            logId: logId,
            selectedExerciseLogIndex: logIndex,
        });
    };

    getFeedbackLog = () => {

        let split = this.state?.logId?.split("_");
        if (split) {
            let index = split[3];

            if (split && this.props?.workoutLog[index]) {
                return this.props?.workoutLog[index]?.feedback;
            }
        } else {
            return {};
        }
    };

    onPressNext(sectionIndex, exerciseIndex) {
        let workouts = this.props.workoutSection;
        if (workouts[sectionIndex].data.length - 1 === exerciseIndex && sectionIndex !== workouts.length - 1 && !this.state.circuitChange.status) {
            this.setState({
                previousBtnStatus: false,
                circuitChange: {status: true},
                openExerciseDetailModal: true,
                modalTop: sectionIndex,
                modalBottom: sectionIndex + 1,
            });
            this.props.addSectionLog(workouts[sectionIndex]);
            // this.workoutSectionRef.current.markExercises();
        } else if (this.state.circuitChange.status) {
            this.setState({
                selectedExercise: workouts[sectionIndex + 1].data[0].exercise,
                selectedSectionIndex: sectionIndex + 1,
                selectedExerciseIndex: 0,
                selectedExerciseLogIndex: workouts[sectionIndex + 1].data[0].index,
                logId: workouts[sectionIndex + 1].data[0].logId,
                circuitChange: {status: false},
            });
        } else if (workouts[sectionIndex].data.length - 1 === exerciseIndex && sectionIndex === workouts.length - 1 && !this.state.circuitChange.status) {
            this.setState({
                circuitChange: {status: true}, previousBtnStatus: false, nextBtnStatus: true, modalTop: sectionIndex,
            });

            this.props.addSectionLog(workouts[sectionIndex]);
        } else {
            if (this.state.selectedExerciseIndex + 1 !== 0) {
                this.setState({
                    previousBtnStatus: false, nextBtnStatus: false,
                });
            }
            this.setState({
                selectedExercise: workouts[sectionIndex].data[exerciseIndex + 1].exercise,
                selectedSectionIndex: sectionIndex,
                selectedExerciseIndex: exerciseIndex + 1,
                selectedExerciseLogIndex: workouts[sectionIndex].data[exerciseIndex + 1].index,
                logId: workouts[sectionIndex].data[exerciseIndex + 1].logId,
            });
        }
        if (this.props.workoutComplete) {
            this.setState({
                circuitChange: {status: false},
            });
        }
    }

    onPressPrevious(sectionIndex, exerciseIndex) {
        let workouts = this.props.workoutSection;

        if (sectionIndex === 0 && exerciseIndex === 0) {

            this.setState({
                previousBtnStatus: true, nextBtnStatus: false,
            });
        } else {

            this.setState({
                previousBtnStatus: false, nextBtnStatus: false,
            });
        }

        if (exerciseIndex === 0 && sectionIndex !== 0 && !this.state.circuitChange.status) {

            let index = workouts[sectionIndex - 1].data?.length - 1;

            this.setState({
                selectedExercise: workouts[sectionIndex - 1].data[index].exercise,
                selectedSectionIndex: sectionIndex - 1,
                selectedExerciseLogIndex: workouts[sectionIndex - 1].data[index].index,
                selectedExerciseIndex: index,
                logId: workouts[sectionIndex - 1].data[index].logId,
                circuitChange: {status: true}, modalTop: sectionIndex - 1, modalBottom: sectionIndex,
            });
        } else if (this.state.circuitChange.status) {

            this.setState({
                selectedExercise: workouts[sectionIndex].data[exerciseIndex].exercise,
                selectedSectionIndex: sectionIndex,
                selectedExerciseIndex: exerciseIndex,
                selectedExerciseLogIndex: workouts[sectionIndex].data[exerciseIndex].index,
                logId: workouts[sectionIndex].data[exerciseIndex].logId,
                circuitChange: {status: false},
            });
        } else {

            if (sectionIndex === 0 && exerciseIndex - 1 === 0) {
                this.setState({
                    previousBtnStatus: true, nextBtnStatus: false,
                });
            }

            this.setState({
                selectedExercise: workouts[sectionIndex].data[exerciseIndex - 1].exercise,
                selectedSectionIndex: sectionIndex,
                selectedExerciseIndex: exerciseIndex - 1,
                selectedExerciseLogIndex: workouts[sectionIndex].data[exerciseIndex - 1].index,
                logId: workouts[sectionIndex].data[exerciseIndex - 1].logId,
            });
        }

    }

    renderForward(sectionIndex, exerciseIndex) {
        return (<IconButton
            className="modalArrow"
            size="large"
            onClick={() => this.onPressNext(sectionIndex, exerciseIndex)}
            disabled={this.state.nextBtnStatus}
        >
            <ArrowForwardIosIcon color={"primary"} fontSize="large"/>
        </IconButton>);
    }

    renderBackward(sectionIndex, exerciseIndex) {
        return (<IconButton
            className="modalArrow"
            size="large"
            onClick={() => this.onPressPrevious(sectionIndex, exerciseIndex)}
            disabled={this.state.previousBtnStatus}
        >
            <ArrowBackIosIcon color={"primary"} fontSize="large"/>
        </IconButton>);
    }

    renderPrevCircuitName() {
        let workouts = this.props.workoutSection;
        if (workouts[this.state.modalTop].workoutType === "Circuit") {
            return (<h2>
                {workouts[this.state.modalTop].workoutType}{" "}
                {workouts[this.state.modalTop].index}
            </h2>);
        } else {
            return <h2>{workouts[this.state.modalTop].workoutType} </h2>;
        }
    }

    onClickCloseEditExerciseModal = () => {
        if (this.state.selectedExerciseIndex === 0 && this.state.selectedSectionIndex === 0) {
            this.setState({
                openExerciseDetailModal: false,
                previousBtnStatus: true,
                selectedExercise: this.props.workoutSection[this.state.selectedSectionIndex].data[0].exercise,
                selectedExerciseIndex: this.state.selectedExerciseIndex,
                selectedSectionIndex: this.state.selectedSectionIndex,
                logId: this.props.workoutSection[this.state.selectedSectionIndex].data[0].logId,
                selectedExerciseLogIndex: this.props.workoutSection[this.state.selectedSectionIndex].data[0].index,
                circuitChange: {status: false},
            });
        } else {
            this.setState({
                openExerciseDetailModal: false,
                selectedExercise: this.props.workoutSection[this.state.selectedSectionIndex].data[0].exercise,
                selectedExerciseIndex: this.state.selectedExerciseIndex,
                selectedSectionIndex: this.state.selectedSectionIndex,
                logId: this.props.workoutSection[this.state.selectedSectionIndex].data[0].logId,
                selectedExerciseLogIndex: this.props.workoutSection[this.state.selectedSectionIndex].data[0].index,
                circuitChange: {status: false},
            });
        }
    };

    renderCircuitModal() {
        let workouts = this.props.workoutSection;
        return (<>
            <div className="modalBtns">
                {" "}
                {this.renderBackward(this.state.selectedSectionIndex, this.state.selectedExerciseIndex)}
                {this.renderForward(this.state.selectedSectionIndex, this.state.selectedExerciseIndex)}
            </div>
            <div className="clienCircuitModal">
                <div className="circuitCont ">
                    {this.renderPrevCircuitName()}
                    <h2>Complete</h2>
                    <CheckCircle color={"white"} fontSize="large"/>
                    {workouts.length - 1 === this.state.selectedSectionIndex ? null : (<div className="nextUp">
                        <p>Nextup:</p>
                        {workouts[this.state.modalBottom].workoutType === "Circuit" ? (<p>
                            {workouts[this.state.modalBottom].workoutType}{" "}
                            {workouts[this.state.modalBottom].index}
                        </p>) : (<p>{workouts[this.state.modalBottom].workoutType}</p>)}
                    </div>)}

                    <button
                        onClick={this.onClickCloseEditExerciseModal}
                        className="closeModalBtn"
                    >
                        Close
                    </button>
                </div>
            </div>
        </>);
    }

    renderSelectedExerciseCard() {
        return (<>
            <div className="modalBtns">
                {" "}
                {this.renderBackward(this.state.selectedSectionIndex, this.state.selectedExerciseIndex)}
                {this.renderForward(this.state.selectedSectionIndex, this.state.selectedExerciseIndex)}
            </div>
            <ExerciseCard
                data={this.state.selectedExercise}
                onDonePress={this.props.onDonePress}
                logId={this.state.logId}
                feedbackLog={this.getFeedbackLog()}
                disabled={!this.props.isEnabled}
                onRemoveLog={this.props.onRemoveLog}
                selectedExerciseLogIndex={this.state.selectedExerciseLogIndex}
                workoutLog={this.props.workoutLog}
            />

            {/*</>)}*/}
        </>);
    }

    render() {
        let workout = this.props.workoutSection;

        return (<div id="homePage">
            <div id="sectionedWorkout" className="sectionedWorkout">
                {workout && workout.length > 0 && workout.map((section, index) => {
                    return (<WorkoutSection
                        key={index}
                        section={section}
                        listIndex={index}
                        firstSection={workout[0]}
                        selectedExercise={this.state.selectedExercise}
                        selectedExerciseIndex={this.state.selectedExerciseIndex}
                        selectedSectionIndex={this.state.selectedSectionIndex}
                        onRemoveLog={this.props.onRemoveLog}
                        getEnabledFields={this.getEnabledFields}
                        onClickExerciseMiniCard={this.onClickExerciseMiniCard}
                        onDonePress={this.props.onDonePress}
                        currentDayUnix={this.props.currentDayUnix}
                        todayUnix={this.props.todayUnix}
                        workoutLog={this.props.workoutLog}
                        addSectionLog={this.props.addSectionLog}
                        removeSectionLog={this.props.removeSectionLog}
                        disabled={!this.props.isEnabled}
                    />);
                })}
            </div>
            <div id="homePageDetailContainer" className="circuitModalWrap">
                {this.state.circuitChange.status && this.state.openExerciseDetailModal ? this.renderCircuitModal() : this.state.selectedExercise ? this.renderSelectedExerciseCard() : null}
            </div>
        </div>);
    }
}
