import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import ProfileEditor from './profileEditor';

class ProfileModal extends React.Component {
    render() {
        return (
            <Dialog open={this.props.open} fullScreen={this.props.fullScreen} onClose={this.props.onClose} aria-labelledby="profileEditorTitle">
                <DialogTitle id="profileEditorTitle">Edit Profile</DialogTitle>
                <ProfileEditor {...this.props} />
            </Dialog>
        );
    }
}

export default ProfileModal;