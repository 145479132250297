import {
    ANALYTIC_EVENTS,
    COLLECTIONS,
    CONTINUE_URL,
    DB_KEYS,
    GOAL_STATUS,
    INITIALIZE_WALKTHROUGH,
    ROLES,
    USER_STATES,
    WORKOUT_SECTIONS_NAMES,
} from "../constants";

import hash from "object-hash";
import moment from "moment";

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    appId: process.env.REACT_APP_APP_ID,
};

window.firebase.initializeApp(config);
const firebase = window.firebase;
const auth = firebase.auth();
const datastore = firebase.firestore();
const functions = firebase.functions();
const analytics = firebase.analytics();
// export const messaging = firebase.messaging();
const {REACT_APP_VAPID_KEY} = process.env;
const publicKey = REACT_APP_VAPID_KEY;
var currentUserData;
//Using Emulator

// functions.useFunctionsEmulator("http://localhost:5001");
const createNewClientFN = functions.httpsCallable("createClientAccount");
const sendInviteFN = functions.httpsCallable("resendInvite");
const removeClientFN = functions.httpsCallable("removeClient");
const requestExerciseFN = functions.httpsCallable("requestExercise");
const approveTrainerRequestFN = functions.httpsCallable("approveTrainerRequest");
const declineTrainerRequestFN = functions.httpsCallable("declineTrainerRequest");
const approveClientRequestFN = functions.httpsCallable("approveClientRequest");
const rejectClientRequestFN = functions.httpsCallable("rejectClientRequest");
const showTrainerFN = functions.httpsCallable("showTrainer");
const hideTrainerFN = functions.httpsCallable("hideTrainer");
const clientEmailVerificationFN = functions.httpsCallable("clientEmailVerification");
const sendMessageFN = functions.httpsCallable("sendMessage");
const notifyTrainerVerification = functions.httpsCallable("onTrainerVerify");
const getTrainerListFN = functions.httpsCallable("getTrainerList");
const createClientAccountWithoutInviteFN = functions.httpsCallable("createClientAccountWithoutInvite");
const createClientRequestFN = functions.httpsCallable("createClientRequest");
const confirmClientInviteFN = functions.httpsCallable("confirmClientInvite");
const getTrainerForInviteFN = functions.httpsCallable("getTrainerForInvite");
const submitEnquiryFN = functions.httpsCallable("submitEnquiry");
const getChallengesFN = functions.httpsCallable("getChallenges");
const tryChallengeFN = functions.httpsCallable("tryChallenge");
const startChallengeFN = functions.httpsCallable("startChallenge");
const createNewProgramWithWorkoutFN = functions.httpsCallable("createNewProgramAndAddWorkout");
const addWorkoutToExistingProgramFN = functions.httpsCallable("addWorkoutToExistingProgram");
const sendOtpFN = functions.httpsCallable("sendOtp");
const verifyOtpFN = functions.httpsCallable("verifyOtp");

function createUserWithEmailAndPassword(email, password) {
    analytics.logEvent(ANALYTIC_EVENTS.SIGNUP);
    return auth.createUserWithEmailAndPassword(email, password);
}


// async function pushNotificationApi(name, text, currentToken) {
//     console.log(currentToken, "currenttoken");
//     let API_URL = `https://fcm.googleapis.com/fcm/send`;
//     let notification = {
//         to: currentToken,
//         link: "https://dashboard.fortisforma.com/#/message-center/",
//         notification: {
//             title: name, body: text
//         }, webpush: {
//             headers: {
//                 Urgency: "high"
//             },
//             fcm_options: {
//                 "link": "https://dummypage.com"
//             },
//             notification: {
//                 title: name,
//                 body: text,
//                 requireInteraction: "true",
//                 // "badge": "/badge-icon.png"
//             }
//         },
//
//     };
//     try {
//         let response = fetch(API_URL, {
//             method: "POST", headers: {
//                 Authorization: `key=${process.env.REACT_APP_WEB_PUSH_KEY}`,
//                 "Content-Type": "application/json"
//             }, body: JSON.stringify(notification)
//         });
//
//
//     } catch (e) {
//         throw e;
//     }
// }

// messaging.onMessage((payload) => {
//     var obj = JSON.parse(payload.notification);
//     var notification = new Notification(obj.title, {
//         body: obj.body,
//     });
// });
// export const onMessageListener = () => {
//     console.log("hellox,Umsn");
//     messaging.onMessage((payload) => {
//         const notificationTitle = payload.notification.title;
//         const notificationOptions = {
//             body: payload.notification.body,
//             icon: payload.notification.icon
//         };
//         ServiceWorkerRegistration.showNotification(notificationTitle, notificationOptions);
//         new Notification(notificationTitle, notificationOptions);
//     });
// };

// // Request permission to receive notifications
// messaging.requestPermission().then((response) => {
//     console.log('Notification permission granted.', response);
//     // get token
//     return messaging.getToken();
// }).then(token => {
//     console.log('Device token:', token);
//     // Send the token to your server so it can send notifications to this device
//     // ...
//     // Set up a listener for incoming notifications
//
// })
//     .catch(error => {
//         console.log('Unable to get permission to notify.', error);
//     });
// export const askForPermissionToReceiveNotifications = async (registration) => {
//
//     try {
//
//         // const registration = await navigator.serviceWorker
//         //     .register("../../public/firebase-message-sw.js", {scope: "/", updateViaCache: "none"})
//         //     .then((registration) => {
//         //         return registration;
//         //     }).catch(e => {
//         //     });
//         const permission = await Notification.requestPermission();
//         if (permission === "granted") {
//             if (localStorage.getItem("clientNotiToken")) {
//                 console.log("in if")
//             } else {
//                 console.log("in else")
//                 const token = await messaging.getToken({
//                     vapidKey: publicKey,
//                     // serviceWorkerRegistration: registration
//                 });
//                 window.localStorage.setItem("clientNotiToken", token);
//             }
//         }
//
//
//     } catch (error) {
//         console.error(error);
//     }
// };
// export const setUserPushNotificationToken = (user) => {
//     if (user) {
//         console.log(user, "users");
//         const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.id);
//         let unique = []
//
//         if (user?.notificationToken?.length > 0 && localStorage.getItem("clientNotiToken") !== null) {
//             unique.push(...user?.notificationToken, localStorage.getItem("clientNotiToken"))
//
//         } else {
//
//             unique.push(localStorage.getItem("clientNotiToken"));
//
//         }
//         let notificationToken = unique.filter((v, i, a) => a.indexOf(v) === i);
//         try {
//             ref.set({notificationToken}, {merge: true});
//         } catch (e) {
//             throw e;
//         }
//     }
// }
// export const sendMessageofNotification = async ({name, text}, user) => {
//     console.log(user, "user")
//     if (user.notificationToken) {
//         await pushNotificationApi(name, text, user.notificationToken);
//
//     }
//
// };

async function storeUserProfile(profile) {
    if (!profile.id) {
        let user = currentUser();
        let id = user.uid;
        profile.id = id;
    }
    let clientId = profile.id;
    try {
        let ref = datastore
            .collection(profile.pendingLogin ? COLLECTIONS.INVITES : COLLECTIONS.USER_DATA)
            .doc(profile.id);
        if (profile.role === ROLES.CLIENT && !clientId) {
            analytics.logEvent(ANALYTIC_EVENTS.NEW_CLIENT);
        }
        let results = await ref.set(profile, {merge: true});
        return results;
    } catch (e) {
        throw e;
    }
}

export async function addUserMessages(values, user, trainer, msgId) {
    let date = new Date().getTime();
    try {
        const messagesRef = datastore.collection(COLLECTIONS.MESSAGE);
        let obj = {
            _id: msgId,
            text: values,
            createdAt: date,
            clientId: user.id,
            trainerId: trainer.id,
            sender: user.role,
            trainer_read_status: false,
            receiver: trainer.role,
            user: {
                _id: user.id,
            },
        };
        let result = await messagesRef.add(obj);
        return Promise.resolve(result);
    } catch (e) {
        console.log(e, "error in the console");
        throw e;
    }
}

export async function currentUserMessages(trainerId, clientId) {
    let data = [];
    return new Promise(async (resolve, reject) => {
        try {
            if (trainerId && clientId) {
                datastore
                    .collection(COLLECTIONS.MESSAGE)
                    .where("trainerId", "==", trainerId)
                    .where("clientId", "==", clientId)

                    .get().then((doc) => {
                    doc.docs.map((item) => {
                        data.push(item.data());
                    });
                    resolve(data);
                });

                // datastore
                //     .collection(COLLECTIONS.MESSAGE)
                //     .where('trainerId', '==', trainerId)
                //     .where('clientId', '==', clientId)
                //     .where("client_read_status", '==', false)
                //     .get().then((doc) => {
                //     doc.docs.map((item) => {
                //         let cityRef = datastore
                //             .collection(COLLECTIONS.MESSAGE).doc(item.id);
                //         cityRef.set({
                //             client_read_status: true
                //         }, {merge: true});
                //     });
                //
                // });
            }
        } catch (error) {
            reject(error);
        }
    });
}

export async function currentUserMessagesCount(trainerId, clientId) {
    let count = 0;
    return new Promise(async (resolve, reject) => {
        try {
            if (trainerId && clientId) {
                datastore
                    .collection(COLLECTIONS.MESSAGE)
                    .where('trainerId', '==', trainerId)
                    .where('clientId', '==', clientId)
                    .where("client_read_status", '==', false)
                    .onSnapshot((doc) => {
                        // let source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                        // eslint-disable-next-line array-callback-return
                        count = doc.docs.length;
                        resolve(count);
                    });
            }
        } catch (error) {
            reject(error);
        }
    });
}

async function getProgramHistory(programId) {
    try {
        let ref = datastore.collection(COLLECTIONS.PROGRAM_HISTORY).doc(programId);
        let results = await ref.get();
        if (results.exists) {
            return results.data();
        }
        // wait for 2 sec for cloud function to create program history then refetch.
        await new Promise((resolve) => setTimeout(() => resolve(true), 2000));
        ref = datastore.collection(COLLECTIONS.PROGRAM_HISTORY).doc(programId);
        results = await ref.get();
        if (results.exists) {
            return results.data();
        }
        throw new Error(`Program history not found for programId: ${programId}`);
    } catch (e) {
        throw e;
    }
}

function signInWithEmailAndPassword(email, password) {
    analytics.logEvent(ANALYTIC_EVENTS.LOGIN);
    return auth.signInWithEmailAndPassword(email, password);
}

function sendPasswordResetEmail(email, actionCodeSettings) {
    return auth.sendPasswordResetEmail(email, actionCodeSettings);
}

function updatePassword(password) {
    return auth.currentUser.updatePassword(password);
}

function signOut() {
    token = null;
    currentUserData = null;
    return auth.signOut();
}

function getProgram(id) {
    const ref = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM).doc(id);

    return new Promise(async (resolve, reject) => {
        try {
            const program = await ref.get();
            if (!program.exists) {
                let error = {
                    code: 404, message: "program not found",
                };
                throw error;
            }
            let programData = program.data();
            resolve(programData);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}

async function getCustomClaims() {
    if (!auth.currentUser) {
        throw new Error("Current user is null");
    }

    const idTokenResult = await auth.currentUser.getIdTokenResult(true);

    if (!idTokenResult || !idTokenResult.claims) {
        throw new Error("Claims not found");
    }

    return idTokenResult.claims;
}

async function updateUserLoginTime() {
    if (!auth.currentUser) {
        throw new Error("Current user is null");
    }
    let user = currentUser();
    let id = user.uid;
    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
    let login = {};
    login[DB_KEYS.LAST_LOGIN_KEY] = window.firebase.firestore.Timestamp.fromDate(new Date());
    try {
        await ref.set(login, {merge: true});
    } catch (e) {
        throw e;
    }
}

async function getSimilarExercises(groupId) {
    return queryData({
        collection: COLLECTIONS.EXERCISES, filters: [{
            key: DB_KEYS.GROUP_ID_KEY, operator: "==", value: groupId,
        }, {
            key: DB_KEYS.TRAINER_ID_KEY, operator: "==", value: "",
        },],
    });
}

async function getWorkouts(query) {
    let results = await queryData(query);
    for (let result of results) {
        if (result.exercises) {
            result.workoutSections = [{
                workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT, sets: 1, exercises: result.exercises,
            },];
            delete result.exercises;
        }
    }
    return results;
}

async function getPrograms(trainerId) {
    return queryData({
        collection: COLLECTIONS.WORKOUT_PROGRAM, filters: [{
            key: DB_KEYS.CREATOR_ID_KEY, operator: "==", value: trainerId,
        },],
    });
}

async function getClientsForProgram(trainerId, programId) {
    return queryData({
        collection: COLLECTIONS.USER_DATA, filters: [{
            key: DB_KEYS.ROLE, operator: "==", value: "Client",
        }, {
            key: DB_KEYS.TRAINER_ID_KEY, operator: "==", value: trainerId,
        }, {
            key: DB_KEYS.PROGRAM_ID_KEY, operator: "==", value: programId,
        },],
    });
}

function exists(item, array) {
    let filtered = array.filter((arrayItem) => {
        return arrayItem === item;
    });

    if (filtered && filtered.length > 0) {
        return true;
    }
    return false;
}

async function storeWorkout(workoutDay) {
    let user = currentUser();
    let id = user.uid;

    if (!workoutDay.createdTime) {
        workoutDay.createdTime = window.firebase.firestore.Timestamp.fromDate(new Date());
    }

    workoutDay.updatedTime = window.firebase.firestore.Timestamp.fromDate(new Date());

    if (!id) {
        throw new Error("User id missing");
    }
    let _exerciseIds = [];
    workoutDay.workoutSections = workoutDay.workoutSections && workoutDay.workoutSections.map((section) => {
        section.exercises.map((e) => {
            if (!exists(e.id, _exerciseIds)) {
                _exerciseIds.push(e.id);
            }
            return e;
        });
        return section;
    });
    workoutDay._exerciseIds = _exerciseIds;

    let workoutdayDocRef;

    if (workoutDay.id) {
        workoutdayDocRef = datastore
            .collection(COLLECTIONS.WORKOUT_DAYS)
            .doc(workoutDay.id);
    } else {
        analytics.logEvent(ANALYTIC_EVENTS.NEW_PROGRAM);
        workoutdayDocRef = datastore.collection(COLLECTIONS.WORKOUT_DAYS).doc();
    }

    const trainerDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);

    return new Promise(async (resolve, reject) => {
        datastore
            .runTransaction((transaction) => {
                return transaction.get(trainerDocRef).then(async (trainerDoc) => {
                    if (!trainerDoc.exists) {
                        throw new Error("Trainer data does not exist");
                    }
                    let op = workoutDay.id ? transaction.update.bind(transaction) : transaction.set.bind(transaction);
                    if (workoutDay.id) {
                        delete workoutDay.createdTime;
                    }
                    const workoutToSave = Object.assign({}, workoutDay, {
                        id: workoutdayDocRef.id,
                    });
                    const workoutSaveTask = op(workoutdayDocRef, workoutToSave);
                    let workoutDayCount = trainerDoc.data().workoutdayCount || 0;
                    if (!workoutDay.id) {
                        workoutDayCount++;
                    }
                    let trainerUpdateTask = transaction.update(trainerDocRef, {
                        workoutdayCount: workoutDayCount,
                    });

                    await Promise.all([workoutSaveTask, trainerUpdateTask]);
                    return Promise.resolve(workoutToSave);
                });
            })
            .then((results) => {
                results = workoutDay;
                results.id = workoutdayDocRef.id;
                resolve(results);
            })
            .catch((e) => {
                console.error(e);
                reject(e);
            });
    });
}

async function deleteProgram(programId, trainerId) {
    let programDocRef = datastore
        .collection(COLLECTIONS.WORKOUT_PROGRAM)
        .doc(programId);
    return datastore.runTransaction(async (transaction) => {
        await updateProgramCount(trainerId, transaction, -1);
        await transaction.delete(programDocRef);
    });
}

async function deleteWorkout(workoutId) {
    let user = currentUser();
    let id = user.uid;

    if (!id) {
        throw new Error("User id missing");
    }

    let workoutdayDocRef = datastore
        .collection(COLLECTIONS.WORKOUT_DAYS)
        .doc(workoutId);

    const trainerDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);

    return new Promise(async (resolve, reject) => {
        datastore
            .runTransaction((transaction) => {
                return transaction.get(trainerDocRef).then((trainerDoc) => {
                    if (!trainerDoc.exists) {
                        throw new Error("Trainer data does not exist");
                    }
                    const workoutDeleteTask = transaction.delete(workoutdayDocRef);
                    const newWorkoutdayCount = trainerDoc.data().workoutdayCount ? trainerDoc.data().workoutdayCount - 1 : 0;
                    const trainerUpdateTask = transaction.update(trainerDocRef, {
                        workoutdayCount: newWorkoutdayCount,
                    });

                    return Promise.all([workoutDeleteTask, trainerUpdateTask]);
                });
            })
            .then((results) => {
                resolve(results);
            })
            .catch((e) => {
                console.error(e);
                reject(e);
            });
    });
}

/*
Query: {
  pageConfig: {
    orders: [Order],
    limit: Number
  },
  collection: string,
  filters: [Filter],
  or: boolean (Is using or, all filters will be applied as different query and count towards quota)
}

Order: {
  key: string,
  direction: string, "desc" or empty for ascending by default
  after: any,
  before: any
}

Filter: {
    "key": string,
    "operator": refer https://firebase.google.com/docs/firestore/query-data/queries,
    "value": any
  }

Note: Filters are applied in order of array
*/
async function queryData(query) {
    console.info("Fetching", query.collection);
    if (query.or && query.filters && query.filters.length) {
        let exercises = [];

        for (let filter of query.filters) {
            let subQuery = Object.assign({}, query);
            subQuery.filters = [filter];

            try {
                let results = await _executeQuery(subQuery);
                exercises = exercises.concat(results);
            } catch (e) {
                throw e;
            }
        }

        exercises = window._.uniq(exercises, false, (e) => {
            return e.id;
        });
        return exercises;
    } else {
        return _executeQuery(query);
    }
}

async function _executeQuery(query) {
    const ref = datastore.collection(query.collection);
    let queryRef = ref;

    if (!query) {
        query = {};
    }

    if (query.filters) {
        for (let filter of query.filters) {
            queryRef = queryRef.where(filter.key, filter.operator, filter.value);
        }
    }

    if (query.pageConfig) {
        let config = query.pageConfig;
        if (config.orders) {
            let afters = [];
            let befores = [];

            for (let order of config.orders) {
                queryRef = queryRef.orderBy(order.key, order.direction);
                if (order.after) {
                    afters.push(order.after);
                }
                if (order.before) {
                    befores.push(order.before);
                }
            }

            if (afters.length) {
                queryRef = queryRef.startAfter(...afters);
            }
            if (befores.length) {
                queryRef = queryRef.endBefore(...befores);
            }
        }

        if (config.limit) {
            queryRef = queryRef.limit(config.limit);
        }
    }

    return new Promise(async (resolve, reject) => {
        try {
            let firebaseResults = await queryRef.get();
            let data = [];
            for (let doc of firebaseResults.docs) {
                let datum = doc.data();
                datum.id = doc.id;
                data.push(datum);
            }

            resolve(data);
        } catch (e) {
            reject(e);
        }
    });
}

function currentUser() {
    return firebase.auth().currentUser;
}

async function createNewClient(clientProfile) {
    try {
        let results = await createNewClientFN(clientProfile);
        return results;
    } catch (e) {
        throw e;
    }
}

async function removeClient(clientProfile) {
    try {
        let results = await removeClientFN(clientProfile);
        return results;
    } catch (e) {
        throw e;
    }
}

async function sendInvite(email, name) {
    try {
        let results = await sendInviteFN({
            email, displayName: name,
        });
        return results;
    } catch (e) {
        throw e;
    }
}

async function requestExercise(request) {
    try {
        let results = await requestExerciseFN(request);
        return results;
    } catch (e) {
        throw e;
    }
}

async function storeExercise(exercise) {
    return store(COLLECTIONS.EXERCISES, exercise);
}

/*
Config: {
  data: File data,
  storageRefPath: string,
  onProgress: Function(progress),
  onError: Function(error),
  onComplete: Function(downloadURL)
}
*/

function startUploadFileTask(config) {
    var storageRef = window.firebase.storage().ref();
    var dataRef = storageRef.child(config.storageRefPath);

    let uploadTask = dataRef.put(config.data);

    uploadTask.on("state_changed", function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        config.onProgress(progress);
    }, function (error) {
        config.onError(error);
    }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            config.onComplete(downloadURL);
        });
    });

    return uploadTask;
}

async function saveProgram(programData) {
    let user = currentUser();
    let id = user.uid;

    if (!id) {
        throw new Error("User id missing");
    }

    programData.creatorId = id;

    programData._workoutDayIds = Object.keys(programData.workoutDataMap || {});

    let programDayRef = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM).doc();
    if (programData.id) {
        programDayRef = datastore
            .collection(COLLECTIONS.WORKOUT_PROGRAM)
            .doc(programData.id);
    }

    let isUpdate = programData.id ? true : false;

    programData.id = programDayRef.id;

    return datastore.runTransaction(async (transaction) => {
        await _saveProgram({programData, programDayRef, trainerId: id, isUpdate}, transaction);
    });
}

async function _saveProgram(params, transaction) {
    let programData = params.programData;

    let programDayRef = params.programDayRef;

    // let change = 0;
    // if (!params.isUpdate) {
    //   change++;
    // }
    // await updateProgramCount(trainerId, transaction, change)
    await transaction.set(programDayRef, programData);
    programData.id = programDayRef.id;
}

async function updateProgramCount(trainerId, transaction, change = 0) {
    let trainerRef = datastore.collection(COLLECTIONS.USER_DATA).doc(trainerId);
    let trainerDataDoc = await transaction.get(trainerRef);
    if (!trainerDataDoc.exists) {
        throw new Error("Trainer does not exist");
    }
    let trainerData = trainerDataDoc.data();
    let programCount = trainerData.programCount || 0;
    programCount += change;
    await transaction.update(trainerRef, {programCount});
}

function assingProgramToClients(programId, clients) {
    let batch = datastore.batch();
    let clientDocRef;

    for (let id of Object.keys(clients)) {
        let state = clients[id];
        if (state.pendingLogin) {
            clientDocRef = datastore.collection(COLLECTIONS.INVITES).doc(id);
        } else {
            clientDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
        }
        batch.update(clientDocRef, {
            programId: programId,
        });
    }
    return batch.commit();
}

function removeProgramfromClients(clients) {
    let batch = datastore.batch();
    let clientDocRef;

    for (let id of Object.keys(clients)) {
        let state = clients[id];
        if (state.pendingLogin) {
            clientDocRef = datastore.collection(COLLECTIONS.INVITES).doc(id);
        } else {
            clientDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
        }
        batch.update(clientDocRef, {
            programId: "",
        });
    }
    return batch.commit();
}

async function addWorkoutDayToClientProgram(clientData, workoutDay, dayIndexes = []) {
    // TODO: remove workout id of previous day if no longer used
    let clientProgramId = clientData.programId;

    if (!clientProgramId) {
        let workoutDaysMap = {};
        for (let dayIndex of dayIndexes) {
            workoutDaysMap[dayIndex] = workoutDay.id;
        }
        let programData = {
            workoutDaysMap, workoutDataMap: {[workoutDay.id]: workoutDay},
        };
        await assignProgramToClient(clientData, programData, clientData.pendingLogin);
        analytics.logEvent(ANALYTIC_EVENTS.PROGRAM_ASSIGN);
    } else {
        let programUpdate = {
            _workoutDayIds: window.firebase.firestore.FieldValue.arrayUnion(workoutDay.id),
            [`workoutDataMap.${workoutDay.id}`]: workoutDay,
        };
        for (let dayIndex of dayIndexes) {
            programUpdate[`workoutDaysMap.${dayIndex}`] = workoutDay.id;
        }

        await datastore
            .collection(COLLECTIONS.WORKOUT_PROGRAM)
            .doc(clientProgramId)
            .update(programUpdate);
    }
}

function assignProgramToClient(assignTo, programData, pendingLogin) {
    let user = currentUser();
    let id = user.uid;

    if (!id) {
        throw new Error("User id missing");
    }

    programData.creatorId = id;

    programData._workoutDayIds = Object.keys(programData.workoutDataMap || {});

    let programDayRef = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM);
    if (assignTo.programId) {
        programDayRef = programDayRef.doc(assignTo.programId);
    } else {
        programDayRef = programDayRef.doc();
    }
    let collectionName = pendingLogin ? COLLECTIONS.INVITES : COLLECTIONS.USER_DATA;

    const clientDocRef = datastore.collection(collectionName).doc(assignTo.id);

    return new Promise(async (resolve, reject) => {
        return datastore
            .runTransaction(async (transaction) => {
                programData.id = programDayRef.id;
                programData.clientId = assignTo.id;

                let params = {
                    trainerId: id, programData, programDayRef, isUpdate: assignTo.programId,
                };
                await _saveProgram(params, transaction);

                let programId = programDayRef.id;
                const clientUpdateTask = transaction.update(clientDocRef, {
                    programId: programId, programs: [{
                        id: programId,
                    },],
                });
                return clientUpdateTask;
            })
            .then((results) => {
                resolve(results);
            })
            .catch((e) => {
                console.error(e);
                reject(e);
            });
    });
}

async function bulkUploadExercises(exercises) {
    for (let exercise of exercises) {
        exercise.createdTime = window.firebase.firestore.Timestamp.fromDate(new Date());
        exercise.updatedTime = window.firebase.firestore.Timestamp.fromDate(new Date());
    }
    return bulkUpload(COLLECTIONS.EXERCISES, exercises);
}

async function bulkUploadMovementCategories(data) {
    return bulkUpload(COLLECTIONS.MOVEMENT_CATEGORIES, data);
}

async function bulkUploadMovements(data) {
    return bulkUpload(COLLECTIONS.MOVEMENTS, data);
}

async function bulkUploadEquipments(data) {
    return bulkUpload(COLLECTIONS.EQUIPMENTS, data);
}

async function bulkUploadMuscles(data) {
    return bulkUpload(COLLECTIONS.MUSCLES, data);
}

async function bulkUploadMuscleGroups(data) {
    return bulkUpload(COLLECTIONS.MUSCLE_GROUPS, data);
}

async function bulkUploadFunctions(data) {
    return bulkUpload(COLLECTIONS.FUNCTIONS, data);
}

async function bulkUploadFunctionCategories(data) {
    return bulkUpload(COLLECTIONS.FUNCTION_CATEGORIES, data);
}

async function bulkUpload(collection, data) {
    return new Promise(async (resolve, reject) => {
        datastore
            .runTransaction((transaction) => {
                let tasks = [];

                for (let datum of data) {
                    let task = new Promise(async (resolve, reject) => {
                        try {
                            delete datum.createdTime;
                            delete datum.updatedTime;
                            let id = datum.id || hash(datum);
                            datum.createdTime = window.firebase.firestore.Timestamp.fromDate(new Date());
                            datum.updatedTime = window.firebase.firestore.Timestamp.fromDate(new Date());
                            let ref = datastore.collection(collection).doc(id);
                            datum.id = ref.id;
                            let ignored = await transaction.set(ref, datum);
                            if (ignored) {
                            }
                            datum.id = ref.id;

                            resolve(datum);
                        } catch (e) {
                            reject(e);
                        }
                    });

                    tasks.push(task);
                }

                return Promise.all(tasks);
            })
            .then((results) => {
                window.FortisForma.Cache.clearCache(collection);
                resolve(results);
            })
            .catch((e) => {
                console.error(e);
                reject(e);
            });
    });
}

async function storeMovementCategory(data) {
    return store(COLLECTIONS.MOVEMENT_CATEGORIES, data);
}

async function storeMovement(data) {
    return store(COLLECTIONS.MOVEMENTS, data);
}

async function storeEquipment(data) {
    return store(COLLECTIONS.EQUIPMENTS, data);
}

async function storeMuscle(data) {
    return store(COLLECTIONS.MUSCLES, data);
}

async function storeMuscleGroups(data) {
    return store(COLLECTIONS.MUSCLE_GROUPS, data);
}

async function storeFunction(data) {
    return store(COLLECTIONS.FUNCTIONS, data);
}

async function storeFunctionCategory(data) {
    return store(COLLECTIONS.FUNCTION_CATEGORIES, data);
}

function storeFeedback(data) {
    return store(COLLECTIONS.FEEDBACK, data);
}

function trimObject(row) {
    if (typeof row === "string") {
        return row.trim();
    }
    if (typeof row !== "object") {
        return row;
    }
    for (let key of Object.keys(row)) {
        let value = row[key];
        if (value && typeof value === "string") {
            row[key] = value.trim();
        }
        if (value && typeof value === "object") {
            row[key] = trimObject(value);
        }
    }

    return row;
}

async function store(collection, data) {
    data = trimObject(data);
    let ref;
    let update = true;

    if (!data.createdTime) {
        update = false;
    }

    if (data.id) {
        ref = datastore.collection(collection).doc(data.id);
    } else {
        let createdTime = data.createdTime;
        delete data.createdTime;
        let id = hash(data);
        data.createdTime = createdTime;
        ref = datastore.collection(collection).doc(id);
    }

    if (!data.createdTime) {
        data.createdTime = window.firebase.firestore.Timestamp.fromDate(new Date());
    }
    data.updatedTime = window.firebase.firestore.Timestamp.fromDate(new Date());

    try {
        data.id = ref.id;
        let fn = update ? ref.update : ref.set;
        let results = await fn.call(ref, data);
        results = data;
        results.id = ref.id;
        if (update) {
            window.FortisForma.Cache.updateDoc(collection, data.id, data);
        } else {
            window.FortisForma.Cache.clearCache(collection);
        }
        return results;
    } catch (e) {
        throw e;
    }
}

async function deleteDocument(collection, doc) {
    datastore
        .collection(collection)
        .doc(doc)
        .delete()
        .then(function () {
            window.FortisForma.Cache.removeDoc(collection, doc);
        })
        .catch(function (error) {
            console.error("Error removing document: ", error);
        });
}

var token;

async function getUserToken(forceFetch) {
    if (!forceFetch) {
        if (token) {
            return token;
        } else {
            token = window.localStorage.getItem(DB_KEYS.USER_TOKEN);
            if (token) {
                return token;
            }
        }
    }

    let user = await currentUserPromise();
    if (user) {
        let t = await user.getIdToken(forceFetch || false);
        if (token !== t) {
        }
        token = t;
        window.localStorage.setItem(DB_KEYS.USER_TOKEN, token);
        return token;
    } else {
        throw new Error("No user is logged in");
    }
}

function currentUserPromise() {
    let user = currentUser();
    if (user) {
        return Promise.resolve(user);
    }

    return new Promise((resolve, reject) => {
        let unsubscribe = window.firebase.auth().onAuthStateChanged((user) => {
            unsubscribe();
            if (user) {
                resolve(user);
            } else {
                reject(null);
            }
        });
    });
}

function getPendingApprovals(filters = [], pageConfig = {limit: 20}) {
    let query = {};
    query.collection = COLLECTIONS.USER_DATA;
    query.filters = filters.concat([{
        key: DB_KEYS.USER_STATE, operator: "==", value: USER_STATES.PENDING,
    }, {
        key: DB_KEYS.ROLE, operator: "==", value: ROLES.TRAINER,
    },]);

    query.pageConfig = pageConfig;

    return queryData(query);
}

function getTrainersList(filters = [], pageConfig = {limit: 20}) {
    let query = {};
    query.collection = COLLECTIONS.USER_DATA;
    query.filters = filters.concat([{
        key: DB_KEYS.ROLE, operator: "==", value: ROLES.TRAINER,
    },]);

    query.pageConfig = pageConfig;

    return queryData(query);
}

function showTrainer(trainerId) {
    if (!trainerId) {
        throw new Error("Trainer id invalid");
    }

    return showTrainerFN({trainerId});
}

function hideTrainer(trainerId) {
    if (!trainerId) {
        throw new Error("Trainer id invalid");
    }

    return hideTrainerFN({trainerId});
}

function approveSignupRequest(trainerId) {
    if (!trainerId) {
        throw new Error("Trainer id invalid");
    }

    return approveTrainerRequestFN({trainerId});
}

function declineSignupRequest(trainerId) {
    if (!trainerId) {
        throw new Error("Client id invalid");
    }

    return declineTrainerRequestFN({trainerId});
}

function verifyPasswordResetCode(code) {
    return auth.verifyPasswordResetCode(code);
}

function confirmPasswordReset(code, newPassword) {
    return auth.confirmPasswordReset(code, newPassword);
}

function checkActionCode(code) {
    return auth.checkActionCode(code);
}

function applyActionCode(code) {
    return auth.applyActionCode(code);
}

function emailVerified() {
    return auth.currentUser.emailVerified;
}

function clientEmailVerification(userData) {
    return clientEmailVerificationFN(userData);
}

function approveClientRequest(request) {
    try {
        return approveClientRequestFN(request);
    } catch (e) {
        throw e;
    }
}

function rejectClientRequest(request) {
    try {
        return rejectClientRequestFN(request);
    } catch (e) {
        throw e;
    }
}

async function initializeWalkthrough() {
    let walkthrough = INITIALIZE_WALKTHROUGH;
    let user = currentUser();
    if (!user || !user.uid) {
        return;
    }
    let id = user.uid;
    try {
        await datastore
            .collection(COLLECTIONS.USER_DATA)
            .doc(id)
            .update({walkthrough});
    } catch (e) {
        throw e;
    }
}

async function updateWalkthroughStatus(walkthroughKey) {
    let user = currentUser();
    if (!user || !user.uid) {
        return;
    }
    let id = user.uid;
    let update = {
        [`${DB_KEYS.WALKTHROUGH}.${walkthroughKey}`]: false,
    };
    try {
        await datastore.collection(COLLECTIONS.USER_DATA).doc(id).update(update);
    } catch (e) {
        throw e;
    }
}

async function sendMessage(message) {
    let user = currentUser();
    if (!user || !user.uid) {
        return;
    }
    try {
        return sendMessageFN(message);
    } catch (e) {
        throw e;
    }
}

async function onTrainerVerify(uid) {
    try {
        await notifyTrainerVerification({uid});
    } catch (e) {
        console.error(e);
    }
}

async function loadMoreLevelsOfExercise(exercise) {
    let user = currentUser();
    if (!user || !user.uid) {
        return;
    }

    return new Promise(async (resolve, reject) => {
        try {
            let query = datastore
                .collection(COLLECTIONS.EXERCISES)
                .where("movement", "==", exercise.movement)
                .where("equipmentCategories.Primary", "==", exercise.equipmentCategories.Primary || "Bodyweight")
                .where(DB_KEYS.TRAINER_ID_KEY, "in", ["admin", user.uid]);

            let results = await query.get();
            resolve(results.docs);
        } catch (e) {
            reject(e);
        }
    });
}

async function getProgramFromHistory(subcollectionId, programId) {
    let ref = datastore.collection(COLLECTIONS.PROGRAM_HISTORY).doc(programId);
    return ref
        .collection(subcollectionId)
        .doc(programId)
        .get()
        .then((snapshot) => {
            return snapshot.data();
        })
        .catch((err) => {
            throw err;
        });
}

async function getUserProgram() {
    if (!auth.currentUser) {
        throw new Error("Current user is null");
    }

    let user;
    try {
        user = await getUserData();
    } catch (e) {
        throw e;
    }

    let programId = user.programId;

    if (!programId) {
        let error = {
            message: "No workout assigned, please contact your trainer", code: 404,
        };
        throw error;
    }

    const ref = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM).doc(programId);

    return new Promise(async (resolve, reject) => {
        try {
            const program = await ref.get();
            if (!program.exists) {
                throw new Error("Workout program does not exist");
            }

            resolve(program.data());
        } catch (error) {
            global.NotificationUtils.showError("No workout assigned currently");
            reject(error);
        }
    });
}

async function changeWorkoutStartDate() {
    let currentUser = currentUserData;
    if (!currentUser) {
        throw new Error("Current user is null");
    }

    let programId = currentUser.programId;
    if (!programId) {
        return false;
    }

    try {
        const summaryRef = datastore
            .collection(COLLECTIONS.WORKOUT_SUMMARY)
            .doc(currentUser.id + "_" + programId);
        let update = {};
        let timestamp = moment().startOf("day").unix();
        update[DB_KEYS.START_DATES] = firebase.firestore.FieldValue.arrayUnion(timestamp);
        let userRef = datastore
            .collection(COLLECTIONS.USER_DATA)
            .doc(currentUser.id);

        return new Promise((resolve, reject) => {
            datastore
                .runTransaction((transaction) => {
                    let tasks = [];
                    tasks.push(transaction.update(userRef, {programStartDate: timestamp}));
                    tasks.push(transaction.update(summaryRef, update));
                    return Promise.all(tasks);
                })
                .then(() => {
                    resolve(true);
                })
                .catch((e) => {
                    console.error(e);
                    reject(e);
                });
        });
    } catch (e) {
        throw e;
    }
}

async function getWorkoutSummary() {
    if (!auth.currentUser) {
        throw new Error("Current user is null");
    }

    let user;
    try {
        user = await getUserData();
    } catch (e) {
        throw e;
    }
    let programId = user.programId;
    if (!programId) {
        throw new Error({
            message: "No workout assigned, please contact your trainer", code: 404,
        });
    }

    let summaryId = user.id + "_" + programId;
    const ref = datastore.collection(COLLECTIONS.WORKOUT_SUMMARY).doc(summaryId);

    return new Promise(async (resolve, reject) => {
        try {
            const summary = await ref.get();
            if (!summary.exists) {
                throw new Error("Workout summary does not exist");
            }
            resolve(summary.data());
        } catch (error) {
            reject(error);
        }
    });
}

function setUserData(data) {
    currentUserData = data;
}

function getUserData(force) {
    if (!auth.currentUser) {
        throw new Error("Current user is null");
    }

    if (currentUserData && !force) {
        return Promise.resolve(currentUserData);
    }

    let userId = auth.currentUser.uid;

    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(userId);

    return new Promise(async (resolve, reject) => {
        try {
            const user = await ref.get();
            if (!user.exists) {
                throw new Error(`User detail do not exist ${userId}`);
            }
            currentUserData = user.data();
            if (currentUserData.pendingLogin) {
                unsetUserPendingFlag();
            }
            resolve(currentUserData);
        } catch (error) {
            reject(error);
        }
    });
}

async function unsetUserPendingFlag() {
    let userId = auth.currentUser.uid;
    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(userId);
    ref
        .update({
            pendingLogin: false,
        })
        .then(() => {
        })
        .catch((error) => {
            console.error("Error updating pending login flag", error);
        });
}

function subscribeToProgramUpdates(callback) {
    if (!auth.currentUser) {
        throw new Error("Current user is null");
    }

    let userId = auth.currentUser.uid;

    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(userId);
    return ref.onSnapshot((snapshot) => {
        callback(snapshot.data());
    });
}

async function getTrainerList() {
    let userId;
    if (firebase.auth().currentUser) {
        userId = auth.currentUser.uid;
    }

    let data = {
        uid: userId,
    };
    try {
        return await getTrainerListFN(data);
    } catch (e) {
        throw e;
    }
}

function getEnterpriseData(enterpriseId) {
    if (!enterpriseId) {
        throw new Error("enterprise id provided is null");
    }

    const ref = datastore.collection(COLLECTIONS.ENTERPRISES).doc(enterpriseId);

    return new Promise(async (resolve, reject) => {
        try {
            const enterpriseData = await ref.get();
            if (!enterpriseData.exists) {
                let error = {
                    code: 404, message: "Enterprise details do not exist",
                };
                throw error;
            }
            let data = enterpriseData.data();
            resolve(data);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}

async function getTrainerData(userId) {
    if (!userId) {
        throw new Error("enterprise id provided is null");
    }
    let ref;
    if (userId.trainerId) {
        ref = datastore.collection(COLLECTIONS.USER_DATA).doc(userId.trainerId);
    } else if (userId.enterpriseId) {

        let newRef = datastore.collection(COLLECTIONS.ENTERPRISES).doc(userId.enterpriseId);
        let entData = await newRef.get();
        ref = datastore.collection(COLLECTIONS.USER_DATA).doc(entData.id);
    }


    return new Promise(async (resolve, reject) => {
        try {
            const trainerData = await ref?.get();
            // if (!trainerData.exists) {
            //     let error = {
            //         code: 404, message: "Trainer details do not exist",
            //     };
            //     throw error;
            // }
            let data = trainerData?.data();

            resolve(data);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}

async function createClientAccount(userData, invite) {
    let requestData = {...userData};
    delete requestData.password;
    try {
        await createUserWithEmailAndPassword(userData.email, userData.password);
        if (invite) {
            await confirmClientInviteFN({invite});
        } else {
            return createClientAccountWithoutInviteFN(requestData)
                .then((results) => {
                    requestData.id = results.data.id;
                    return createClientRequestFN(requestData);
                })
                .catch((e) => {
                    throw e;
                });
        }
    } catch (e) {
        throw e;
    }
}

async function createClientAccountWithoutTrainer(userData) {
    let data = {...userData};
    delete data.password;
    try {
        let response = await createUserWithEmailAndPassword(userData.email, userData.password);
        data.id = response.user.uid;

        return createClientAccountWithoutInviteFN(data);
    } catch (e) {
        throw e;
    }
}

async function reloadCurrentAuthUser() {
    let user = currentUser();
    if (!user) {
        throw new Error("No current user");
    }
    await user.reload();
    return user;
}

async function sendEmailVerification() {
    await currentUserPromise();
    const actionCodeSettings = {
        url: CONTINUE_URL + `?uid=${auth.currentUser.uid}`, handleCodeInApp: false,
    };
    if (!auth.currentUser) {
        return Promise.resolve();
    }
    return auth.currentUser.sendEmailVerification(actionCodeSettings);
}

async function createClientRequest(requestData) {
    try {
        return await createClientRequestFN(requestData);
    } catch (e) {
        throw e;
    }
}

async function getTrainerFromInvite(invite) {
    try {
        let trainer = await getTrainerForInviteFN({id: invite});
        return trainer;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

async function saveUserGoalsAndReminders(update) {
    let currentUser = currentUserData;
    if (!currentUser) {
        throw new Error("Current user is null");
    }
    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(currentUser.id);
    try {
        await ref.update(update);
    } catch (e) {
        throw e;
    }
}

async function saveGoalsToSummary(update, weekStamp) {
    let currentUser = currentUserData;
    let goals = update.goals;
    if (!currentUser) {
        throw new Error("Current user is null");
    }
    let programId = currentUser.programId;
    if (!programId) {
        return false;
    }
    const ref = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);
    let key = `goals.${weekStamp}`;
    let summaryUpdate = {};
    summaryUpdate[key] = goals[weekStamp];
    try {
        await ref.update(summaryUpdate);
    } catch (e) {
        throw e;
    }
}

async function addWorkoutSectionLog(logData, setStartDate) {
    const currentUser = currentUserData;
    if (!logData[0]) {
        return false;
    }
    const programId = logData[0].programId;
    const logDate = logData[0].logDate;
    const sectionIndex = logData[0].sectionIndex;
    const workoutId = logData[0].workoutId;
    let log = {};
    for (let exerciseLog of logData) {
        log[exerciseLog.exerciseIndex] = exerciseLog.log;
        if (exerciseLog.feedback) {
            log[exerciseLog.exerciseIndex].feedback = exerciseLog.feedback;
        }
    }

    if (!programId || !log || !logDate || sectionIndex === undefined || !workoutId) {
        console.log(`programId${programId}log${log}logDate${logDate}sectionIndex${sectionIndex}workoutId${workoutId}`);
        throw new Error(`Invalid params`);
    }
    const summaryRef = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);
    let key = `logs.${logDate}.${workoutId}.${sectionIndex}`;
    let update = {};
    update[key] = log;
    if (setStartDate) {
        await changeWorkoutStartDate();
    }
    try {
        await summaryRef.update(update);
    } catch (e) {
        throw e;
    }
    return log;
}

async function addWorkoutLog(params, setStartDate) {
    const currentUser = currentUserData;
    const programId = params.programId;
    const logDate = params.logDate;
    const log = params.log;
    const sectionIndex = params.sectionIndex;
    const exerciseIndex = params.exerciseIndex;
    const workoutId = params.workoutId;

    if (!programId || !log || !logDate || sectionIndex === undefined || exerciseIndex === undefined || !workoutId) {
        console.log(`programId${programId}log${log}logDate${logDate}sectionIndex${sectionIndex}exerciseIndex${exerciseIndex}workoutId${workoutId}`);
        throw new Error(`Invalid params`);
    }

    const summaryRef = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);

    let key = `logs.${logDate}.${workoutId}.${sectionIndex}.${exerciseIndex}`;
    let update = {};
    update[key] = log;
    if (setStartDate) {
        await changeWorkoutStartDate();
    }

    await summaryRef.update(update);

    return log;
}

async function removeWorkoutSectionLog(logData) {
    const currentUser = currentUserData;
    if (!logData[0]) {
        return false;
    }
    const programId = logData[0].programId;
    const logDate = logData[0].logDate;
    const sectionIndex = logData[0].sectionIndex;
    const workoutId = logData[0].workoutId;
    if (!programId || !logDate || sectionIndex === undefined || !workoutId) {
        console.log(`programId${programId}logDate${logDate}sectionIndex${sectionIndex}workoutId${workoutId}`);
        throw new Error(`Invalid params`);
    }
    const summaryRef = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);
    let key = `logs.${logDate}.${workoutId}.${sectionIndex}`;
    let update = {};
    update[key] = firebase.firestore.FieldValue.delete();
    try {
        await summaryRef.update(update);
    } catch (e) {
        throw e;
    }
    return true;
}

async function removeWorkoutLog(params) {
    const currentUser = currentUserData;
    const programId = params.programId;
    const logDate = params.logDate;
    const sectionIndex = params.sectionIndex;
    const exerciseIndex = params.exerciseIndex;
    const workoutId = params.workoutId;
    if (!programId || !logDate || sectionIndex === undefined || exerciseIndex === undefined || !workoutId) {
        console.log(`programId${programId}logDate${logDate}sectionIndex${sectionIndex}exerciseIndex${exerciseIndex}workoutId${workoutId}`);
        throw new Error(`Invalid params`);
    }
    const summaryRef = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);
    let key = `logs.${logDate}.${workoutId}.${sectionIndex}.${exerciseIndex}`;
    let update = {};
    update[key] = firebase.firestore.FieldValue.delete();
    return summaryRef.update(update);
}

async function updateFeedbackDate(summaryRef) {
    const todayUnix = moment().startOf("day").unix();
    return summaryRef.update({feedbackDate: todayUnix});
}

async function updateUserGoals(goalUpdate) {
    const currentUser = currentUserData;
    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(currentUser.id);
    let key = `goals.${goalUpdate.weekUnix}.${goalUpdate.day}`;
    let update = {};
    update[key] = GOAL_STATUS.COMPLETE;
    try {
        await ref.update(update);
    } catch (e) {
        throw e;
    }
}

async function saveWorkoutFeedback(data, updateDate, goalUpdate) {
    const currentUser = currentUserData;
    const programId = data.programId;
    const logDate = data.logDate;
    const workoutId = data.workoutId;
    const summaryRef = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);
    let update = {};
    if (data.feedback) {
        let key = `logs.${logDate}.${workoutId}.feedback`;
        update[key] = data.feedback;
    }
    if (goalUpdate) {
        if (Object.keys(goalUpdate).length) {
            let goalKey = `goals.${goalUpdate.weekUnix}.${goalUpdate.day}`;
            update[goalKey] = GOAL_STATUS.COMPLETE;
        }
    }

    try {
        if (updateDate) {
            await updateFeedbackDate(summaryRef);
        }
        await summaryRef.update(update);
    } catch (e) {
        throw e;
    }
    return true;
}

function submitEnquiry(form) {
    try {
        return submitEnquiryFN(form);
    } catch (e) {
        throw e;
    }
}

async function getTrainers(filter) {
    let start = filter.postalCode;
    let end = start.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1));
    try {
        let query = {};
        query.collection = COLLECTIONS.TRAINERS;
        query.filters = [{
            key: DB_KEYS.VISIBLE, operator: "==", value: true,
        },];
        if (filter.postalCode) {
            query.filters.push({
                key: DB_KEYS.POSTAL_CODE, operator: ">=", value: start,
            }, {
                key: DB_KEYS.POSTAL_CODE, operator: "<", value: end,
            });
        }

        if (filter.serviceModes && filter.serviceModes.length) {
            query.filters.push({
                key: DB_KEYS.SERVICE_MODES, operator: "array-contains-any", value: filter.serviceModes,
            });
        }
        return queryData(query);
    } catch (e) {
        throw e;
    }
}

async function getTrainerSearchRadius() {
    try {
        const ref = datastore.collection(COLLECTIONS.CONFIG).doc(DB_KEYS.GLOBAL);
        let radiusSnap = await ref.get();
        let radiusData = radiusSnap.data();
        return radiusData[DB_KEYS.TRAINER_RADIUS];
    } catch (e) {
        throw e;
    }
}

async function getPlaceDetails(place_id) {
    let API_URL = `${process.env.REACT_APP_SERVER_ADDRESS}places/${place_id}/details`;
    try {
        let response = await fetch(API_URL, {
            method: "GET", headers: {
                "x-requested-with": "example.com",
            },
        });
        if (response.ok) {
            return response.json();
        } else {
            throw new Error("HTTP-Error: " + response.status);
        }
    } catch (e) {
        throw e;
    }
}

async function fetchWorkoutDay(WorkoutDayId) {
    const ref = datastore.collection(COLLECTIONS.WORKOUT_DAYS).doc(WorkoutDayId);

    return new Promise(async (resolve, reject) => {
        try {
            const workoutDay = await ref.get();
            if (!workoutDay.exists) {
                throw new Error("Workout day does not exist");
            }
            resolve(workoutDay.data());
        } catch (error) {
            global.NotificationUtils.showError("Workout not found");
            reject(error);
        }
    });
}

async function getChallenges() {
    try {
        return getChallengesFN();
    } catch (e) {
        throw e;
    }
}

async function assignFirstPhaseToUser(challenge) {
    challenge.programId = challenge.id;
    try {
        return tryChallengeFN(challenge);
    } catch (e) {
        throw e;
    }
}

async function assignChallengeToUser(challenge) {
    challenge.programId = challenge.id;
    // try {

    //     return startChallengeFN(challenge);
    // }
    // catch (e) {
    //     throw e;
    // }
    let user = currentUser();
    challenge.user_id = user.uid;
    let obj = {
        data: challenge,
    };
    const fetchOptions = {
        body: JSON.stringify(obj), headers: {
            "Content-Type": "application/json",
        }, method: "POST",
    };

    // const apiURL = `http://3.98.138.115/user/challenges`;
    const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}user/challenges`;
    return fetch(apiURL, fetchOptions)
        .then((res) => res.json())
        .then((nw) => {
            return nw;
        })
        .catch((e) => {
            throw e;
        });
}

async function createNewProgramWithWorkout(workout) {
    try {
        return createNewProgramWithWorkoutFN({workout});
    } catch (e) {
        throw e;
    }
}

async function addWorkoutToProgram(programId, workout) {
    try {
        return addWorkoutToExistingProgramFN({programId, workout});
    } catch (e) {
        throw e;
    }
}

async function updateActiveProgram(programId) {
    const currentUser = currentUserData;
    try {
        const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(currentUser.id);
        return await ref.update({programId: programId});
    } catch (e) {
        throw e;
    }
}

async function sendOtp() {
    try {
        const result = await sendOtpFN();
        return result;
    } catch (e) {
        throw e;
    }
}

async function verifyOtp(token) {
    try {
        const result = await verifyOtpFN(token);
        if (result) {
            return result.data;
        }
    } catch (e) {
        throw e;
    }
}

async function removeDayLogs() {
    let currentUser = currentUserData;

    if (!currentUser) {
        throw new Error("Current user is null");
    }

    const programId = currentUser.programId;

    if (!programId) {
        throw new Error("Program id not found");
    }

    const summaryRef = datastore
        .collection(COLLECTIONS.WORKOUT_SUMMARY)
        .doc(currentUser.id + "_" + programId);
    const todayDate = moment().format("MM-DD-YYYY");

    try {
        let clientSummary = await summaryRef.get();

        if (!clientSummary.exists) {
            throw new Error("Workout summary not found");
        }

        clientSummary = clientSummary.data();
        const loggedWorkouts = clientSummary.logs?.[todayDate];
        let loggedWorkoutsCopy = {};
        const key = `logs.${todayDate}`;
        let update = {};

        if (loggedWorkouts && loggedWorkouts.notes) {
            loggedWorkoutsCopy.notes = {...loggedWorkouts.notes};
            update[key] = loggedWorkoutsCopy;
        } else {
            update[key] = firebase.firestore.FieldValue.delete();
        }

        await summaryRef.update(update);
    } catch (e) {
        throw e;
    }
}

let exportedFunctions = {
    store,
    createUserWithEmailAndPassword: createUserWithEmailAndPassword,
    storeUserProfile: storeUserProfile,
    signInWithEmailAndPassword: signInWithEmailAndPassword,
    sendPasswordResetEmail: sendPasswordResetEmail,
    currentUser: currentUser,
    updatePassword: updatePassword,
    updateUserLoginTime: updateUserLoginTime,
    getUserProgram: getUserProgram,
    getUserData: getUserData,
    signOut: signOut,
    getSimilarExercises: getSimilarExercises,
    queryData: queryData,
    storeWorkout: storeWorkout,
    deleteWorkout: deleteWorkout,
    deleteProgram: deleteProgram,
    createNewClient: createNewClient,
    removeClient: removeClient,
    sendInvite: sendInvite,
    storeExercise: storeExercise,
    startUploadFileTask: startUploadFileTask,
    assignProgramToClient: assignProgramToClient,
    bulkUploadExercises: bulkUploadExercises,
    storeMovementCategory: storeMovementCategory,
    storeMovement: storeMovement,
    bulkUploadMovementCategories: bulkUploadMovementCategories,
    bulkUploadMovements: bulkUploadMovements,
    storeEquipment: storeEquipment,
    bulkUploadEquipments: bulkUploadEquipments,
    storeMuscle: storeMuscle,
    storeMuscleGroups: storeMuscleGroups,
    bulkUploadMuscles: bulkUploadMuscles,
    bulkUploadMuscleGroups: bulkUploadMuscleGroups,
    requestExercise: requestExercise,
    storeFunction: storeFunction,
    storeFunctionCategory: storeFunctionCategory,
    bulkUploadFunctionCategories: bulkUploadFunctionCategories,
    bulkUploadFunctions: bulkUploadFunctions,
    deleteDocument: deleteDocument,
    getUserToken: getUserToken,
    getWorkoutSummary: getWorkoutSummary,
    getPendingApprovals: getPendingApprovals,
    approveSignupRequest: approveSignupRequest,
    declineSignupRequest: declineSignupRequest,
    verifyPasswordResetCode,
    confirmPasswordReset,
    sendEmailVerification,
    checkActionCode,
    applyActionCode,
    emailVerified,
    getProgramHistory,
    getCustomClaims,
    storeFeedback,
    getPrograms,
    getClientsForProgram,
    saveProgram,
    getProgram,
    assingProgramToClients,
    removeProgramfromClients,
    approveClientRequest,
    rejectClientRequest,
    initializeWalkthrough,
    updateWalkthroughStatus,
    addWorkoutDayToClientProgram,
    getTrainersList,
    showTrainer,
    hideTrainer,
    clientEmailVerification,
    sendMessage,
    onTrainerVerify,
    loadMoreLevelsOfExercise,
    getProgramFromHistory,
    getWorkouts,
    changeWorkoutStartDate,
    subscribeToProgramUpdates,
    getTrainerList,
    createClientAccount,
    reloadCurrentAuthUser,
    createClientRequest,
    getTrainerFromInvite,
    addWorkoutLog,
    removeWorkoutLog,
    addWorkoutSectionLog,
    removeWorkoutSectionLog,
    saveWorkoutFeedback,
    saveUserGoalsAndReminders,
    saveGoalsToSummary,
    updateUserGoals,
    submitEnquiry,
    getTrainers,
    getTrainerSearchRadius,
    getPlaceDetails,
    fetchWorkoutDay,
    getChallenges,
    createClientAccountWithoutTrainer,
    assignFirstPhaseToUser,
    assignChallengeToUser,
    setUserData,
    createNewProgramWithWorkout,
    addWorkoutToProgram,
    updateActiveProgram,
    sendOtp,
    verifyOtp,
    removeDayLogs,
    getEnterpriseData,
    getTrainerData,
};

// const wrapped = memoryCacheWrapper(exportedFunctions);
export default exportedFunctions;
