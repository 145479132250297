import React from 'react';
import WorkoutSection from './workoutSection';

export default class SectionedWorkout extends React.Component {
    render() {
        let workout = this.props.workoutSections;
        return (
            <div className="previewSectionedWorkout">
                {workout.length > 0 && workout.map((section, index) => {
                    return (
                        <WorkoutSection key={index} section={section} listIndex={index} firstSection={workout[0]}
                                        size={this.props.size} collapsible={this.props.collapsible}
                                        workoutType={this.props.workoutType}
                                        loggedWorkoutId={this.props.loggedWorkoutId}
                                        loggedData={this.props.loggedData}/>
                    );
                })}
            </div>
        );
    }
}