import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';

export default function ChallengeListItem(props) {

    function onSelectProgram() {
        props.onSelectProgram(props.data.id)
    }

    return (
        <div 
            className={props.selected === props.data.id ? "programListItem programListItemSelected" : "programListItem"}
            onClick={onSelectProgram}
        >
            <div className="flexRow">
                <Avatar 
                    className="challengeThumbnail"
                    alt="Challenge Image" 
                    src={props.data.picURL} 
                    variant="square" 
                />
                <div className="challengeItemContent">
                    <Typography variant="body2" className="boldFont">{props.data.name}</Typography>
                    <Typography variant="caption" className="greyColor">{props.data.value}</Typography>
                </div>
            </div>
            <div>{props.selected === props.data.id && <DoneIcon color="primary" />}</div>
        </div>
        
    )
}