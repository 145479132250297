import MomentUtils from "@date-io/moment";
import Spinner from "@material-ui/core/CircularProgress";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import React from "react";
import styled from "styled-components";
import Header from "../../components/common/Header/header";
import PublicSectionedWorkout from "../../components/publicSectionedWorkout/publicSectionedWorkout";
import {LOCAL_STORAGE_KEYS} from "../../constants";

const PublicHomeWorkout = styled.div`
  @media only screen and (max-width: 450px) {
    padding-top: 0 !important;
  }
`;

export default class PublicWorkoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            publicWorkoutId: null,
        };
    }

    componentDidMount() {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.PUBLIC_WORKOUT_ID);
        let workoutId = this.getWorkoutId();
        if (!workoutId) {
            this.props.history.replace("/signup");
        }
        this.fetchWorkoutDay(workoutId);
    }

    getWorkoutId = () => {
        let querySearchParams = this.props.location.search;
        let params = new URLSearchParams(querySearchParams);

        let id = params.get("id");
        this.setState({
            publicWorkoutId: id,
        });
        return id;
    };

    fetchWorkoutDay = async (id) => {
        this.setState({
            loading: true,
        });
        await window.FortisForma.database
            .fetchWorkoutDay(id)
            .then(async (results) => {
                if (results.exercises) {
                    let workoutSections = [];
                    let exercises = results.exercises;
                    workoutSections[0] = {
                        exercises: exercises,
                        workoutType: "Warmup",
                    };
                    results.workoutSections = workoutSections;
                }
                this.setState({
                    data: results,
                });
            })
            .catch((error) => {
                console.error(error);
            });
        this.setState({
            loading: false,
        });
    };

    progressIndicator() {
        return (
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    margin: "auto",
                    textAlign: "center",
                    height: 50,
                }}
            >
                <Spinner color="primary"/>
            </div>
        );
    }

    createProgramWithWorkout = async () => {
        try {
            await window.FortisForma.database.createNewProgramWithWorkout(
                this.state.data
            );
            window.NotificationUtils.showSuccess("Program Created Successfully");
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Something went wrong");
        }
    };

    onClickUseWorkout = async () => {
        try {
            if (this.props.user?.programId) {
                await window.FortisForma.database.addWorkoutToProgram(
                    this.props.user?.programId,
                    this.state.data
                );
                this.props.history.replace("/home");
                window.NotificationUtils.showSuccess("Workout added Successfully");
            } else {
                await this.createProgramWithWorkout();
            }
        } catch (e) {
            console.error(e);
            window.NotificationUtils.showError("Something went wrong");
        }
    };

    onSignupComplete = async () => {
        try {
            await this.createProgramWithWorkout();
            if (this.props.onUserChange) {
                await this.props.onUserChange();
            }
            this.props.history.replace("/home");
        } catch (e) {
            window.NotificationUtils.showError("Something went wrong");
            console.error(e);
        }
    };

    renderAppBar = () => {

        return (
            <Header
                {...this.props}
                onClickUseWorkout={this.onClickUseWorkout}
                onSignupComplete={this.onSignupComplete}
                type={"public"}
                publicWorkoutId={this.state.publicWorkoutId}
                publicWorkoutName={this.state.data?.name}
            />
        );
    };

    renderMainContent() {
        return this.state.data && this.renderWorkoutSections();
    }

    renderWorkoutSections = () => {
        return (
            <React.Fragment>
                <PublicHomeWorkout id="homePageWorkoutContainer">
                    <PublicSectionedWorkout data={this.state.data}/>
                </PublicHomeWorkout>
            </React.Fragment>
        );
    };

    render() {
        if (this.state.loading) {
            return this.progressIndicator();
        }
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {this.renderAppBar()}
                {this.renderMainContent()}
            </MuiPickersUtilsProvider>
        );
    }
}
