import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import React from "react";
import {isEmail} from "validator";
import AppDownload from "../../components/appDownload";
import LoginSocial from "../../components/common/socialAuth/socialauth";
import PasswordInput from "../../components/passwordField/passwordComponent";
import TaButton from "../../components/taButton";
import {DB_KEYS, ERROR_CODES} from "../../constants";
import styled from "styled-components";

const InputContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

export default class Login extends LoginSocial {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            authButtonEnabled: true,
            emailInvalid: false,
            value: "",
            loading: false,
            publicWorkoutId: null,
        };

        this._isMounted = true;

        this.onChangeEmail = this.onChange.bind(this, "email");
        this.onChangePassword = this.onChange.bind(this, "password");
        this.login = this.login.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.isValidEmail = this.isValidEmail.bind(this);
    }

    componentDidMount() {
        this.getWorkoutId()
        this._isMounted = true;
        window.addEventListener("keypress", this.handleKeyPress);
        let email = window.localStorage.getItem(DB_KEYS.LAST_USER_EMAIL);
        if (email) {
            this.setState({
                email: email,
            });
        }
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.authButtonEnabled) {
            this.setState({
                authButtonEnabled: true,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.getWorkoutId();
        window.removeEventListener("keypress", this.handleKeyPress);
    }

    onChange(key, event) {
        let update = {};
        update[key] = event.target.value;
        this.setState(update);
    }

    onLoginSuccess = () => {
        if (this.props.publicWorkoutId !== null) {
            // this.props.history.replace("/home");
            this.props.history.replace(`/public-workout?id=${this.props.publicWorkoutId}`);
        }

        // this.props.history.replace("/home");
    };
    getWorkoutId = () => {
        let querySearchParams = window.location;
        const queryString = querySearchParams.hash.split('?')[2];

        let params = new URLSearchParams(queryString)


        let id = params.get("id");

        this.setState({
            publicWorkoutId: id,
        });
        return id;
    };

    login(e) {
        try {
            window.FortisForma.database.signOut();
        } catch (e) {
            console.error(e);
        }

        if (e.nativeEvent) {
            e.nativeEvent.stopPropagation();
        }
        let {email, password} = this.state;
        email = window.emailTrimAndLowerCase(email);
        if (!email) {
            window.NotificationUtils.showError("Invalid email");
            return;
        }

        if (!password) {
            window.NotificationUtils.showError("Invalid password");
            return;
        }

        this.setState({
            authButtonEnabled: false, loading: true,
        });

        window.localStorage.removeItem(DB_KEYS.SIGN_UP_STATE);
        window.FortisForma.database
            .signInWithEmailAndPassword(email, password)
            .then(async (result) => {
                try {
                    window.setUserEmailInLocalStorage(email);
                    this.setState({
                        authButtonEnabled: true,
                    });
                    console.log("logged in");

                    // this.onLoginSuccess();
                    setTimeout(async () => {
                        if (this.getWorkoutId()) {

                            await this.props.history.replace(`/public-workout?id=${this.getWorkoutId()}`);
                        } else {
                            await this.props.history.replace("/home");
                        }
                    }, 1200)

                } catch (e) {
                    console.error(e);
                    this.setState({
                        authButtonEnabled: true, loading: false,
                    });
                }
            })
            .catch((error) => {
                this.showError(error);
                this.setState({
                    authButtonEnabled: true, loading: false,
                });
            });
    }

    forgotPassword() {
        if (!this.isValidEmail()) {
            return;
        }
        window.FortisForma.database
            .sendPasswordResetEmail(this.state.email)
            .then(() => {
                this.onSuccessForgotPass();
            })
            .catch((error) => {
                if (error.code === ERROR_CODES.USER_NOT_FOUND) {
                    return this.onSuccessForgotPass();
                }
                this.showError(error);
            });
    }

    onSuccessForgotPass() {
        window.NotificationUtils.showSuccess("Successfully sent reset email");
        console.log("Successfully sent reset email");
    }

    isValidEmail() {
        if (this.state.email && isEmail(this.state.email)) {
            return true;
        }
        window.NotificationUtils.showError("Email is not valid");
        console.log("Email is not valid");
        return false;
    }

    showError(error) {
        console.error(error);
        switch (error.code) {
            case ERROR_CODES.USER_DISABLED:
                window.NotificationUtils.showError("Your account is blocked");
                break;

            case ERROR_CODES.TOO_MANY_ATTEMPTS:
                window.NotificationUtils.showError("Too many failed login attempts, please wait for few minutes now");
                break;

            default:
                window.NotificationUtils.showError("Invalid email or password");
                console.error(error);
        }
    }

    progressIndicator() {
        return (<div
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                margin: "auto",
                textAlign: "center",
                height: 50,
            }}
        >
            {/* <Spinner color="primary" /> */}
        </div>);
    }

    handleKeyPress = (evt) => {
        if (evt.key === "Enter") {
            this.login(evt);
        }
    };

    setEmailValid = (isValid) => {
        if (isValid === true) {
            this.setState({emailInvalid: false});
            return;
        }

        if (isEmail(this.state.email) === false) {
            this.setState({emailInvalid: true});
            return;
        }
        this.setState({emailInvalid: false});
    };

    gotoSignup = () => {
        this.props.history.replace("/signup");
    };

    render() {
        return (<React.Fragment>
            <div className="container">
                <div style={{display: "flex", height: "100%"}}>
                    <div className="imageContainer">
                        <img
                            alt="client Logo"
                            className="imageStyle"
                            src="/assets/womenGym.jpg"
                        />
                    </div>
                    <div
                        className="loginContainer"
                        style={{width: "35%", alignItems: "center"}}
                    >
                        <div className="flexDirection">
                            <div>
                                <img
                                    alt="FortisForma Logo"
                                    className="logo"
                                    src="/assets/textLogo.png"
                                    style={{width: "200px"}}
                                />
                            </div>

                            <div>
                                <h2>Login to your account</h2>
                            </div>

                            <InputContainer>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Email"
                                    variant="outlined"
                                    required
                                    onChange={this.onChangeEmail}
                                    value={this.state.email}
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">
                                            <AlternateEmailIcon
                                                color="disabled"
                                                fontSize="small"
                                            />
                                        </InputAdornment>),
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <PasswordInput
                                    label={"Password"}
                                    showIndicator={false}
                                    value={this.state.password}
                                    onChange={this.onChangePassword}
                                />
                            </InputContainer>

                            <div
                                style={{textAlign: "right", width: "100%"}}
                            >
                                <div
                                    onClick={this.forgotPassword}
                                    className="forgotPasswordButton"
                                >
                                    Forgot Password?
                                </div>
                            </div>

                            <TaButton
                                className="loginButton"
                                variant="contained"
                                color="primary"
                                isLoading={this.state.loading}
                                loadingMessage={"Logging in ..."}
                                onClick={this.login}
                                style={{marginTop: 24, width: "100%"}}
                            >
                                Login with email
                            </TaButton>

                            <div
                                style={{marginTop: 18, width: "100%", textAlign: "center"}}
                            >
                                <Button
                                    className="redirectLink"
                                    onClick={this.gotoSignup}
                                    variant="text"
                                    color="default"
                                >
                                    Don't have an account? Sign Up
                                </Button>
                            </div>
                        </div>
                        <div id="appIconsLogin">
                            <AppDownload/>
                            <p style={{
                                textAlign: 'center', margin: 0,
                            }}>version {process.env.REACT_APP_VERSION}</p>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
}
