import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import * as animationData from "../../assets/invalid.json";
import { INVALID_LOGIN_REDIRECT_URL } from "../../constants";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const TIMER = 8000;

export default class Welcome extends Component {
  state = {
    remaining: TIMER / 1000,
  };

  componentDidMount() {
    this.startTimer();
  }
  startTimer(timer = TIMER) {
    this.timer = setTimeout(() => {
      this.routeToHome();
    }, timer - 500);

    this.interval = setInterval(() => {
      this.setState({
        remaining: this.state.remaining - 1,
      });
    }, timer / (timer / 1000));
  }

  routeToHome = () => {
    window.location.href = INVALID_LOGIN_REDIRECT_URL;
    return;
  };

  render() {
    return (
      <div className="pageContainer flexCenter flexColumn">
        <Typography variant="h4" align="center">
          Sorry this is the clients section, we'll redirect you to the
          trainer section momentarily.
        </Typography>
        {this.state.remaining > 0 && (
          <Typography className="mg-tp-16">
            Redirecting to trainer section in {this.state.remaining} seconds.
          </Typography>
        )}

        <Button
          id="waitingAction"
          variant="outlined"
          color="primary"
          onClick={() => {
            this.routeToHome();
          }}
          className="mg-tp-16 "
        >
          Go to Trainer Section
        </Button>
      </div>
    );
  }
}
